import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class RemarkService {

  constructor(private apiService: ApiService) { }

  public createRemark = (feedback) => this.apiService.post('remarks', feedback);
  public deleteRemark = (id) => this.apiService.delete('remarks/' + id);
  public updateRemark = (id, feedback) => this.apiService.patch('remarks/' + id, feedback);
  public getRemarkBySlotId = (slotId) => this.apiService.get('remarks?filter[where][slotId]=' + slotId);
  public getRemarkLatestByStudentId = (userId) => this.apiService.get(`remarks?filter[where][userId]=${userId}&filter[limit]=1&filter[order]=createAt DESC`);
  public getRemarksByStudentId = (userId) => this.apiService.get('remarks?filter[where][userId]=' + userId);
  public getRemarksByTeacherId = (teacherId) => this.apiService.get('remarks?filter[where][teacherId]=' + teacherId);

}
