import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { FormBuilder, NgForm, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';

// import { MENU_ITEMS } from './pages-menu';
import { ContactService, TrialService, LoadScriptService, ApiService, SubscribeService, TraceBackService } from '../services';
import { Contact } from '../model/contact.model';
import { customAlphabet } from 'nanoid'

@Component({
  selector: 'app-contact-page',
  styleUrls: ['contact.component.scss',],
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {

  isTrialSubmitted = false;
  isContactSubmitted = false;
  isContactSubmittedOK = false;
  public contact: Contact = new Contact();
  public trial: Contact = new Contact();
  campaignId;


  contactForm = this.formBuilder.group({
    fullName: new FormControl(this.contact.fullName, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    phone: new FormControl(this.contact.phone, [Validators.required]),
    title: new FormControl(this.contact.title, [Validators.required]),
    content: new FormControl(this.contact.content, [Validators.required]),
  });

  trialForm = this.formBuilder.group({
    name: new FormControl(this.contact.fullName, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    phone: new FormControl(this.contact.phone, [Validators.required]),
  });

  submitted = false;
  subscribe: any = {};

  constructor(
    private route: ActivatedRoute,
    private subscribeService: SubscribeService,
    private contactService: ContactService,
    private trailService: TrialService,
    private traceBackService: TraceBackService,
    private apiService: ApiService,
    private loadScriptService: LoadScriptService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle("Liên hệ | elseTalk");
    this.meta.updateTag({ name: "description", content: "Liên hệ" });
    // for show slide menu and some script
    this.loadScriptService.loadScript(this.loadScriptService.INIT);
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParamMap])
      .subscribe(async ([params, queryParams]) => {
        let userCode = localStorage.getItem('userCode');
        let campaignId = queryParams.get('camp');
        // if userCode not exist, it mean user not vistied yet
        if (!userCode && campaignId) {
          // init for first time go to this page
          const nanoid = customAlphabet('1234567890abcdefghijkmnlopqrstuvzxy', 15);
          userCode = nanoid() //=> "4f90d13a42"
          localStorage.setItem('userCode', userCode);
        }

        if (campaignId) {
          this.campaignId = campaignId;

          // for sinh vien camp
          if (campaignId == 'sv001') {
            console.log('cam::', campaignId);
            this.contact.title = 'register';
          }
          // save local storage for another page/time
          localStorage.setItem('campaignId', campaignId);
          this.traceBackService.add({
            userCode,
            campaignId,
          });
        }
        // const title = queryParams.get('title') || localStorage.getItem('contact_title');
        // this.contact.title = title ?? "";
      });

    window.scrollTo(100, 0);
  }

  async onSubmitContact(contactForm: NgForm) {
    try {
      this.isContactSubmitted = true;
      if (contactForm.invalid) {
        return;
      }
      this.contact = contactForm.value;
      if (this.campaignId) {
        this.contact.campaignId = this.campaignId;
      }
      let userCode = localStorage.getItem('userCode');
      if (userCode) {
        this.contact.userCode = userCode;
      }
      const result = await this.contactService.createContact(this.contact);
      alert('Cảm ơn bạn liên hệ với elseTalk, elseTalk sẽ liên hệ lại với bạn nhất có thể!');
      this.isContactSubmittedOK = true;
      contactForm.resetForm();
    } catch (error) {
      alert('Đã có lỗi gửi tin nhắn, vui lòng kiểm tra lại thông tin');
    }
  }

  async onSubmitTrial(trialForm: NgForm) {
    try {
      this.isTrialSubmitted = true;
      if (trialForm.invalid) {
        return;
      }
      this.trial = trialForm.value;
      if (this.campaignId) {
        this.trial.campaignId = this.campaignId;
      }
      let userCode = localStorage.getItem('userCode');
      if (userCode) {
        this.trial.userCode = userCode;
      }
      const result = await this.trailService.registerTrail(this.trial);
      alert('Cảm ơn bạn đã đăng ký học thử, elseTalk sẽ liên hệ với bạn sắp lịch học sớm nhất có thể!');
      trialForm.resetForm();
    } catch (error) {
      alert('Đã có lỗi đăng ký, vui lòng kiểm tra lại thông tin');
    }
  }

}
