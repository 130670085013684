<div class="site-section courses-title" id="khoa-hoc-tieng-anh-online">
  <div class="container">
    <div class="row mb-5 justify-content-center">
      <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="10">
        <h2 class="section-title">Khoá Học</h2>
        <p style="color: white;">Các khoá học đều 1 kèm 1 và online với 100% giáo viên nước ngoài, không giới hạn số buổi học trong tháng nhằm để học viên tự sắp lịch một cách linh hoạt nhất</p>
      </div>
    </div>
  </div>
</div>
<div class="site-section courses-entry-wrap"  data-aos="fade-up" data-aos-delay="100">
  <div class="container">
    <div class="row">

      <div class="owl-carousel col-12 nonloop-block-14">
        <div class="course bg-white h-100 align-self-stretch">
          <figure class="m-0">
            <a href="#"><img src="assets/images-landing/img_1.jpg" alt="Image" class="img-fluid"></a>
          </figure>
          <div class="course-inner-text py-4 px-4">
            <span class="course-price">hot</span>
            <div class="meta"><span class="icon-clock-o"></span>20 class+ / 1 month</div>
            <h3><a href="#">Talk Talk</a></h3>
            <p>Giúp bạn luyện nói tiếng Anh phản xạ cách tự nhiên nhất, không cần phải cố gắn học thuộc rồi nhanh chống quên sau đó, không để tiếng Anh là nỗi sợ của bản nữa.</p>
          </div>
          <div class="d-flex border-top stats">
          </div>
        </div>

        <div class="course bg-white h-100 align-self-stretch">
          <figure class="m-0">
            <a href="#"><img src="assets/images-landing/img_2.jpg" alt="Image" class="img-fluid"></a>
          </figure>
          <div class="course-inner-text py-4 px-4">
            <span class="course-price">hot</span>
            <div class="meta"><span class="icon-clock-o"></span>20 class+ / 1 month</div>
            <h3><a href="#">Business Talk</a></h3>
            <p>Tập chung vào luyên nói tiếng Anh Business, giúp bạn làm quen với môi trường công sở nhanh chống, giúp bạn thăng tiến nhanh hơn trong công việc.</p>
          </div>
          <div class="d-flex border-top stats">
            <!-- <div class="py-3 px-4"><span class="icon-users"></span> 129 students</div>
            <div class="py-3 px-4 w-25 ml-auto border-left"><span class="icon-chat"></span> 2</div> -->
          </div>
        </div>

        <div class="course bg-white h-100 align-self-stretch">
          <figure class="m-0">
            <a href="#"><img src="assets/images-landing/img_3.jpg" alt="Image" class="img-fluid"></a>
          </figure>
          <div class="course-inner-text py-4 px-4">
            <span class="course-price">hot</span>
            <div class="meta"><span class="icon-clock-o"></span>20 class+ / 1 month</div>
            <h3><a href="#">IELTS</a></h3>
            <p>Bám sát theo từng mục tiêu của từng học viên, giúp bạn hoàn thiện 4 kỹ năng Speaking, Writing, Listening, Reading trong thời gian ngắn nhất với lớp học 1 kèm 1</p>
          </div>
          <div class="d-flex border-top stats">
            <!-- <div class="py-3 px-4"><span class="icon-users"></span> 48 students</div>
            <div class="py-3 px-4 w-25 ml-auto border-left"><span class="icon-chat"></span> 2</div> -->
          </div>
        </div>

        <div class="course bg-white h-100 align-self-stretch">
          <figure class="m-0">
            <a href="#"><img src="assets/images-landing/img_4.jpg" alt="Image" class="img-fluid"></a>
          </figure>
          <div class="course-inner-text py-4 px-4">
            <span class="course-price">pro</span>
            <div class="meta"><span class="icon-clock-o"></span>Flexible</div>
            <h3><a href="#">Customize Course for You</a></h3>
            <p>Thiết kế riêng khoá học theo nhu cầu của bạn, cùng với thời gian học linh hoạt, giáo viên 1 kèm 1 để đạt hiệu quả tối ưu nhất cho bạn.</p>
          </div>
          <div class="d-flex border-top stats">
            <!-- <div class="py-3 px-4"><span class="icon-users"></span> 27 students</div>
            <div class="py-3 px-4 w-25 ml-auto border-left"><span class="icon-chat"></span> 2</div> -->
          </div>
        </div>

      </div>

    </div>
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <button class="customPrevBtn btn btn-primary m-1">Prev</button>
        <button class="customNextBtn btn btn-primary m-1">Next</button>
      </div>
    </div>
  </div>
</div>
