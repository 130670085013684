<nb-card>
  <nb-card-header>
    Subscription
  </nb-card-header>

  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="data"></ng2-smart-table>
  </nb-card-body>
</nb-card>

