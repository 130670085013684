export const config = {
  END_POINT: 'https://api.elsetalk.com/',
  FREE_SLOT_BG: '#b9f6ca', // ligth green
  BOOKED_SLOT_BG: '#9e9e9e', // light gray
  DISABLE_SLOT_BG: '#424242', // dark gray
  OWNER_SLOT_BG: '#7971ea', // purpel
  COMPLETE_SLOT_BG: '#009624', // dark green
  TEACHER_ABSENT_BG: '#f44336', // Primary RED
  REPORT_TEACHER_ABSENT_BG: '#ffcccb', // Light RED
  STUDENT_ABSENT_BG: '#ffa726', // dark Yellow
  REPORT_TEACHER_STUDENT_ABSENT_BG: '#5d4037', // dark Brow conlict


  // allow
  LEAD_TIME_BOOK_CLASS: 3600000, // 60 minute as milisecond
  LEAD_TIME_CANCEL_CLASS: 0, // 0 hour as milisecond , handler by server, just for update class info
  CLASS_DURATION: 1500000, // 25 minute as milisecond

  // date
  // less short localized date and time
  DATE_TIME_SHORT_FORMAT: 'MMM dd, yyyy t',

  DEFAULT_IMAGE: '/assets/images/default_image.png',
  PRICE_TEACHER : 90 , // PHP
};
