import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class StudentService {

  constructor(private apiService: ApiService) { }

  public getStudents = () => this.apiService.get('users');
  public getStudent = (id) => this.apiService.get('users/' + id);
  public createStudent = (user) => this.apiService.post('users', user);
  public updateStudent = (user) => this.apiService.patch('users/' + user.id, user);
  public updateStudentPassword = (id, userPassword) => this.apiService.patch(`users/${id}/password`, userPassword);
  public getStudentOrTeacher = (id, mainRole) => {
    if (mainRole == 'teacher') {
      return this.apiService.get('teachers/' + id);
    } else {
      return this.apiService.get('users/' + id);
    }
  }

}
