import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


// import { MENU_ITEMS } from './pages-menu';
import { ActivatedRoute } from '@angular/router';
import { LoadScriptService, ApiService, BlogService } from '../services';

@Component({
  selector: 'app-blog-single-page',
  styleUrls: ['blog-single.component.scss'],
  templateUrl: './blog-single.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BlogSingleComponent implements OnInit {
  blogs: any = {};
  contactForm: any = {};
  trialForm: any = {};
  subscribe: any = {};
  isShowSlideMenu = false;
  blog: any = {};

  constructor(
    private route: ActivatedRoute,
    private loadScriptService: LoadScriptService,
    private renderer: Renderer2,
    private blogService: BlogService,
    private title: Title,
    private meta: Meta,
  ) {
  }
  ngOnInit() {
    this._initData();
  }

  async _initData() {
    // get Route
    this.route.params.subscribe(async params => {
      const slug = params['slug'];
      // get blog object by slug
      this.blog = await this.blogService.getBlogById(slug);
      this.title.setTitle(this.blog.title + " | elseTalk");
      this.meta.updateTag({ name: "description", content: this.blog.excerpt });
    });
    this.blogs = await this.blogService.getLatestBlogs();
  }

}
