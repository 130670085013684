<!--Breadcrumb Area-->
<!-- <section class="breadcrumb-area banner-11" data-background="assets//assets/images/banner/11.jpg"> -->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/phuong-phap-hoc-tieng-anh', '']">Phương pháp học</a></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>Tối ưu hoá cho từng cá nhân</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Process-->
<section class="service-block pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span>Phương Pháp</span>
          <h2>Phương pháp học </h2>
          <p>Học tiếng Anh giao tiếp online 1 học viên 1 giáo viên mỗi lớp theo phương pháp độc quyền của chúng tôi, đặt
            hẹn chủ động theo thời gian rảnh của bạn mà không cần đi đến trung tâm, huỷ lớp dễ dàng nhanh chống!</p>
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-4 v-center order1">
        <div class="image-block1">
          <img src="assets/images/process/process-1.svg" alt="Process" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-7 v-center order2">
        <div class="ps-block">
          <span>1</span>
          <h3>Lớp tiếng Anh Online 1-1</h3>
          <p>Mỗi lớp chỉ 1 học viên. 100% Giáo viên nước ngoài chất lượng, nhiều năm kinh nghiệm sẽ giúp bạn hoàn toàn sử dụng tiếng Anh để giao tiếp.
             Giáo viên sẽ chỉnh sửa lỗi phát âm cho bạn từ cơ bản nhất đến nâng cao, viết lại nhận xét đánh giá theo từng buổi học trên hệ thống quản lý.</p>
          <!-- <p>100% lớp online sẽ giúp bạn tiết kiệm thời gian tới trung tâm</p> -->
          <ul class="list-ul ul-check mt10">
            <li>100% Tiếng tiếng anh</li>
            <li>100% Giáo viên nước ngoài</li>
            <li>100% Lớp học online</li>
            <li>100% Tương tác trực tuyến</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-7 v-center order2">
        <div class="ps-block">
          <span>2</span>
          <h3>Cách học tiếng Anh mới</h3>
          <p>Học giao tiếp luyện phản xạ tự nhiên chỉ có thực hành, tiếp thu thụ động tránh học trước quên sau và suy nghĩ bằng tiếng Việt dịch sang tiếng Anh.
             Học mỗi ngày và không giới hạn giờ học sẽ giúp bạn nhanh chống đạt đến mục tiêu của mình</p>
          <ul class="list-ul ul-check mt10">
            <li>Dạy tiếng Anh bằng tiếng Anh</li>
            <li>25/50 phút mỗi buổi học</li>
            <li>Học không giới hạn số buổi mỗi ngày</li>
            <li>Từ 3 tháng sẽ tự tin giao tiếp</li>
          </ul>
          <p></p>
          <p></p>
        </div>
      </div>
      <div class="col-lg-4 v-center order1">
        <div class="image-block1">
          <img src="assets/images/process/process-2.svg" alt="Process" class="img-fluid" />
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-4 v-center order1">
        <div class="image-block1">
          <img src="assets/images/process/process-3.svg" alt="Process" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-7 v-center order2">
        <div class="ps-block">
          <span>3</span>
          <h3>Lịch Học linh Hoạt</h3>
          <p>Có lớp học từ 8 giờ đến 23 giờ mỗi ngày. Bạn Chỉ cần đặt lịch trước 60 phút, nếu bận chỉ cần huỷ lớp trước 90 phút.
          Đặt lịch học và lộ trình học được cá nhân hoá vô cùng thuận tiện. Có nhiều giáo viên để bạn lựa chọn, chỉ cần kết nối Internet là có thể học ở bất cứ nơi đâu</p>
          <ul class="list-ul ul-check mt10">
            <li>Không giới hạn thời gian học</li>
            <li>Đặt lịch thông minh qua hệ thống elseTalk</li>
            <li>Học tiếng Anh tại nhà, hay bất cứ nơi nào chỉ cần kết nối Internet</li>
            <li>Tiết kiệm thời gian đến trung tâm, rút ngắn thời gian học tiếng Anh</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Process-->
