<!--Breadcrumb Area-->
<!-- <section class="breadcrumb-area banner-11" data-background="assets/images/banner/11.jpg"> -->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/lien-he', '']">Liên Hệ</a></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>Đăng Ký, Tư vấn, báo giá..</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->

<!--Start Enquire Form-->
	<section class="contact-page pad-tb">
		<div id="contact" class="container">
			<div class="row justify-content-center">
				<div class="col-lg-7 v-center">
					<div class="common-heading text-l">
						<span>Nhắn Tin Ngay</span>
						<h2 class="mt0 mb0">Tìm hiểu thông tin, đăng ký học, nhận báo giá... 🤙</h2>
            <!-- Tìm hiểu các hoá học, học phí, đăng ký học ngay hôm nay để nhận ưu đãi hấp dẫn -->
						<p class="mb60 mt20">Đăng ký học ngay hôm nay để nhận ưu đãi hấp dẫn, chúng tôi sẽ phản hồi bạn ngay lập tức</p>
					</div>
					<div class="form-block">
						<form #contactForm="ngForm" (ngSubmit)="onSubmitContact(contactForm)" id="contactForm" data-toggle="validator" class="shake">
							<div class="row">
                <div class="form-group col-sm-6">
                  <input type="text" name="fullName" id="fullName" [ngModel]="contact.fullName" #fullName="ngModel" placeholder="Tên của bạn" required>
                  <div *ngIf="isContactSubmitted || (fullName.invalid && (fullName.dirty || fullName.touched))">
                    <div *ngIf="fullName.errors?.required" class="help-block with-errors">Vui lòng nhập tên của bạn</div>
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <input type="email" id="email" name="email" [ngModel]="contact.email" #email="ngModel" placeholder="Email của bạn" required email>
                  <div *ngIf="isContactSubmitted || (email.invalid && (email.dirty || email.touched))">
                    <div *ngIf="email.errors?.required" class="help-block with-errors">Vui lòng nhập Email của bạn</div>
                    <div *ngIf="email.errors?.email" class="help-block with-errors">Vui lòng kiểm tra lại Email của bạn</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <input type="text" id="mobile" name="phone" id="phone" [ngModel]="contact.phone" #phone="ngModel" placeholder="Số điện thoại của bạn" required data-error="Please fill Out">
                  <div *ngIf="isContactSubmitted || (phone.invalid && (phone.dirty || phone.touched))">
                    <div *ngIf="phone.errors?.required" class="help-block with-errors">Vui lòng nhập số điện thoại của bạn</div>
                  </div>
                </div>
				<div class="form-group col-sm-6">
					<select id="Dtype" name="title" id="title" [ngModel]="contact.title" #title="ngModel" required>
						<option value="">Chọn Tiêu Đề</option>
						<option value="register">Đăng Ký Học</option>
						<option value="trial-register">Đăng Ký Học Thử</option>
						<option value="communication-price">Báo Giá Khoá Giao Tiếp</option>
						<option value="ielts-price">Báo Giá Khoá IETLS</option>
						<!-- <option value="communication-price-3">Báo Giá Khoá Giao Tiếp 3 Tháng</option>
						<option value="communication-price-6">Báo Giá Khoá Giao Tiếp 6 Tháng</option>
						<option value="communication-price-12">Báo Giá Khoá Giao Tiếp 12 Tháng</option> -->
						
						<!-- <option value="ielts-price-1">Báo Giá Khoá IETLS 1 Tháng</option>
						<option value="ielts-price-3">Báo Giá Khoá IETLS 3 Tháng</option>
						<option value="ielts-price-6">Báo Giá Khoá IETLS 6 Tháng</option> -->
						<option value="customize-price">Báo Giá Học Theo Yêu Cầu</option>
						<option value="communication-course-help">Tư Vấn Khoá Học Giao Tiếp</option>
						<option value="business-course-help">Tư Vấn Khoá Học Thương Mại</option>
						<option value="ielts-course-help">Tư Vấn Khoá Học IELTS</option>
						<option value="customize-course-help">Tư Vấn Khoá Học Theo Yêu Cầu</option>
						<option value="method-help">Tư Vấn Phương Pháp Học</option>
						<option value="roadmap-help">Tư Vấn Lộ Trình Học</option>
						<option value="other">Khác</option>
					</select>
					<div *ngIf="isContactSubmitted || (title.invalid && (title.dirty || title.touched))">
						<div *ngIf="title.errors?.required" class="help-block with-errors">Vui lòng chọn tiêu đề</div>
                  	</div>
				</div>
			   </div>
              <div class="form-group">
                <textarea id="content" rows="5" name="content" id="content" [ngModel]="contact.content" #content="ngModel" placeholder="Nội dung" required></textarea>
                <div *ngIf="isContactSubmitted || (content.invalid && (content.dirty || content.touched))">
                  <div *ngIf="content.errors?.required" class="help-block with-errors">Vui lòng nhập Nội Dung</div>
                </div>
              </div>
              <div class="mt20">
                <div *ngIf="isContactSubmittedOK" class="help-block with-success">Cảm ơn bạn liên hệ với elseTalk, elseTalk sẽ liên hệ lại với bạn nhất có thể!</div>
                <button type="submit" id="form-submit" class="btn lnk btn-main bg-btn">
                  Gửi <span class="circle"></span>
                </button>
                <div id="msgSubmit" class="h3 text-center hidden"></div>
                <p class="trm"><i class="fas fa-lock"></i>Chúng tôi ghét spam, và chúng tôi tôn trọng sự riêng tư của bạn.</p>

                <div class="clearfix"></div>
              </div>
						</form>
					</div>
				</div>


					<div class="col-lg-5 contact2dv">
						<div class="info-wrapr">
							<h3 class="mb-4">Thông Tin Liên Hhệ</h3>
							<!-- <div class="dbox d-flex align-items-start">
								<div class="icon d-flex align-items-center justify-content-center">
									<i class="fas fa-map-marker"></i>
								</div>
								<div class="text pl-4">
									<p><span>Address:</span>123 Business Centre London SW1A 1AA</p>
								</div>
							</div> -->
							<div class="dbox d-flex align-items-start">
								<div class="icon d-flex align-items-center justify-content-center">
									<i class="fas fa-phone-alt"></i>
								</div>
								<div class="text pl-4">
									<p><span>ĐT 👇</span> <a href="tel:0901481609">0901 481 609</a></p>
								</div>
							</div>
              <div class="dbox d-flex align-items-start">
								<div class="icon d-flex align-items-center justify-content-center">
                  <i class="fas fa-comment"></i>
								</div>
								<div class="text pl-4">
									<p><span>Zalo 👇</span> <a href="http://zalo.me/0901481609" target="_blank">0901 481 609</a></p>
								</div>
							</div>
              <div class="dbox d-flex align-items-start">
								<div class="icon d-flex align-items-center justify-content-center">
									<i class="fab fa-skype"></i>
								</div>
								<div class="text pl-4">
									<p><span>Skype 👇</span> <a href="skype:live:.cid.a466fddbd1f65778?chat">elseTalk</a></p>
								</div>
							</div>
							<div class="dbox d-flex align-items-start">
								<div class="icon d-flex align-items-center justify-content-center">
									<i class="fas fa-envelope"></i>
								</div>
								<div class="text pl-4">
									<p><span>Email 👇</span> <a href="mailto:elsetalk.business@gmail.com">elsetalk.business@gmail.com</a></p>
								</div>
							</div>
							<div class="dbox d-flex align-items-start">
								<div class="icon d-flex align-items-center justify-content-center">
									<i class="fa fa-globe"></i>
								</div>
								<div class="text pl-4">
									<p><span>Website 👇</span> <a href="https://elsetalk.vn">elsetalk.vn</a></p>
								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
	</section>
	<!--End Enquire Form-->

