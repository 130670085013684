<div class="container-fluid">
  <div class="row p-2">
    <!-- <button class="m-2" [disabled]="isDisableCallBtn" (click)="call()">Join Call</button>
    <button color="warn" class="m-2" [disabled]="isDisableEndBtn" (click)="endCall()">End Call</button> -->
  </div>
  <div class="row">
    <div class="col-lg-4">
      <video #remoteVideo id="remoteVideo" [class]="remoteVideoCss" playsinline autoplay muted="muted"></video>
      <video #localVideo id="localVideo" [class]="localVideoCss" playsinline autoplay muted
        poster="assets/images/logo.png"></video>
      <video #canvasVideo id="canvasVideo" playsinline autoplay muted="muted"></video>
      <button color="primary" nbButton status="success" class="btn-call m-2" [disabled]="isDisableStartBtn"(click)="startCall()">Join Class</button>
      <button color="primary" nbButton status="success" class="btn-call m-2" [disabled]="isDisableStartBtn"(click)="shareCanvas()">share canvas</button>
    </div>
    <div class="col-lg-8">
      <button color="primary" nbButton status="success" class="btn-call m-2" [disabled]="isDisableStartBtn" (click)="draw()">draw</button>
      <img #pdf style="display: none;" src="https://elsetalk.sgp1.digitaloceanspaces.com/logo-alt.png" />
      <canvas #canvas id="canvas" width="200" height="100"></canvas>
      <!-- your.component.html -->
      <button (click)="openPdf();">Open Pdf</button>
    </div>
  </div>
</div>