import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  constructor(private socket: Socket) {}

  public sendData(channel: string, data: any) {
    this.socket.emit(channel, data);
  }

  public getData(channel: string) {
    return this.socket.fromEvent(channel).pipe(map((data) => data));
  }

  public listen(channel: string, cb: any) {
    return this.socket.on(channel, cb)
  }
}
