export * from './api.service';
export * from './blog.service';
export * from './class.service';
export * from './contact.service';
export * from './teacher.service';
export * from './slot.service';
export * from './student.service';
export * from './subscribe.service';
export * from './feedback.service';
export * from './load-script.service';
export * from './remark.service';
export * from './trial.service';
export * from './window-ref.service';
export * from './subscription.service';
export * from './trackback.service';