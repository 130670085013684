import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { BlogComponent } from './blog/blog.component';
import { TeacherComponent } from './teacher/teacher.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';
import { CourseCumnicationComponent } from './course/comunication/course.component';
import { CourseBusinessComponent } from './course/business/course.component';
import { CourseIELTSComponent } from './course/ielts/course.component';
import { CourseCustomizeComponent } from './course/customize/course.component';
import { PriceCumnicationComponent } from './price/comunication/price.component';
import { PriceIELTSComponent } from './price/ielts/price.component';
import { PriceCustomizeComponent } from './price/customize/price.component';
import { ContactComponent } from './contact/contact.component';
import { MethodComponent } from './method/method.component';
import { RegisterDoneComponent } from './pages/register-done/register-done.component';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

const routes: Routes = [
  { path: '', component: LandingComponent, pathMatch: 'full' },
  // price
  { path: 'hoc-phi/tieng-anh-giao-tiep/', component: PriceCumnicationComponent, pathMatch: 'full' },
  { path: 'hoc-phi/tieng-anh-giao-tiep', redirectTo: 'hoc-phi/tieng-anh-giao-tiep/', pathMatch: 'full' },

  { path: 'hoc-phi/ielts/', component: PriceIELTSComponent, pathMatch: 'full' },
  { path: 'hoc-phi/ielts', redirectTo: 'hoc-phi/ielts/', pathMatch: 'full' },

  { path: 'hoc-phi/ielts-speaking/', component: PriceIELTSComponent, pathMatch: 'full' },
  { path: 'hoc-phi/ielts-speaking', redirectTo: 'hoc-phi/ielts-speaking/', pathMatch: 'full' },

  { path: 'hoc-phi/ielts-writing/', component: PriceIELTSComponent, pathMatch: 'full' },
  { path: 'hoc-phi/ielts-writing', redirectTo: 'hoc-phi/ielts-writing/', pathMatch: 'full' },

  { path: 'hoc-phi/ielts-listening/', component: PriceIELTSComponent, pathMatch: 'full' },
  { path: 'hoc-phi/ielts-listening', redirectTo: 'hoc-phi/ielts-listening/', pathMatch: 'full' },

  { path: 'hoc-phi/ielts-reading/', component: PriceIELTSComponent, pathMatch: 'full' },
  { path: 'hoc-phi/ielts-reading', redirectTo: 'hoc-phi/ielts-reading/', pathMatch: 'full' },

  { path: 'hoc-phi/khoa-hoc-theo-yeu-cau/', component: PriceCustomizeComponent, pathMatch: 'full' },
  { path: 'hoc-phi/khoa-hoc-theo-yeu-cau', redirectTo: 'hoc-phi/khoa-hoc-theo-yeu-cau/', pathMatch: 'full' },
  // course
  { path: 'khoa-hoc/tieng-anh-giao-tiep/', component: CourseCumnicationComponent, pathMatch: 'full' },
  { path: 'khoa-hoc/tieng-anh-giao-tiep', redirectTo: 'khoa-hoc/tieng-anh-giao-tiep/', pathMatch: 'full' },

  { path: 'khoa-hoc/tieng-anh-thuong-mai/', component: CourseBusinessComponent, pathMatch: 'full' },
  { path: 'khoa-hoc/tieng-anh-thuong-mai', redirectTo: 'khoa-hoc/tieng-anh-thuong-mai/', pathMatch: 'full' },

  { path: 'khoa-hoc/ielts/', component: CourseIELTSComponent, pathMatch: 'full' },
  { path: 'khoa-hoc/ielts', redirectTo: 'khoa-hoc/ielts/', pathMatch: 'full' },

  { path: 'khoa-hoc/ielts-speaking/', component: CourseIELTSComponent, pathMatch: 'full' },
  { path: 'khoa-hoc/ielts-speaking', redirectTo: 'khoa-hoc/ielts-speaking/', pathMatch: 'full' },

  { path: 'khoa-hoc/ielts-writing/', component: CourseIELTSComponent, pathMatch: 'full' },
  { path: 'khoa-hoc/ielts-writing', redirectTo: 'khoa-hoc/ielts-writing/', pathMatch: 'full' },

  { path: 'khoa-hoc/ielts-listening/', component: CourseIELTSComponent, pathMatch: 'full' },
  { path: 'khoa-hoc/ielts-listening', redirectTo: 'khoa-hoc/ielts-listening/', pathMatch: 'full' },

  { path: 'khoa-hoc/ielts-reading/', component: CourseIELTSComponent, pathMatch: 'full' },
  { path: 'khoa-hoc/ielts-reading', redirectTo: 'khoa-hoc/ielts-reading/', pathMatch: 'full' },

  { path: 'khoa-hoc/tieng-anh-theo-yeu-cau/', component: CourseCustomizeComponent, pathMatch: 'full' },
  { path: 'khoa-hoc/tieng-anh-theo-yeu-cau', redirectTo: 'khoa-hoc/tieng-anh-theo-yeu-cau/', pathMatch: 'full' },

  // { path: 'khoa-hoc/tieng-anh-giao-tiep/', component: CourseComponent, pathMatch: 'full' },
  // { path: 'khoa-hoc/tieng-anh-giao-tiep', redirectTo: 'khoa-hoc/tieng-anh-giao-tiep/', pathMatch: 'full' },

  // { path: 'khoa-hoc/tieng-anh-giao-tiep-business/', component: CourseComponent, pathMatch: 'full' },
  // { path: 'khoa-hoc/tieng-anh-giao-tiep-business', redirectTo: 'khoa-hoc/tieng-anh-giao-tiep-business/', pathMatch: 'full' },
  // method
  { path: 'phuong-phap-hoc-tieng-anh/', component: MethodComponent, pathMatch: 'full' },
  { path: 'phuong-phap-hoc-tieng-anh', redirectTo: 'phuong-phap-hoc-tieng-anh/', pathMatch: 'full' },
  // contact
  { path: 'lien-he/', component: ContactComponent, pathMatch: 'full' },
  { path: 'lien-he', redirectTo: 'lien-he/', pathMatch: 'full' },
  // teacher
  { path: 'giao-vien/', component: TeacherComponent, pathMatch: 'full' },
  { path: 'giao-vien', redirectTo: 'giao-vien/', pathMatch: 'full' },
  // blog
  { path: 'blog/', component: BlogComponent, pathMatch: 'full' },
  { path: 'blog', redirectTo: 'blog/', pathMatch: 'full' },
  { path: 'blog/:slug/', component: BlogSingleComponent, pathMatch: 'full' },
  { path: 'blog/:slug', redirectTo: 'blog/:slug/', pathMatch: 'full' },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    // outlet: "school",
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: NbLoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
      {
        path: 'register-done',
        component: RegisterDoneComponent,
      },
    ],
  },

  { path: '', redirectTo: '', pathMatch: 'full' },
  // { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
