import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

// import { MENU_ITEMS } from './pages-menu';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken, NbAuthService } from '@nebular/auth';


@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent implements OnInit {
  isPages = true;
  menu;

  constructor(
    private authService: NbAuthService,
  ) { }

  ngOnInit() {
    let menu: NbMenuItem[] = [];
    this.authService.getToken().subscribe((token: NbAuthJWTToken) => {
      // remove after logout
      if (token.getPayload()) {
        const { roles } = token.getPayload();
        // generic user
        if (roles.includes('generic_user')) {
          menu = [
            {
              title: 'Call',
              icon: 'calendar-outline',
              link: '/pages/call',
              home: true,
            },
            {
              title: 'Booking',
              icon: 'calendar-outline',
              link: '/pages/booking/teachers',
              home: true,
            },
            {
              title: 'Class',
              icon: 'list-outline',
              link: `/pages/student/${localStorage.getItem('user_id')}/class`,
            },
            {
              title: 'Teacher Remark', // teacher review
              icon: 'bookmark-outline',
              link: `/pages/student/${localStorage.getItem('user_id')}/teacher-remark`,
            },
            {
              title: 'Teacher Feedback', // teacher review
              icon: 'edit-outline',
              link: `/pages/student/${localStorage.getItem('user_id')}/teacher-feedback`,
            },
            {
              title: 'Subscription',
              icon: 'repeat-outline',
              link: `/pages/student/${localStorage.getItem('user_id')}/subscription`,
            },
            {
              title: 'Profile',
              icon: 'person-outline',
              link: `/pages/student/${localStorage.getItem('user_id')}/profile`,
            },
          ];
        }

        // teacher
        if (roles.includes('teacher')) {
          menu = [
            {
              title: 'Home',
              icon: 'home-outline',
              link: '/pages/booking/teachers',
              home: true,
            },
            {
              title: 'Class',
              icon: 'calendar-outline',
              link: `/pages/teacher/${localStorage.getItem('user_id')}/class`,
            },
            {
              title: 'Profile',
              icon: 'person-outline',
              link: `/pages/teacher/${localStorage.getItem('user_id')}/profile`,
              home: true,
            },
            {
              title: 'My Remark', // student review
              icon: 'bookmark-outline',
              link: `/pages/teacher/${localStorage.getItem('user_id')}/my-remark`,
            },
            {
              title: 'My Feedback', // student review
              icon: 'edit-2-outline',
              link: `/pages/teacher/${localStorage.getItem('user_id')}/my-feedback`,
            },
            {
              title: 'Student Feedback', // student review
              icon: 'edit-outline',
              link: `/pages/teacher/${localStorage.getItem('user_id')}/student-feedback`,
            },
          ];
        }
        // super admin
        if (roles.includes('super_admin')) {
          menu.push({
            title: 'admin',
            icon: 'layers-outline',
            children: [
              {
                title: 'Student List',
                icon: 'people-outline',
                link: '/pages/admin/student-list',
              },
              {
                title: 'Subscription',
                icon: 'repeat-outline',
                link: `/pages/admin/subscription`,
              },
              {
                title: 'Teacher List',
                icon: 'people',
                link: '/pages/admin/teacher-list',
              },
              {
                title: 'Teacher Statistic',
                icon: 'funnel-outline',
                link: '/pages/admin/teacher-statistic',
              },
              {
                title: 'Teacher Remark',
                icon: 'bookmark-outline',
                link: '/pages/admin/student-remark',
              },
              {
                title: 'Teacher Feedback',
                icon: 'edit-outline',
                link: '/pages/admin/teacher-feedback',
              },
              {
                title: 'Student Feedback',
                icon: 'edit',
                link: '/pages/admin/student-feedback',
              },
            ],
          });
        }

        // admin
        if (roles.includes('admin')) {
          menu.push({
            title: 'admin',
            icon: 'layers-outline',
            children: [
              {
                title: 'Teacher List',
                icon: 'people',
                link: '/pages/admin/teacher-list',
              },
              {
                title: 'Teacher Statistic',
                icon: 'funnel-outline',
                link: '/pages/admin/teacher-statistic',
              },
              {
                title: 'Teacher Remark',
                icon: 'bookmark-outline',
                link: '/pages/admin/student-remark',
              },
              {
                title: 'Teacher Feedback',
                icon: 'edit-outline',
                link: '/pages/admin/teacher-feedback',
              },
              {
                title: 'Student Feedback',
                icon: 'edit',
                link: '/pages/admin/student-feedback',
              },
            ],
          });
        }
      }
      // NO need handel else because
      this.menu = menu;

    });

  }
}
