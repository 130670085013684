import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'ngx-register-done',
  styleUrls: ['./register-done.component.scss'],
  templateUrl: './register-done.component.html',
})
export class RegisterDoneComponent implements OnDestroy {

  constructor(
  ) {
  }

  ngOnDestroy() {
  }


}
