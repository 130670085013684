import { Component, OnInit, Renderer2 } from '@angular/core';

// import { MENU_ITEMS } from './pages-menu';
// import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-cta-component',
  styleUrls: ['cta.component.scss',],
  templateUrl: './cta.component.html',
})
export class CTAComponent implements OnInit {

  constructor(
    // private loadScriptService: LoadScriptService,
    // private title:Title,
    // private meta: Meta,
  ) {
    // this.title.setTitle("elseTalk - bang gia khoa hoc tieng Anh giao tiep");
    // this.meta.updateTag({name: 'description', content: ''});
    // this.loadScriptService.loadScript(this.loadScriptService.OWL);
  }

  ngOnInit() {
  }


  setContactTitle(info) {
    localStorage.setItem('contact_title', info);
  }
}
