import { Component, OnInit, Renderer2, AfterViewInit, PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { FormBuilder, NgForm, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from './services/window-ref.service';

// import { MENU_ITEMS } from './pages-menu';
import { ContactService, TrialService, LoadScriptService, ApiService, BlogService, SubscribeService } from './services';
import { Contact } from './model/contact.model';
declare var jQuery: any;
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  // for remove header, footer for auth page
  isLanding = true;
  blogs:any = [];
  title = 'elsetalk-landing';
  subscribeFrom = this.formBuilder.group({
    email: ['', Validators.required]
  });
  isSubscribeSubmitted = false;
  subscribe: any = '';
  isShowSlideMenu = false;
  isTrialSubmitted=false;
  isContactSubmitted=false;
  public contact: Contact = new Contact();
  public trial: Contact = new Contact();


  contactForm = this.formBuilder.group({
    fullName: new FormControl(this.contact.fullName, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    phone: new FormControl(this.contact.phone, [Validators.required]),
    title: new FormControl(this.contact.title, [Validators.required]),
    content: new FormControl(this.contact.content, [Validators.required]),
  });

  trialForm = this.formBuilder.group({
    name: new FormControl(this.contact.fullName, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    phone: new FormControl(this.contact.phone, [Validators.required]),
  });


  constructor( 
    
    // private authService: NbAuthService,
    // private _spinner: NbSpinnerService,
    private blogService: BlogService,
    private subscribeService: SubscribeService,
    private contactService: ContactService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRef: WindowRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._initData();
    this.isLanding = true;
    setTimeout(async () => {
      // await this.loadScriptService.loadAll();
      // this._spinner.load();
      // $('#body').removeClass('nb-theme-default');
      // remove nb-theme-default CSS class
      // if (this.isLanding) {
      //   this.renderer.removeClass(document?.body, 'nb-theme-default');
      // }
    }, 100);
    // this.loadScriptService.loadScript(this.loadScriptService.INIT);
  }

  async _initData() {
    this.blogs = await this.blogService.getLatestBlogs();
  }

  ngOnInit() {
    setTimeout(async () => {
      // await this.loadScriptService.loadAll();
      // this._spinner.load();
      // $('#body').removeClass('nb-theme-default');
      // remove nb-theme-default CSS class
      // if (this.isLanding) {
      //   this.renderer.removeClass(document?.body, 'nb-theme-default');
      // }
    }, 100);
  }

  ngAfterViewInit() {
  }


  showSlideMenu() {
    this.renderer.addClass(document?.body, 'offcanvas-menu');
    this.isShowSlideMenu = true;
  }

  async onSubmitContact(contactForm: NgForm) {
    try {
      this.isContactSubmitted = true;
      if (contactForm.invalid) {
        return;
      }
      this.contact = contactForm.value;
      const result = await this.contactService.createContact(this.contact);
      alert('Chúng tôi đã nhận được thông tin liên Hệ của bạn');
      contactForm.resetForm();
    } catch (error) {
      alert('Đã có lỗi gửi tin nhắn, vui lòng kiểm tra lại thông tin');
    }
  }

  async onSubmitSubscribe(subscribeForm: NgForm) {
    try {
      this.isSubscribeSubmitted = true;
      if (subscribeForm.invalid) {
        return;
      }
      this.subscribe = subscribeForm.value;
      const result = await this.subscribeService.createSubscribe(this.subscribe);
      alert('Chúng tôi đã nhận được thông tin đăng ký của bạn');
      subscribeForm.resetForm();
    } catch (error) {
      alert('Đã có lỗi đăng ký, vui lòng kiểm tra lại thông tin');
    }
  }

  // move to top when init page
  onActivate(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.windowRef.nativeWindow.scrollTo(0, 0);
    }

    if (event.auth || event.isPages) {
      this.isLanding = false;
      this.renderer.addClass(document?.body, 'nb-theme-default');
    } else {
      this.isLanding = true;
      setTimeout(async () => {
        // await this.loadScriptService.loadAll();
        // this._spinner.load();
        // $('#body').removeClass('nb-theme-default');
        // remove nb-theme-default CSS class
        try {
          if (this.isLanding) {
            this.renderer.removeClass(document?.body, 'nb-theme-default');
          }
        } catch (error) {

        }
      }, 100);
      // this.renderer.removeClass(document.body, 'nb-theme-default');
    }
  }


  setContactTitle(info) {
    localStorage.setItem('contact_title', info);
  }
}