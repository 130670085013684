import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class SlotService {

  constructor(private apiService: ApiService) { }

  public getSlots = () => this.apiService.get('slots');
  public getSlotById = (id) => this.apiService.get('slots/' + id);
  public createSlots = (timeObject) => this.apiService.post('slots', timeObject);
  public deleteSlots = (id) => this.apiService.delete('slots/' + id);
  public bookSlots = (id, slot) => this.apiService.post(`slots/${id}/booking`, slot);
  // only for student
  public updateBookSlots = (id, slot) => this.apiService.patch(`slots/${id}/booking`, slot);

  // only for Teacher
  public updateSlots = (id, slot) => this.apiService.patch(`slots/${id}`, slot);

  public cancelSlots = (id) => this.apiService.delete(`slots/${id}/cancel`);
  // /slots/teacher/{teacherId}/bulk
  public bookTeacher = (id, slot) => this.apiService.post(`slots/teacher/${id}/bulk`, slot);


  // get slot by teacherId
  public getSlotsByTeacherId = (id, start, end) => this.apiService.get(`slots?filter[where][teacherId]=${id}&filter[include][][relation]=user&filter[where][start][gte]=${start}&filter[where][end][lte]=${end}`);
  public getSlotsByUserId = (id) => this.apiService.get(`slots?filter[where][userId]=${id}`);


}
