import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { FormBuilder, NgForm, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

// import { MENU_ITEMS } from './pages-menu';
import { ContactService, TrialService, LoadScriptService, ApiService, SubscribeService } from '../services';
import { Contact } from '../model/contact.model';


@Component({
  selector: 'app-method-page',
  styleUrls: ['method.component.scss',],
  templateUrl: './method.component.html',
})
export class MethodComponent implements OnInit {

  submitted = false;
  subscribe: any = {};
  isShowSlideMenu = false;

  constructor(
    // private authService: NbAuthService,
    // private _spinner: NbSpinnerService,
    private subscribeService: SubscribeService,
    private contactService: ContactService,
    private trailService: TrialService,
    private apiService: ApiService,
    private loadScriptService: LoadScriptService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle("Phương pháp học tiếng Anh | elseTalk");
    this.meta.updateTag({name: "description", content: "Liên hệ"});
    this.loadScriptService.loadScript(this.loadScriptService.INIT);
  }

  ngOnInit() {
    // window.location.reload();
    setTimeout(async () => {
      // await this.loadScriptService.loadAll();
      // this._spinner.load();
      // $('#body').removeClass('nb-theme-default');
    }, 500);
  }


}
