


// export showToast(, title: string, body: string) {
//   const config = {
//     status: danger,
//     destroyByClick: this.destroyByClick,
//     duration: this.duration,
//     position: this.position,
//     preventDuplicates: this.preventDuplicates,
//   };

//   this.toastrService.show(
//     body,
//     `${title}`,
//     config);
// }

export enum SlotState {
  AVAILABLE = 'Available',
  OVERTIME = 'Overtime',
  BOOKED = 'Booked',
  COMPLETED = 'Completed',
  TEACHER_ABSENT = 'Teacher Absent',
  STUDENT_ABSENT = 'Student Absent',
}
