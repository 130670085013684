import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-remove-slot',
  templateUrl: 'dialog-remove-slot.component.html',
  styleUrls: ['dialog-remove-slot.component.scss'],
})
export class DialogRemoveSlotComponent {
  header: string;

  constructor(protected ref: NbDialogRef<DialogRemoveSlotComponent>) {}

  cancel() {
    this.ref.close({isCancle: true});
  }

  submit() {
    this.ref.close({isRemove: true});
  }
}
