import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { FormBuilder, NgForm, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

// import { MENU_ITEMS } from './pages-menu';
import { ContactService, TrialService, LoadScriptService, ApiService, SubscribeService } from '../services';
import { Contact } from '../model/contact.model';


@Component({
  selector: 'app-landing-page',
  styleUrls: [
    'landing.component.scss',
  ],
  templateUrl: './landing.component.html',
})
export class LandingComponent implements OnInit {

  isTrialSubmitted=false;
  isTrialSubmittedOK=false;
  isContactSubmitted=false;
  isContactSubmittedOK=false;
  public contact: Contact = new Contact();
  public trial: Contact = new Contact();


  contactForm = this.formBuilder.group({
    fullName: new FormControl(this.contact.fullName, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    phone: new FormControl(this.contact.phone, [Validators.required]),
    title: new FormControl(this.contact.title, [Validators.required]),
    content: new FormControl(this.contact.content, [Validators.required]),
  });

  trialForm = this.formBuilder.group({
    fullName: new FormControl(this.contact.fullName, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    phone: new FormControl(this.contact.phone, [Validators.required]),
    content: new FormControl(this.contact.content, []),
  });

  submitted = false;
  subscribe: any = {};
  isShowSlideMenu = false;

  constructor(
    // private authService: NbAuthService,
    // private _spinner: NbSpinnerService,
    private subscribeService: SubscribeService,
    private contactService: ContactService,
    private trailService: TrialService,
    private apiService: ApiService,
    private loadScriptService: LoadScriptService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle("Học tiếng Anh giao tiếp, IELTS online 1 kèm 1 | elseTalk");
    this.meta.updateTag({name: "description", content: "Liên hệ"});

    this.loadScriptService.loadScript(this.loadScriptService.INIT);
  }

  ngOnInit() {
  }

  async onSubmitContact(contactForm: NgForm) {
    try {
      this.isContactSubmitted = true;
      if (contactForm.invalid) {
        return;
      }
      this.contact = contactForm.value;
      let campaignId = localStorage.getItem('campaignId');
      if (campaignId) {
        this.contact.campaignId = campaignId;
      }
      let userCode = localStorage.getItem('userCode');
      if (userCode) {
        this.contact.userCode = userCode;
      }
      const result = await this.contactService.createContact(this.contact);
      alert('Cảm ơn bạn liên hệ với elseTalk, elseTalk sẽ liên hệ lại với bạn nhất có thể!');
      this.isContactSubmittedOK = true;
      contactForm.resetForm();
    } catch (error) {
      alert('Đã có lỗi gửi tin nhắn, vui lòng kiểm tra lại thông tin');
    }
  }

  async onSubmitTrial(trialForm: NgForm) {
    try {
      this.isTrialSubmitted = true;
      if (trialForm.invalid) {
        return;
      }
      this.trial = trialForm.value;
      let campaignId = localStorage.getItem('campaignId');
      if (campaignId) {
        this.trial.campaignId = campaignId;
      }
      let userCode = localStorage.getItem('userCode');
      if (userCode) {
        this.trial.userCode = userCode;
      }

      const result = await this.trailService.registerTrail(this.trial);
      this.isTrialSubmittedOK = true;
      alert('Cảm ơn bạn đã đăng ký học thử, elseTalk sẽ liên hệ với bạn sắp lịch học sớm nhất có thể!');
      trialForm.resetForm();
    } catch (error) {
      alert('Đã có lỗi đăng ký, vui lòng kiểm tra lại thông tin');
    }
  }

  showSlideMenu() {
    this.renderer.addClass(document.body, 'offcanvas-menu');
    this.isShowSlideMenu = true;
  }

  setContactTitle(info) {
    localStorage.setItem('contact_title', info);
  }

}
