import { Component, OnInit, Renderer2 } from '@angular/core';

// import { MENU_ITEMS } from './pages-menu';
import { LoadScriptService, ApiService, SubscribeService, BlogService } from '../../services';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-price-page',
  styleUrls: ['price.component.scss',],
  templateUrl: './price.component.html',
})
export class PriceCustomizeComponent implements OnInit {

  constructor(
    private loadScriptService: LoadScriptService,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle("Bảng giá khoá học tiếng anh theo yêu cầu| elseTalk");
    this.meta.updateTag({ name: 'description', content: '' });
    // this.loadScriptService.loadScript(this.loadScriptService.OWL);
    // for show slide menu and some script
    this.loadScriptService.loadScript(this.loadScriptService.INIT);
  }

  onGetPrice() {
    localStorage.setItem('contact_title', 'customize-price');
  }

  ngOnInit() {
  }

}
