import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { config } from '../../../config';
import { SubscriptionService } from '../../../services';


@Component({
  selector: 'ngx-student-subscription',
  styleUrls: ['./subscription.component.scss'],
  templateUrl: './subscription.component.html',
})
export class SubscriptionComponent implements OnDestroy {
  private alive = true;
  data: any = [];

  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      // confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: false,
    },
    columns: {
      courseName: {
        title: 'Course',
        type: 'string',
        editable: false,
      },
      totalClass: {
        title: 'Amount Class',
        type: 'number',
      },
      assignedClass: {
        title: 'Assigned Class',
        type: 'number',
      },
      chargeAmount: {
        title: 'Amount Settled',
        type: 'number',
      },
      chargeDate: {
        title: 'Settlement Date',
        type: 'number',
      },
      startDate: {
        title: 'Start Date',
        type: 'number',
      },
      expireAt: {
        title: 'End Date',
        type: 'number',
      },
      period: {
        title: 'Period (month)',
        type: 'number',
        // editable: false,
      },
      note: {
        title: 'Note',
        type: 'string',
        // editable: false,
      },
    },
  };

  constructor(
    private subscriptionService: SubscriptionService,
    private activatedRoute: ActivatedRoute,

  ) {
    this._initData();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  // onEditConfirm(event): void {
  //   if (window.confirm('Are you sure you want to Edit?')) {
  //     const newData = {
  //       materialName: event.newData.materialName,
  //       whereStart: event.newData.whereStart,
  //       whereEnd: event.newData.whereEnd,
  //       performance: event.newData.performance,
  //     };
  //     this.subscriptionService.updateRemark(event.newData.id, newData)
  //       .then(() => {
  //         return event.confirm.resolve();
  //       })
  //       .catch(() => event.confirm.reject());
  //   } else {
  //     event.confirm.reject();
  //   }
  // }


  private async _initData() {
    this.activatedRoute.params.subscribe(async params => {
      const studentId = params['id'];
      try {
        const result = await this.subscriptionService.getSubscriptionsByStudentId(studentId);
        for (const subscription of result) {
          if (subscription.chargeDate) {
            subscription.chargeDate = DateTime.fromMillis(subscription.chargeDate).toFormat(config.DATE_TIME_SHORT_FORMAT);
            subscription.startDate = DateTime.fromMillis(subscription.startDate).toFormat(config.DATE_TIME_SHORT_FORMAT);
            subscription.expireAt = DateTime.fromMillis(subscription.expireAt).toFormat(config.DATE_TIME_SHORT_FORMAT);
          }
        }
        result.sort(this.compare);

        this.data = result;
      } catch (ex) {
        console.error('getSubscriptionByStudentId: ', ex);
      }
    });
  }


  private compare(a, b) {
    if (a.chargeDate > b.chargeDate) {
      return -1;
    }
    if (a.chargeDate < b.chargeDate) {
      return 1;
    }
    return 0;
  }
}
