	<!--Start CTA-->
	<section class="cta-area pad-tb bg-gradient10">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8">
					<div class="common-heading">
						<span>Đăng ký học với elseTalk ngay nào!</span>
						<h2>elseTalk luôn sẵn sàn lắng nghe và giúp bạn chinh phục tiếng Anh</h2>
						<a [routerLink]="['/lien-he/']" (click)="setContactTitle('register')" class="btn-outline">Đăng ký học ngay <i
								class="fas fa-chevron-right fa-icon"></i></a>
						<p class="cta-call">Gọi
               <a href="tel:+84901481609"><i class="fas fa-phone-alt"></i>(+84) 901 481 609</a>
                hoặc gọi hay nhắn tin <a href="http://zalo.me/0901481609" target="_blank"><i class="fas fa-comment"></i>Zalo: 0901 481 609</a></p>
					</div>
				</div>
			</div>
		</div>
		<div class="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
		<div class="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
		<div class="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
		<div class="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
	</section>
	<!--End CTA-->

