import { NgModule } from '@angular/core';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbFormFieldModule,
} from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ThemeModule } from '../../@theme/theme.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { StudentRoutingModule } from './video-call-routing.module';
import { StudentComponent } from './video-call.component';
import { RoomComponent } from './room/room.component';


import { DialogUploadComponent } from './dialog-upload/dialog-upload.component';



@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbIconModule,
    NbActionsModule,
    NbSelectModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbInputModule,
    NbRadioModule,
    NbFormFieldModule,
    FormsModule,

    ImageCropperModule,
    StudentRoutingModule,
    Ng2SmartTableModule,
  ],
  declarations: [
    StudentComponent,
    RoomComponent,
    DialogUploadComponent,
  ],
  providers: [
  ],
})
export class VideoCallModule {
}
