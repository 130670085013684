import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuModule } from '@nebular/theme';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ThemeModule } from '../@theme/theme.module';
import { PagesComponent } from './pages.component';
import { TeacherModule } from './teacher/teacher.module';
import { StudentModule } from './student/student.module';
import { VideoCallModule } from './video-call/video-call.module';
import { PagesRoutingModule } from './pages-routing.module';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = { url: 'http://localhost:5000', options: {} };

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    ImageCropperModule,
    TeacherModule,
    StudentModule,
    VideoCallModule,
    SocketIoModule.forRoot(config)
  ],
  declarations: [
    PagesComponent,
  ],
})
export class PagesModule {

  constructor(
    private authService: NbAuthService,
    private router: Router,
  ) {
    this.authService.getToken().subscribe((token: NbAuthJWTToken) => {
      // remove after logout
      if (!token.getPayload()) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('main_role');
        // go to login page
        // this.router.navigateByUrl('/auth/login');
        console.log("goto: /auth/login");
      } else {
        // console.log(token.getPayload());
        localStorage.setItem('user_id', token.getPayload().userId);
        const roles = token.getPayload().roles;
        const mainRole = roles.includes('teacher') ? 'teacher' : 'student';
        localStorage.setItem('main_role', mainRole);
      }
    });
    this.authService.isAuthenticated().subscribe((result: boolean) => {
      // console.log('isAuthenticated pages: ', result);
    });
  }
}
