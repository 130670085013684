import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { config } from '../../../config';
import { FeedbackService } from '../../../services';


@Component({
  selector: 'ngx-teacher-feedback',
  styleUrls: ['./feedback-list-self.component.scss'],
  templateUrl: './feedback-list-self.component.html',
})
export class TeacherFeedbackComponent implements OnDestroy {
  private alive = true;
  data: any = [];

  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: true,
    },
    columns: {
      userName: {
        title: 'Student',
        type: 'string',
        editable: false,
      },
      content: {
        title: 'Content',
        type: 'string',
      },
      // slotTitle: {
      //   title: 'Class Title',
      //   type: 'string',
      //   editable: false,
      // },
      period: {
        title: 'Class Time (dd-mm-yy)',
        type: 'string',
        editable: false,
      },
      // lastUpdate: {
      //   title: 'Last Update',
      //   type: 'string',
      //   editable: false,
      // },
    },
  };

  constructor(
    private feedbackService: FeedbackService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

  ) {
    this._initData();
  }

  // onUserRowSelect(event): void {
  //   this.router.navigateByUrl(`/pages/teacher/${event.data.id}/profile`);
  // }

  ngOnDestroy() {
    this.alive = false;
  }

  private async _initData() {

    this.activatedRoute.params.subscribe(async params => {
      const teacherId = params['id'];
      try {
        const result = await this.feedbackService.getTeacherFeedbacksByTeacherId(teacherId);
        for (const data of result) {
          const start = new Date(data.slotStart);
          data.period = DateTime.fromMillis(data.slotStart).toFormat('HH:mm') + '-' + DateTime.fromMillis(data.slotEnd).toFormat('HH:mm') + ' - ' + DateTime.fromMillis(data.slotStart).toFormat('dd-MM-yyyy');
          data.lastUpdate = DateTime.fromMillis(data.lastUpdate).toFormat(config.DATE_TIME_SHORT_FORMAT);
        }
        result.sort(this.compare);

        this.data = result;
      } catch (ex) {
        console.error('getTeacherFeedbacksByTeacherId: ', ex);
      }
    });

  }

  onEditConfirm(event): void {
    if (window.confirm('Are you sure you want to Edit?')) {
      const newData = {
        content: event.newData.content,
      };
      this.feedbackService.updateTeacherFeedBack(event.newData.id, newData)
        .then(() => {
          return event.confirm.resolve();
        })
        .catch(() => event.confirm.reject());
    } else {
      event.confirm.reject();
    }
  }


  onUserRowSelect(event): void {
    // TODO tralve exactly calendar time of class
    this.router.navigateByUrl(`/pages/booking/${event.data.teacherId}/class?day=${event.data.slotStart}`);
  }

  compare(a, b) {
    if (a.slotStart > b.slotStart) {
      return -1;
    }
    if (a.slotStart < b.slotStart) {
      return 1;
    }
    return 0;
  }

}
