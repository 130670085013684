import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { TeacherService, ApiService } from '../../../services';
import { config } from '../../../config';
import { DialogUploadComponent } from '../dialog-upload/dialog-upload.component';
import { NbDialogService } from '@nebular/theme';


@Component({
  selector: 'ngx-teacher-profile-edit',
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile-edit.component.html',
})
export class TeacherProfileEditComponent implements OnDestroy {

  private alive = true;
  showPassword = false;

  teacher: any = {};
  teacherId: string;
  avatarUrl: string;
  safeURL: any;
  currentPassword: string;
  newPassword: string;
  isPasswordField: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private teacherService: TeacherService,
    private dialogService: NbDialogService,
    private _sanitizer: DomSanitizer,
  ) {
    this._initData();
  }

  ngOnDestroy() {
    this.alive = false;
  }


  private async _initData() {
    this.activatedRoute.params.subscribe(async params => {
      this.teacherId = params['id'];
      try {
        this.teacher = await this.teacherService.getTeacher(this.teacherId);
        this.avatarUrl = this.teacher.avatarUrl || config.DEFAULT_IMAGE;
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.teacher.introduceVideoUrl);
      } catch (ex) {

      }
    });
  }

  onUpdate() {
    const teacher = {
      id: this.teacherId,
      fullName: this.teacher.fullName,
      email: this.teacher.email,
      gender: this.teacher.gender,
      dob: this.teacher.dob,
      skypeAccount: this.teacher.skypeAccount,
      zaloAccount: this.teacher.zaloAccount,
      introduce: this.teacher.introduce,
      introduceVideoUrl: this.teacher.introduceVideoUrl,
    };
    this.teacherService.updateTeachers(this.teacherId, teacher).then(() => {
      this.apiService.showToast('Success', 'Update profile', 'success');
    });
    // change pwd
    if (this.currentPassword && this.newPassword) {
      const userPassword = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };
      this.teacherService.updateTeacherPassword(this.teacherId, userPassword).then(() => {
        this.apiService.showToast('Success', 'Update password', 'success');
      });
    }
  }


  openUploadDialog() {
    this.dialogService.open(DialogUploadComponent).onClose.subscribe(
      async (data) => {
        if (!data || !data.fileUrl) {
          return;
        }
        // add timeout because S3 generate URL before upload, so if we get early image uploaded will get 404
        setTimeout(() => {
          this.avatarUrl = data.fileUrl;
        }, 3000);
        const tmpUser = {
          avatarUrl: data.fileUrl,
          shortAvatarUrl: data.shortFileUrl,
        };
        this.teacherService.updateTeachers(this.teacherId, tmpUser);
      });
  }

  onEmbedUrlChange(event) {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(event);
  }

  togglePassword() {
    if (this.isPasswordField) {
      this.isPasswordField = false;
    } else {
      this.isPasswordField = true;
    }
  }

}
