<div class="row">

  <div class="col-lg-5">
    <nb-card>
      <!-- <nb-card-header></nb-card-header> -->
      <nb-card-body>
        <div class="row">
          <div class="col-lg-5">
            <img class="teacher-avatar" [src]="avatarUrl" attr.loading="lazy">
            <button nbButton fullWidth status="basic" (click)="openUploadDialog()">Upload an Image </button>
          </div>
          <div class="col-lg-7">
            <h4>{{teacher.fullName}}</h4>
            <div class="teacher-title">{{teacher.title}}</div>

            <!-- start rating -->
            <ng-template #t let-fill="fill">
              <span class="star" [class.full]="fill === 100">
                <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
              </span>
            </ng-template>

            <ngb-rating [(rate)]="teacher.rate" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
            <span class="rate">{{teacher.rate}}</span>

            <div>Success Class: {{teacher.successClass}}</div>
            <div>Opened Class: {{teacher.openedClass}}</div>
            <br>
          </div>
        </div>

        <!-- introduceVideoUrl -->
        <input type="text" nbInput fullWidth [(ngModel)]="teacher.introduceVideoUrl" (ngModelChange)="onEmbedUrlChange($event)" placeholder="Embed URL link">
        <iframe [src]='safeURL' width="100%" height="315" frameborder="0" allowfullscreen></iframe>
        <!-- <br> -->
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-lg-7">
    <nb-card>
      <!-- <nb-card-header>Default Inputs</nb-card-header> -->
      <nb-card-body>
        <!-- <div class="full-name-inputs">
          <input type="text" nbInput fullWidth placeholder="Last Name">
        </div> -->
        <input type="text" nbInput fullWidth [(ngModel)]="teacher.fullName" placeholder="First Name">
        <input type="text" nbInput fullWidth [(ngModel)]="teacher.title" placeholder="Professional Teacher">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="teacher.email" placeholder="email">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="teacher.gender" placeholder="Male | Female | ...">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="teacher.dob" placeholder="20/10/1992">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="teacher.skypeAccount" placeholder="Skype Account">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="teacher.zaloAccount" placeholder="Zalo Account">
        <textarea rows="10" nbInput fullWidth shape="rectangle" [(ngModel)]="teacher.introduce" placeholder="say about your self to seductive students"></textarea>
        <a nbButton status="info" ghost (click)="togglePassword()">Click to change password</a>
        <input type="text" nbInput fullWidth shape="rectangle" *ngIf="isPasswordField" [(ngModel)]="currentPassword" placeholder="Current Password">
        <input type="text" nbInput fullWidth shape="rectangle" *ngIf="isPasswordField" [(ngModel)]="newPassword" placeholder="New Password">
        <button nbButton fullWidth status="success" (click)="onUpdate()">Save</button>
      </nb-card-body>
    </nb-card>
  </div>

</div>
