<!--Breadcrumb Area-->
<!-- <section class="breadcrumb-area banner-11" data-background="assets//assets/images/banner/11.jpg"> -->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/khoa-hoc', 'ielts', '']">Khoá Học</a></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>IELTS</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start About-->
<section class="service pad-tb bg-gradient5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="image-block wow fadeIn">
          <img src="assets/images/service/service-img-3.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-8 block-1">
        <div class="common-heading text-l pl25">
          <span>Gới Thiệu</span>
          <h2>IELTS là gì</h2>
          <p>IELTS là viết tắt của International English Language Testing System
            là một hệ thống bài kiểm tra về khả năng sử dụng thành thạo <a
              href="https://vi.wikipedia.org/wiki/Ti%E1%BA%BFng_Anh" title="Tiếng Anh" target="_blank">tiếng Anh</a>
            trải dài qua cả bốn kĩ năng Nghe, Nói, Đọc, Viết.
            Bài thi được đồng điều hành bởi ba tổ chức ESOL của Đại học <a
              href="https://vi.wikipedia.org/wiki/%C4%90%E1%BA%A1i_h%E1%BB%8Dc_Cambridge" title="Đại học Cambridge"
              target="_blank">Đại học Cambridge</a> (University of Cambridge ESOL),
            <a href="https://vi.wikipedia.org/wiki/H%E1%BB%99i_%C4%91%E1%BB%93ng_Anh" title="" target="_blank">Hội đồng
              Anh</a> (British Council)
            và tổ chức giáo dục IDP của Úc và được triển khai từ năm 1989.
            Người thi có thể lựa chọn giữa hai hình thức: Academic (học thuật) hoặc General training module (đào tạo
            chung).
            Tham khảo thêm thông tin tại <a href="https://vi.wikipedia.org/wiki/IELTS" target="_blank">Wiki</a></p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start Key points-->
<section class="service light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="servie-key-points">
          <h3>Khoá học IELTS dành cho ai?</h3>
          <p>Các bạn học sinh, sinh viên chuẩn bị chứng chỉ IELTS để xin học bổng, đi du học, khóa học nghề, muốn tìm
            việc làm, hoặc vì mục đích di cư...</p>
          <p>Các bạn đi làm chuẩn bị IELTS cho chuẩn bị để xin visa đi làm việc nước ngoài, nâng cao khả năng bản thân,
            học tiếp sau đại học, định cư nước ngoài...</p>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="servie-key-points mt20">
          <h3>Ưu điểm khoá học IETLS</h3>
          <ul class="key-points mt15">
            <li>100% Giáo Viên nước ngoài (Philippines) được tuyển chọn đều có nhiều năm kinh nghiệm giảng dạy online
              cũng như ở các trường chuyên dạy ngoại ngữ, sẽ giúp bạn 100% dùng tiếng Anh trong lớp không bị lối mòn dạy
              tiếng Anh bằng tiếng Việt, từ đó hình thành tuy duy, suy nghĩ, diễn đạt bằng tiếng Anh một cách tự nhiên.
            </li>
            <li>Lớp học 1 kèm 1 của elseTalk nhằm để giáo viên hoàn toàn tập trung vào 1 học viên là bạn, nên sẽ rất
              hiệu quả và tiết kiệm thời gian hơn lớp học truyền thống nhiều lần, sẽ giúp học viên sửa sai ngay lập tức
              và tìm ra điểm yếu của bạn để cải thiện triệt để.</li>
            <li>Theo dõi theo từng buổi học, sau mỗi buổi học giáo viên sẽ viết lại nhận xét, những tiến bộ của bạn cũng
              như là những điểm cần chú ý để cải thiện, giúp bạn tiến bộ theo từng ngày.</li>
            <li>Giờ Học linh hoạt từ sáng đến 23h mỗi ngày, kể cả thứ bảy, chủ nhật, thích hợp cho người bận rộn. Vì là
              lớp 1-1 nên bạn có thể đặt lịch học bất cứ lúc nào, bất cứ ngày nào và rất tiện lợi hơn lớp học cố định
              thời gian.</li>
            <li>Học tiếng IELTS ở bất cứ nơi đâu, học tại nhà, tại quán cafe, tại công ty của bạn… Chỉ cần điện
              thoại hoặc laptop kết nối internet, giúp bạn tiết kiệm thời gian đi tới trung tâm cố định, thời gian học
              trong lớp nhiều học viên vì thật sự bạn chỉ có vài phút để nói chưa kể tới việc trong lớp sẽ có nhiều bạn
              trình độ khác nhau, và khi bạn thực hành với bạn học có trình độ tương đương hoặc thấp hơn bạn sẽ không
              giúp bạn tiến bộ hơn.</li>
            <li>elseTalk có học phí hợp lý, nhiều gói khác nhau để bạn dễ dàng lựa chọn cho phù hợp và tiết kiệm hơn 5
              lần so với học tại trung tâm cố định.</li>
            <li>Elsetalk có lớp học thử miễn phí 100%, sau buổi học giáo viên sẽ viết nhận xét đánh giá trình độ tiếng
              Anh của bạn và để xuất giáo trình phù hợp.</li>
            <li>Chương trình học mới và giáo trình chuẩn quốc tế.</li>
            <li>elseTalk có Hệ thống đặt lịch và quản lý thông minh:</li>
            <div>
              <ul class="list-style-" style="margin-left: 30px;">
                <li>Giúp bạn chủ động hơn khi sắp xếp lịch học của mình, huỷ lớp một cách dễ dàng.</li>
                <li>Quản lý lớp học và thời gian học một cách dễ dàng.</li>
                <li>Giáo Viên sẽ đánh giá theo từng buổi học để bạn dễ dàng theo dõi và thấy mình tiến bộ theo từng
                  ngày.
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </div>
      <div class="servie-key-points mt20">
        <h3>Phương pháp học IETLS</h3>
        <ul class="key-points mt15">
          <li>Phương pháp học mới nhiều ưu điểm bạn có thể tham khảo <a [routerLink]="['/phuong-phap-hoc-tieng-anh', '']">tại đây </a></li>
        </ul>
      </div>
      <div class="servie-key-points mt20">
        <h3>Lộ trình Học IETLS</h3>
        <ul class="key-points mt15">
          <li>Cá nhân hoá theo từng học viên, nên sau khi làm bài kiểm tra trình độ tiếng Anh hiện tại với Giáo Viên nước ngoài, elseTalk sẽ đưa ra lộ trình phù hợp với khả năng và mục tiêu của bạn.</li>
        </ul>
      </div>
      <div class="servie-key-points mt20">
        <h3>Kết Quả</h3>
        <ul class="key-points mt15">
          <li>Học viên có lượng từ vựng khổng lồ về nhiều chủ đề khác nhau trong cuộc sống như du lịch, ẩm thực, khoa học, chính trị...</li>
          <li>Cải thiện 4 kỹ năng Nghe, Nói, Đọc Viết một cách toàn diện</li>
          <li>Có khả năng diễn thuyết cũng như diễn đạt thoải mái về các chủ đề trong cuộc sống bằng tiếng Anh</li>
          <li>Viết email, tài liệu, bài luận tiếng Anh sẽ không còn là vấn đề đâu đầu mất thời gian nữa.</li>
          <li>Cuối cùng là đạt được điểm IELTS mà bạn muốn</li>
        </ul>
      </div>
      <div class="servie-key-points mt20 mb40">
        <h3>Học phí</h3>
        <ul class="key-points mt15">
          <li>Giá hợp lý nhiều gói để học viên lựa chọn</li>
          <li>Bạn có thể tham khảo các gói <a [routerLink]="['/hoc-phi', 'ielts', '']">học phí</a></li>
          <li><a [routerLink]="['/lien-he', '']" [queryParams]="{title: 'ielts-course-help'}">Tư vấn</a> ngay để nhận nhiều ưu đãi bất ngờ! hoặc có thể chat với nhân viên tư vấn qua FB Messenger ở bên dưới</li>
        </ul>
        <ul class="key-points mt15">
          <li>Giá hợp lý nhiều gói để học viên lựa chọn</li>
          <li>Bạn có thể tham khảo các gói <a [routerLink]="['/hoc-phi', 'ielts', '']">học phí</a></li>
          <li>Đăng ký <a [routerLink]="['/lien-he', '']" [queryParams]="{title: 'trial-register'}">học thử</a> miễn
            phí</li>
          <li><a [routerLink]="['/lien-he', '']" [queryParams]="{title: 'register'}">Đăng ký</a>
             học ngay hoặc nhờ
            <a [routerLink]="['/lien-he', '']" [queryParams]="{title: 'ielts-course-help'}">tư vấn</a>
            để nhận nhiều
            ưu đãi bất ngờ! hoặc có thể chat với nhân viên tư vấn qua FB Messenger ở bên dưới
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--End Key points-->
