import { Component, OnInit, Renderer2 } from '@angular/core';

// import { MENU_ITEMS } from './pages-menu';
import { LoadScriptService, ApiService, SubscribeService, BlogService } from '../services';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-teacher-page',
  styleUrls: ['teacher.component.scss',],
  templateUrl: './teacher.component.html',
})
export class TeacherComponent implements OnInit {

  constructor(
    private loadScriptService: LoadScriptService,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle("Giáo viên | elseTalk");
    this.meta.updateTag({ name: 'description', content: 'Giáo viên elseTalk' });
    // for show slide menu and some script
    this.loadScriptService.loadScript(this.loadScriptService.INIT);
    // this.loadScriptService.loadScript(this.loadScriptService.OWL);
  }

  ngOnInit() {
  }

}
