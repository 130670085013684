import { Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { CallService } from '../video-call.service';
import { WindowRef } from '../../../services';
// const { RTCPeerConnection, RTCSessionDescription } = window;
// const configuration = { iceServers: [{ urls: "stun:stun.stunprotocol.org" }] }
// const peerConnection = new RTCPeerConnection(configuration);
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

let peerConnection: any
const configuration = {
  iceServers: [{
    'urls': [
      'stun:stun.l.google.com:19302',
      'stun:stun1.l.google.com:19302',
      'stun:stun2.l.google.com:19302',
      'stun:stun.l.google.com:19302?transport=udp',
    ]
  }]
};

@Component({
  selector: 'ngx-video-call',
  styleUrls: ['./room.component.scss'],
  templateUrl: './room.component.html',
})
export class RoomComponent implements OnDestroy, AfterViewInit {
  public isCallStarted$: Observable<boolean>;
  isDisableStartBtn = false;
  isDisableCallBtn = false;
  isDisableEndBtn = false;
  getCalled = false;
  isAlreadyCalling = false;
  socketId: String;
  localStream: any;
  screenStream: any;
  canvasStream: any;
  remoteStream: any;
  shareScreenStreamId: String;
  streamId: String;
  remoteVideoStreamId: String;

  public isMicroOn = true;
  public isCameraOn = true;
  public isShareScreen = false;

  // css
  localVideoCss = "local-video"
  remoteVideoCss = "remote-video";
  screenVideoCss = "";

  @ViewChild('screenVideo') screenVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('localVideo') localVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasVideo') canvasVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('pdf') imageObj: ElementRef;

  ctx: CanvasRenderingContext2D;

  constructor(
    @Inject(DOCUMENT) document,
    private callService: CallService,
    private cdref: ChangeDetectorRef,
    private windowRef: WindowRef,
    private http: HttpClient,
  ) {
    const { RTCPeerConnection, RTCSessionDescription } = this.windowRef.nativeWindow;
    // peerConnection = new RTCPeerConnection(configuration);

    let url = "api/document/getmypdf"; // Or your url
    this.downloadFile(url).subscribe(
      (res) => {
        // this.pdfViewerAutoLoad.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        // this.pdfViewerAutoLoad.refresh(); // Ask pdf viewer to load/refresh pdf
      }
    );
  }

  renderPDF() {
    let url = "https://dicom-interactive.com/assets/profile/Dicom-interactive-Portfolio.pdf";
    let options = { scale: 1 };

    function renderPage(page) {
      let viewport = page.getViewport(options.scale);
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let renderContext = {
        canvasContext: this.ctx,
        viewport: viewport
      };

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      this.canvas.nativeElement.appendChild(canvas);

      page.render(renderContext);
    }

    function renderPages(pdfDoc) {
      for (let num = 1; num <= pdfDoc.numPages; num++)
        pdfDoc.getPage(num).then(renderPage);
    }

    // @ts-ignore
    let pdfjs = PDFJS;
    pdfjs.disableWorker = true;
    pdfjs.getDocument(url).then(renderPages);
  }



  private downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  public openPdf() {
    let url = "url to fetch pdf as byte array"; // E.g. http://localhost:3000/api/GetMyPdf
    // url can be local url or remote http request to an api/pdf file. 
    // E.g: let url = "assets/pdf-sample.pdf";
    // E.g: https://github.com/intbot/ng2-pdfjs-viewer/tree/master/sampledoc/pdf-sample.pdf
    // E.g: http://localhost:3000/api/GetMyPdf
    // Please note, for remote urls to work, CORS should be enabled at the server. Read: https://enable-cors.org/server.html

    this.downloadFile(url).subscribe(
      (res) => {
        // this.pdfViewerOnDemand.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        // this.pdfViewerOnDemand.refresh(); // Ask pdf viewer to load/reresh pdf
      }
    );
  }

  ngAfterViewInit(): void {
    console.log('Requesting local stream');
    const self = this;
  }

  ngOnDestroy(): void {

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  /**
   * Binding function
   */
  async startCall() {
    await this.init();
    // join to room and sent the offer
    const roomCode = 'room-x';
    this.callService.sendData("join-room", { roomCode });

    // if not have offer we will create one
    this.callService.listen('listen-new-room-member', async (data) => {
      // 
      if (!this.socketId) {
        this.socketId = data.socketId;
        console.log('listen-new-room-member joined');
      } else if (this.socketId != data.socketId) {
        // send offer
        let offer = await this.createOffer(roomCode);
        this.callService.sendData('create-offer', { roomCode, offer });
        console.log('create-offer: ', { roomCode, offer });
      } else {
        console.log('unkonw socketId:: ', data.socketId);
      }
    });

    this.callService.listen('listen-offer', async (data) => {
      console.log('listen-offer: ', data);
      if (data) {
        let answer = await this.createAnswer(roomCode, data.offer);
        this.callService.sendData('create-answer', { roomCode, answer });
        console.log('create-answer: ', { roomCode, answer });
      }
    });

    this.callService.listen('listen-answer', async (data) => {
      console.log('listen-answer: ', data);
      if (data) {
        console.log('add-answer: ', data);
        await this.addAnswer(data.answer);
      }
    });

    this.callService.listen('listen-candidate', async (data) => {
      console.log('listen-candidate: ', data);
      if (data) {
        peerConnection.addIceCandidate(data.candidate);
      }
    });

  }

  /**
   * Binding function
   */
  async shareCanvas() {
    // @ts-ignore
    this.canvasStream = this.canvas.nativeElement.captureStream();
    console.log('shareCanvas() ', this.canvasStream);
    this.canvasVideo.nativeElement.srcObject = this.canvasStream;
    // await peerConnection.addTrack(track, canvasStream);

    //  // @ts-ignore
    // this.screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });

    // if (this.canvasVideo) {
    //   console.log('getDisplayMedia local stream:: ', this.screenStream);
    //   this.screenStream.xName = 'elsetalk-sharescreen'
    //   this.canvasVideo.nativeElement.srcObject = this.screenStream;
    //   // this.streamId = stream.id;
    // }


    // peerConnection.getSenders().forEach(function (rtpSender) {
    //   if (rtpSender.track.kind === 'video') {
    //     rtpSender.replaceTrack(track);
    //   }

    //   // if (rtpSender.track.kind === 'audio') {
    //   //   rtpSender.replaceTrack(track);
    //   // }
    // });
  }

  async draw() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    // this.ctx.drawImage(this.imageObj.nativeElement, 100, 200);
    const colors = ['#FF0000', '#32a852', '#a2a832', '#b613d6', '#1320d6']
    const x = Math.round(Math.random() * 100)
    const y = Math.round(Math.random() * 100)
    const w = Math.round(Math.random() * 100)
    const h = Math.round(Math.random() * 100)
    this.ctx.fillStyle = colors[Math.round(Math.random() * 4)];
    this.ctx.fillRect(x, y, w, h);
  }

  async init() {
    await this.shareCanvas();

    this.localStream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: true }, video: true });
    // this.remoteStream = new MediaStream();

    this.localVideo.nativeElement.srcObject = this.localStream;
    this.localVideo.nativeElement.muted = true;
    // this.remoteVideo.nativeElement.srcObject = this.remoteStream;
  }

  async createOffer(roomCode) {
    this.createPeerConnection(roomCode);

    // this.remoteVideo.nativeElement.srcObject = this.remoteStream;
    let offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(new RTCSessionDescription(offer));
    return offer;
  }

  async createOfferWithoutConnection(roomCode) {
    // this.remoteVideo.nativeElement.srcObject = this.remoteStream;
    let offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(new RTCSessionDescription(offer));
    return offer;
  }

  async createAnswer(roomCode, offer) {
    this.createPeerConnection(roomCode);

    await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));

    let answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(new RTCSessionDescription(answer));
    return answer;
  }

  async addAnswer(answer: any) {
    if (!answer) return alert('Retrieve answer from peer first...')
    // answer = JSON.parse(answer)

    if (!peerConnection.currentRemoteDescription) {
      await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
      console.log('addAnswer setRemoteDescription...');
    }
  }

  /**
   * peerConnection.addTrack only working in this function
   * 
   * @param roomCode
   */
  private async createPeerConnection(roomCode) {
    peerConnection = new RTCPeerConnection(configuration);
    // this.remoteStream = new MediaStream();
    // this.remoteVideo.nativeElement.srcObject = this.remoteStream;
    if (this.localStream) {
      this.localStream.getTracks().forEach((track: any) => {
        peerConnection.addTrack(track, this.localStream);
      });
    }

    if (this.screenStream) {
      this.screenStream.getTracks().forEach(track => peerConnection.addTrack(track, this.screenStream));
    }
    if (this.canvasStream) {
      this.canvasStream.getTracks().forEach(track => peerConnection.addTrack(track, this.canvasStream));
    }

    peerConnection.ontrack = async ({ streams }) => {
      // streams[0].getTracks().forEach((track: any) => {
      //   this.remoteStream.addTrack(track);
      // });
      console.log('ontrack remoteStream() ', streams[0]);
      this.remoteStream = new MediaStream(streams[0]);
      // console.log('ontrack remoteStream() ', this.remoteStream);
      // if (this.remoteStream.xname == 'canvas') {
      //   console.log('find canvas streaming.............................');
      //   return;
      // }

      this.remoteVideo.nativeElement.srcObject = this.remoteStream;
    }
    peerConnection.onnegotiationneeded = function() {
      console.log('onnegotiationneeded.......................');
      // this.createOfferWithoutConnection(roomCode);
    };

    peerConnection.onicecandidate = async (event: any) => {
      if (event.candidate) {
        this.callService.sendData('create-candidate', { candidate: event.candidate, roomCode });
      }
    }
  };
}


// https://stackoverflow.com/questions/29108654/webrtc-sequence-of-events?
// https://www.webrtc-experiment.com/
// https://www.webrtc-experiment.com/demos/screen-and-video-from-single-peer.html
// https://www.w3.org/TR/mediacapture-streams/#mediastream

// for share screen and video call with one connection
// https://stackoverflow.com/questions/22865481/webrtc-switch-from-video-sharing-to-screen-sharing-during-call#comment34905046_22866316
// https://www.webrtc-experiment.com/demos/screen-and-video-from-single-peer.html



// https://github.com/muaz-khan/RTCMultiConnection/tree/master/demos
// https://github.com/muaz-khan/RTCMultiConnection/blob/master/demos/dashboard/canvas-designer.html