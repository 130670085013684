<nb-card>
  <nb-card-header>Upload</nb-card-header>
  <nb-card-body>
    <!-- icon to click to update -->
    <nb-icon *ngIf="isShowUploader" icon="cloud-upload" status="basic" (click)="triggerUploader()"></nb-icon>
    <!-- upload file input -->
    <input #uploader type="file" (change)="fileChangeEvent($event)" hidden />

    <!-- croper image -->
    <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="4 / 3"
        [cropperStaticWidth]= "800"
        [cropperStaticHeight]= "800"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
        style="width: 100%; max-height: 560px;"
    ></image-cropper>

    <!-- <img [src]="croppedImage" width="50%"/> -->
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="danger" (click)="cancel()"  class="close">Close</button>
    <button nbButton status="success" (click)="submit()" class="btn-submit">Upload</button>
  </nb-card-footer>
</nb-card>
