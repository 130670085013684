import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class TraceBackService {

  constructor(private apiService: ApiService) { }

  public add = (data) => this.apiService.post('trace-backs', data);


}
