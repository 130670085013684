import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NbThemeService, NbComponentStatus, NbGlobalPosition, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';
import { NbDatepickerComponent } from '@nebular/theme';

import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import { Calendar } from '@fullcalendar/core'; // useful for typechecking

import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import dayGridPlugin from '@fullcalendar/daygrid'; // for dayGridMonth view

import { ActivatedRoute, Router } from '@angular/router';
import { SlotService, TeacherService, FeedbackService, RemarkService } from '../../../services';
import { DateTime } from 'luxon';
import { combineLatest } from 'rxjs';

import { DialogPickSlotComponent } from '../dialog-pick-slot/dialog-pick-slot.component';
import { DialogRemoveSlotComponent } from '../dialog-remove-slot/dialog-remove-slot.component';
import { DialogFeedBackSlotComponent } from '../dialog-feedback-slot/dialog-feedback-slot.component';

import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { config } from '../../../config';
import { SlotState } from '../../../utils';


@Component({
  selector: 'ngx-teacher-calendar-self',
  styleUrls: ['./teacher-calendar-self.component.scss'],
  templateUrl: './teacher-calendar-self.component.html',
})
export class TeacherCalendarSelfComponent {

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  @ViewChild(NbDatepickerComponent) datepicker;
  // https://fullcalendar.io/docs/v4/angular
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  // getting the calendar api
  calendarApi: Calendar;
  selected: { startDate: Date, endDate: Date };
  self: any;
  authUserId: any;
  public teacherId: any;
  isOpenClassEndOfAllSelectedDay: boolean;

  // Show TOASTR
  status: NbComponentStatus = 'danger';
  titleToastr = 'Bạn không có quyền';
  content = ``;

  destroyByClick = true;
  duration = 2000;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;

  async change(data) {
    if (data && data.startDate) {
      const startDate = new Date(data.startDate?._d);
      const endDate = new Date(data.endDate?._d);
      const offset = startDate.getTimezoneOffset();
      const startHour = this.convertMilliseconds(startDate);
      let endHour = this.convertMilliseconds(endDate);
      const startTimestamp = startDate.getTime() - startHour;
      const endTimestamp = endDate.getTime() - endHour;

      if (this.isOpenClassEndOfAllSelectedDay) {
        endHour = 85800000; // 23:50
      }

      const slot = {
        'teacherId': this.teacherId,
        'startTimestamp': startTimestamp,
        'endTimestamp': endTimestamp,
        'startHour': startHour,
        'endHour': endHour,
        'teacherTimezoneOffset': offset,
      };
      await this.slotService.bookTeacher(this.teacherId, slot);
      await this._initData();
    }
  }


  constructor(
    private toastrService: NbToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private teacherService: TeacherService,
    private slotService: SlotService,
    private feedBackService: FeedbackService,
    private remarkService: RemarkService,
    private dialogService: NbDialogService,
  ) {
    this.authUserId = localStorage.getItem('user_id');
    this._initData();
    this.self = this;
  }


  title = 'calendar-booking';
  events: any = [];
  slots: any = [];
  mapSlots = new Map();

  calendarOptions: CalendarOptions = {
    // plugins: [ timeGridPlugin ],
    plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin],
    initialView: 'timeGridWeek',
    selectable: true,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridMonth,timeGridWeek,timeGridDay',
    },
    firstDay: 1,
    contentHeight: 'auto',
    handleWindowResize: true,
    // eventDrop: function (info) {
    //   alert(info.event.title + ' was dropped on ' + info.event.start.toISOString());

    //   if (!confirm('Are you sure about this change?')) {
    //     info.revert();
    //   }
    // },
    displayEventTime: true,
    // views: {
    //   dayGrid: {
    //     // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
    //   },
    //   timeGrid: {
    //     // options apply to timeGridWeek and timeGridDay views
    //   },
    //   week: {
    //     // options apply to dayGridWeek and timeGridWeek views
    //   },
    //   day: {

    //     // options apply to dayGridDay and timeGridDay views
    //   },
    // },
    navLinks: true,
    editable: false,
    // droppable: true,
    initialEvents: this.slots,
    // select: this._handleDateSelectRange.bind(this),
    dateClick: this._handleDateSelectRange.bind(this),
    eventClick: this._bookClass.bind(this),
    customButtons: {
      prev: {
        text: 'prev',
        click: this._prevWeekCalendar.bind(this)

      },
      next: {
        text: 'next',
        click: this._nextWeekCalendar.bind(this)
      },
    },
  };

  enterTitle(arg) {
    const title = prompt(`Creat an event from ${arg.startStr} to ${arg.endStr}`, '');
    return title;
  }

  // onChange of checkbox
  onChangOpenClassEndOfAllSelectedDay(checked) {
    this.isOpenClassEndOfAllSelectedDay = checked;
  }

  _handleDateSelectRange(arg) {
    this.pickerDirective.open();
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    combineLatest([this.route.params, this.route.queryParamMap])
      .subscribe(([params, queryParams]) => {
        let day = queryParams.get('day');
        if (day) {
          try {
            const tmp = Number(day);
            if (!isNaN(tmp)) {
              day = DateTime.fromMillis(tmp).toFormat('yyyy-MM-dd');
            }
          } catch (e) {
            // nothing to do here
          }
          this.calendarApi.changeView('timeGridDay', day);
        }
      });
  }

  _prevWeekCalendar(){
    this.calendarApi.prev();
    this._initData();
  }


  _nextWeekCalendar(){
    this.calendarApi.next();
    this._initData();

  }


  private showToast(type: NbComponentStatus, title: string, body: string) {
    const toastConfig = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };

    this.toastrService.show(
      body,
      `${title}`,
      toastConfig);
  }

  private async _initData() {
    this.route.params.subscribe(async params => {
      this.teacherId = params['id'];
      if (this.authUserId !== this.teacherId) {
        this.showToast(this.status, this.titleToastr, this.content);
        this.router.navigate(['/pages/teachers']);
      }
      let guruCurrentTime = DateTime.local();
      // when init on contructor calendarApi is null
      if (this.calendarApi) {
        guruCurrentTime = DateTime.fromISO(this.calendarApi.getDate().toISOString());
      }
      // start of time by format
      const start = guruCurrentTime.startOf('week').ts;
      // end of time by format
      const end = guruCurrentTime.endOf('week').ts;
      const slots: any = await this.slotService.getSlotsByTeacherId(this.teacherId, start, end);
      for (const slot of slots) {
        if (slot.userId) {
          // TODO check complete without absent
          if (this._isCompleteClass(slot)) {
            if (slot.state == SlotState.TEACHER_ABSENT) {
              slot.backgroundColor = config.TEACHER_ABSENT_BG;
            // student report teacher absent and teacher update student absent, confict
            } else if (slot.state == SlotState.STUDENT_ABSENT && slot.isReportTeacherAbsent) {
              slot.backgroundColor = config.REPORT_TEACHER_STUDENT_ABSENT_BG;
            } else if (slot.state == SlotState.STUDENT_ABSENT) {
              slot.backgroundColor = config.STUDENT_ABSENT_BG;
            } else if (slot.isReportTeacherAbsent) { // student report teacher absent
              slot.backgroundColor = config.REPORT_TEACHER_ABSENT_BG;
            } else {
              slot.backgroundColor = config.COMPLETE_SLOT_BG;
            }
          } else {
            slot.backgroundColor = config.OWNER_SLOT_BG;
          }
          // set user name for default slot title
          slot.title = slot.userName + " " + slot.title ?? "";
        } else {
          // if overtime slot
          if (!this._allowBookClass(slot)) {
            slot.backgroundColor = config.DISABLE_SLOT_BG;
            slot.title = "overtime";
          } else if (this._isCompleteClass(slot)) { // if complete slot
            slot.backgroundColor = config.DISABLE_SLOT_BG;
          } else {
            slot.backgroundColor = config.FREE_SLOT_BG;
          }
        }
        this.mapSlots.set(slot.id, slot);
      }
      this.slots = slots;
      this.calendarOptions.events = this.slots;
    });

  }

  async _bookClass(info) {
    const slotId = info.event.id;
    const pickSlot = this.mapSlots.get(slotId);
    const header = DateTime.fromMillis(pickSlot.start).toFormat('HH:mm') + ' - ' + DateTime.fromMillis(pickSlot.end).toFormat('HH:mm')
      + ' ' + DateTime.fromMillis(pickSlot.end).toFormat('DD');
    // click remove slot
    if (pickSlot.userId) {
      // booking complete
      if (this._isCompleteClass(pickSlot)) {
        const feedbacks = await this.feedBackService.getTeacherFeedbackBySlotId(slotId);
        const feedback = feedbacks[0];
        const remarks = await this.remarkService.getRemarkBySlotId(slotId);
        const remark = remarks[0] ?? {};
        this.dialogService.open(DialogFeedBackSlotComponent, {
          context: {
            header,
            slot: pickSlot,
            remark,
            content: feedback?.content, // feedback
          },
        }).onClose.subscribe(
          async (data) => {
            if (data && !data.isCancle && !data.isRemark) {
              // add feedback
              const newFeedBack = {
                userId: pickSlot.userId,
                teacherId: this.teacherId,
                teacherName: pickSlot.teacherName,
                content: data.content,
                slotId: slotId,
                slotTitle: pickSlot.title,
                slotStart: pickSlot.start,
                slotEnd: pickSlot.end,
              };
              if (feedback) {
                if (!data.content) {
                  // delete feedback
                  await this.feedBackService.deleteTeacherFeedBack(feedback.id);
                } else {
                  // call update feedback
                  await this.feedBackService.updateTeacherFeedBack(feedback.id, newFeedBack);
                }
              } else if (data.content) {
                // call create feedback if input content
                await this.feedBackService.createTeacherFeedBack(newFeedBack);
              }
              // report absent
              if (pickSlot.isTeacherAbsent != data.checkedTeacher || pickSlot.isStudentAbsent != data.checkedStudent) {
                // report absent
                const absent = {
                  isTeacherAbsent: data.checkedTeacher,
                  isStudentAbsent: data.checkedStudent,
                };
                await this.slotService.updateSlots(slotId, absent);
              }
              this._initData();
            }
            else if (data && !data.isCancle && data.isRemark) { // REMARK
              delete data.isRemark;
              // NOTED: remark is object referent, that euqual data
              if (remark.id) {
                // call update feedback
                await this.remarkService.updateRemark(remark.id, data);
              } else if (data.materialName) {
                data.slotId = slotId;
                // call create feedback if input content
                await this.remarkService.createRemark(data);
              }
            } else {
              // DO no thing
            }
          });
        return;
      }

      const latestRemarks = await this.remarkService.getRemarkLatestByStudentId(pickSlot.userId);
      const latestRemark = latestRemarks[0] ?? {};
      // view class info
      this.dialogService.open(DialogPickSlotComponent, {
        context: {
          header,
          slot: pickSlot,
          remark: latestRemark,
        },
      });
      return false;
    } else {
      // not allow remove overtime class
      if (this._isCompleteClass(pickSlot)) {
        return;
      }
      this.dialogService.open(DialogRemoveSlotComponent, {
        context: {
          header,
        },
      }).onClose.subscribe(
        async (data) => {
          if (data.isCancle) {
            return;
          }
          // remove slot
          if (data.isRemove) {
            await this.slotService.deleteSlots(slotId).then(function () {
              // TODO update slots
            });
            await this._initData();
          }
        });
    }
  }


  // ngOnDestroy() {
  //   // this.alive = false;
  // }

  convertMilliseconds(srcDate) {
    const minute = srcDate.getHours() * 60 + srcDate.getMinutes();
    const ONE_MINUTE_MILISECOND = 60000;
    return minute * ONE_MINUTE_MILISECOND;
  }

  _isCompleteClass(slot) {
    const now = new Date();
    return slot.state == SlotState.COMPLETED || now.getTime() >= slot.end;
  }

  _allowBookClass(slot) {
    const now = new Date();
    return now.getTime() <= (slot.start - config.LEAD_TIME_BOOK_CLASS);
  }

}
