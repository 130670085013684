import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-pick-slot',
  templateUrl: 'dialog-pick-slot.component.html',
  styleUrls: ['dialog-pick-slot.component.scss'],
})
export class DialogPickSlotComponent {

  header: string;
  slot: any;
  remark: any;

  constructor(protected ref: NbDialogRef<DialogPickSlotComponent>) { }

  cancel() {
    this.ref.close();
  }

}
