import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  // NbChatModule,
  NbDatepickerModule,
  NbCardModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';

import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken, NbAuthService } from '@nebular/auth';

// 3rd
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { BlogComponent } from './blog/blog.component';
import { TeacherComponent } from './teacher/teacher.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';
import { CourseCumnicationComponent } from './course/comunication/course.component';
import { CourseBusinessComponent } from './course/business/course.component';
import { CourseIELTSComponent } from './course/ielts/course.component';
import { CourseCustomizeComponent } from './course/customize/course.component';
import { PriceCumnicationComponent } from './price/comunication/price.component';
import { PriceIELTSComponent } from './price/ielts/price.component';
import { PriceCustomizeComponent } from './price/customize/price.component';
import { ContactComponent } from './contact/contact.component';
import { MethodComponent } from './method/method.component';
import { CourseOwlComponent } from './component/course/course.component';
import { CTAComponent } from './component/cta/cta.component';

import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { NbThemeModule, NbLayoutModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
// import { PagesComponent } from './pages/pages.component';
import { PagesModule } from './pages/pages.module';
import { RegisterDoneComponent } from './pages/register-done/register-done.component';

import { config } from './config';


const formSetting: any = {
  redirectDelay: 0,
  showMessages: {
    success: true,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    CourseCumnicationComponent,
    CourseBusinessComponent,
    CourseIELTSComponent,
    CourseCustomizeComponent,
    PriceCumnicationComponent,
    PriceIELTSComponent,
    PriceCustomizeComponent,
    MethodComponent,
    ContactComponent,
    TeacherComponent,
    BlogComponent,
    BlogComponent,
    BlogComponent,
    BlogSingleComponent,
    CourseOwlComponent,
    CTAComponent,
    // RegisterDoneComponent,
    // PagesComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    PagesModule,
    NbCardModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    // NbChatModule.forRoot({
    //   messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    // }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            class: NbAuthJWTToken,
            key: 'token',
          },
          baseEndpoint: config.END_POINT,
          login: {
            endpoint: 'users/login/email',
            method: 'post',
            requireValidToken: true,
            redirect: {
              success: '/pages/booking/teachers',
              failure: null,
            },
            defaultErrors: ['Login/Email combination is not correct or your account is inactive, please try again.'],
            defaultMessages: ['You have been successfully logged in.'],
          },
          logout: {
            // endpoint: `users/${localStorage.getItem('user_id')}/logout`,
            endpoint: `users/x/logout`,
            redirect: {
              success: '/auth/login',
              failure: null,
            },
            defaultErrors: ['Something went wrong, please try again.'],
            defaultMessages: ['You have been successfully logged out.'],
          },
          register: {
            endpoint: `users/register/individual`,
            requireValidToken: false,
            redirect: {
              success: '/auth/register-done',
              failure: null,
            },
            // terms: true, // remove term&condition
            defaultErrors: ['Something went wrong, please try again.'],
            defaultMessages: ['You have been successfully registered.'],
          },
          requestPass: {
            endpoint: `users/forgot-password`,
            redirect: {
              success: null,
              failure: null,
            },
            defaultErrors: ['Something went wrong, please try again.'],
            defaultMessages: ['Reset password instructions have been sent to your email.'],
          },
          validation: {
            password: {
              required: true,
              minLength: 1,
              maxLength: 50,
            },
            // email: {
            //   required: true,
            //   regexp: "/^[^\s@]+@[^\s@]+\.[^\s@]+$/"
            // },
            fullName: {
              required: false,
              minLength: 4,
              maxLength: 50,
            },
          },
        }),
      ],
      forms: {
        login: formSetting,
      },
    }),
  ],
  providers: [
    {
      provide: 'isBrowser', useValue: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
