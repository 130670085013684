// http://www.lukasjakob.com/how-to-dynamically-load-external-scripts-in-angular/
import { Injectable } from '@angular/core';

const myScripts = [

  { name: '1', src: 'assets/js/jquery-3.3.1.min.js' },
  // { name: '2', src: 'assets/js/jquery-migrate-3.0.1.min.js' },
  { name: '3', src: 'assets/js/jquery-ui.js' },
  { name: '4', src: 'assets/js/popper.min.js' },
  { name: '5', src: 'assets/js/bootstrap.min.js' },
  { name: '6', src: 'assets/js/owl.carousel.min.js' },
  { name: '7', src: 'assets/js/jquery.stellar.min.js' },
  { name: '8', src: 'assets/js/jquery.countdown.min.js' },
  { name: '9', src: 'assets/js/bootstrap-datepicker.min.js' },
  { name: '10', src: 'assets/js/jquery.easing.1.3.js' },
  { name: '11', src: 'assets/js/aos.js' },
  { name: '12', src: 'assets/js/jquery.fancybox.min.js' },
  { name: '13', src: 'assets/js/jquery.sticky.js' },
  { name: 'main', src: 'assets/js/main.js' },
  { name: 'owl', src: 'assets/js/owl.js' },
];

@Injectable({
  providedIn: 'root',
})
export class LoadScriptService {

  private scripts: any = {};
  public INIT = 'main';
  public OWL = 'owl';
  public PROCESS_BAR = 'processBar';

  constructor() {
    myScripts.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  // load a single or multiple scripts
  load(...scripts: string[]) {
    const promises: any[] = [];
    // push the returned promise of each loadScript call
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  loadAll() {
    const promises: any[] = [];
    // push the returned promise of each loadScript call
    // myScripts.forEach((script) => promises.push(this.loadScript(script.name)));
    myScripts.forEach(async (script) => this.loadScript(script.src));

    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  // load the script
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      // if (this.scripts[name].loaded) {
      //   resolve({ script: name, loaded: true, status: 'Already Loaded' });
      // } else {
      // load script
      try {
        const script = document?.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.type = 'text/javascript';
        script.async = true;
        script.charset = 'utf-8';
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => reject({ script: name, loaded: false, status: 'Loading_fail', error });
        // finally append the script tag in the DOM
        document?.getElementsByTagName('footer')[0]?.appendChild(script);
      } catch (error) {

      }
      // }
    });
  }

}
