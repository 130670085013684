import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  constructor(private apiService: ApiService) { }

  public createStudentFeedBack = (feedback) => this.apiService.post('user-feedbacks', feedback);
  public updateStudentFeedBack = (id, feedback) => this.apiService.patch('user-feedbacks/' + id, feedback);
  public getStudentFeedbackBySlotId = (slotId) => this.apiService.get('user-feedbacks?filter[where][slotId]=' + slotId);
  public getStudentFeedbacksByTeacherId = (teacherId) => this.apiService.get('user-feedbacks?filter[where][teacherId]=' + teacherId);

  public createTeacherFeedBack = (feedback) => this.apiService.post('teacher-feedbacks', feedback);
  public deleteTeacherFeedBack = (id) => this.apiService.delete('teacher-feedbacks/' + id);
  public updateTeacherFeedBack = (id, feedback) => this.apiService.patch('teacher-feedbacks/' + id, feedback);
  public getTeacherFeedbackBySlotId = (slotId) => this.apiService.get('teacher-feedbacks?filter[where][slotId]=' + slotId);
  public getTeacherFeedbacksByStudentId = (userId) => this.apiService.get('teacher-feedbacks?filter[where][userId]=' + userId);
  public getTeacherFeedbacksByTeacherId = (teacherId) => this.apiService.get('teacher-feedbacks?filter[where][teacherId]=' + teacherId);

}
