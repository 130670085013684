<!--Start Hero-->
<section class="hero-section hero-bg-bg1 bg-gradient1">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 v-center">
          <div class="header-heading">
            <h1 class="wow fadeInUp" data-wow-delay=".2s"><span class="xhighlight">Học Tiếng Anh</span>Có Khó không?</h1>
            <p class="wow fadeInUp" data-wow-delay=".4s">Bạn học tiếng Anh đã nhiều năm, bạn vẫn chưa thể giao tiếp
              được, bạn luôn cảm thấy bối rối và xấu hổ khi nói tiếng Anh.
              Bạn đã thử rất nhiều cách khác nhau, nhưng tiếng Anh luôn làm bạn đau đầu, luôn cản trở bạn trong cuộc
              sống và thăng tiến trong công việc.
              Hãy để elseTalk giúp bạn học tiếng Anh thành công!</p>
            <a [routerLink]="['/lien-he/']" (click)="setContactTitle('register')" class="btn-main bg-btn2 lnk wow fadeInUp" data-wow-delay=".6s">Đăng Ký Ngay<i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="single-image wow fadeIn" data-wow-delay=".2s">
            <h1 class="wow fadeInUp" data-wow-delay=".2s">Đăng Ký Học Thử</h1>
            <div class="form-block mt20 mb10">
              <form #trialForm="ngForm" (ngSubmit)="onSubmitTrial(trialForm)" name="feedback-form">
                <div class="fieldsets row">
                  <div class="col-md-12">
                    <input type="text" name="fullName" id="fullName" [ngModel]="trial.fullName" #fullName="ngModel"
                      placeholder="Tên Của Bạn" required>
                    <div *ngIf="isTrialSubmitted || (fullName.invalid && (fullName.dirty || fullName.touched))">
                      <div *ngIf="fullName.errors?.required" class="help-block with-errors">Vui lòng nhập tên của bạn</div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <input type="email" id="email" name="email" [ngModel]="trial.email" #email="ngModel"
                      placeholder="Email của bạn" required email>
                    <div *ngIf="isTrialSubmitted || (email.invalid && (email.dirty || email.touched))">
                      <div *ngIf="email.errors?.required" class="help-block with-errors">Vui lòng nhập Email của bạn
                      </div>
                      <div *ngIf="email.errors?.email" class="help-block with-errors">Vui lòng kiểm tra lại Email của
                        bạn</div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <input type="number" id="mobile" name="phone" id="phone" [ngModel]="trial.phone" #phone="ngModel"
                      placeholder="Số điện thoại của Bạn" required data-error="Please fill Out">
                    <div *ngIf="isTrialSubmitted || (phone.invalid && (phone.dirty || phone.touched))">
                      <div *ngIf="phone.errors?.required" class="help-block with-errors">Vui lòng nhập số điện thoại của
                        bạn</div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <textarea id="content" rows="5" name="content" id="content" [ngModel]="trial.content"
                      #content="ngModel" placeholder="Yêu cầu đặc biệt" required></textarea>
                    <!-- <div *ngIf="isTrialSubmitted || (content.invalid && (content.dirty || content.touched))">
              <div *ngIf="content.errors?.required" class="help-block with-errors">Vui lòng nhập Nội Dung</div>
            </div> -->
                  </div>
                </div>
                <div class="fieldsets mt20 pb20">
                  <div *ngIf="isTrialSubmittedOK" class="help-block with-success">Đã đăng ký học thử thành công! elseTalk sẽ liên hệ với bạn để sắp lịch sớm nhất có thể!</div>
                  <button type="submit" name="submit" class="lnk btn-main bg-btn2" data-dismiss="modal">Đăng Ký Học Thử
                    Miễn Phí<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></button>
                </div>
              </form>
            </div>
            <!-- <img src="assets/images/hero/digital-marketing.png" alt="hero image" class="img-fluid"/> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Hero-->
<!--Start Service-->
<section class="service-section service-2 pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading">
          <span>Các khoá học dành cho bạn</span>
          <h2 class="mb30">Khoá Học</h2>
        </div>
      </div>
    </div>
    <div class="row upset link-hover">
      <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
        <div class="wide-block service-img1 hfull" data-tilt data-tilt-max="2" data-tilt-speed="600">
          <div class="block-space-">
            <span>Talk Talk</span>
            <h4>Tiếng Anh Giao Tiếp</h4>
            <a [routerLink]="['/khoa-hoc', 'tieng-anh-giao-tiep', '']">Tìm Hiểu <i class="fas fa-chevron-right fa-icon"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 mt30  wow fadeInUp" data-wow-delay=".3s">
        <div class="wide-block service-img2 hfull" data-tilt data-tilt-max="2" data-tilt-speed="600">
          <div class="block-space-">
            <span>Business Talk</span>
            <h4>Tiếng Anh Giao Tiếp Thương Mại</h4>
            <a [routerLink]="['/khoa-hoc', 'tieng-anh-thuong-mai', '']">Tìm Hiểu <i class="fas fa-chevron-right fa-icon"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 mt30  wow fadeInUp" data-wow-delay=".4s">
        <div class="wide-block service-img3 hfull" data-tilt data-tilt-max="2" data-tilt-speed="600">
          <div class="block-space-">
            <span>IETLS</span>
            <h4>Speaking, Writing, Reading, Listening</h4>
            <a [routerLink]="['/khoa-hoc', 'ielts', '']">Tìm Hiểu <i class="fas fa-chevron-right fa-icon"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 mt30  wow fadeInUp" data-wow-delay=".5s">
        <div class="wide-block service-img4 hfull" data-tilt data-tilt-max="2" data-tilt-speed="600">
          <div class="block-space-">
            <span>Customize</span>
            <h4>Khoá Học Theo Yêu Cầu</h4>
            <a [routerLink]="['/khoa-hoc', 'tieng-anh-theo-yeu-cau', '']">Tìm Hiểu <i class="fas fa-chevron-right fa-icon"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="-cta-btn mt70">
      <div class="free-cta-title v-center  wow zoomInDown" data-wow-delay=".6s">
        <p>Đăng ký ngay <span>Ưu Đãi Hấp Dẫn</span></p>
        <a [routerLink]="['/lien-he/']" (click)="setContactTitle('register')" class="btn-main bg-btn2 lnk">Liên hệ <i class="fas fa-chevron-right fa-icon"></i><span
            class="circle"></span></a>
      </div>
    </div>
  </div>
</section>
<!--End Service-->
<!--Start About-->
<section class="about-agency pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 v-center">
        <div class="image-block">
          <img src="assets/images/about/about-service.jpg" alt="about" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="common-heading text-l">
          <span>PHƯƠNG PHÁP HỌC</span>
          <h2>Học Tiếng Anh Mọi Lúc Mọi Nơi</h2>
          <p>
            100% Giáo Viên nước ngoài được tuyển chọn đều có đầy đủ chứng chỉ giảng dạy, nhiều năm kinh nghiệm giảng dạy
            online cũng như ở các trường chuyên dạy ngoại ngữ.
            Lớp học 1 kèm 1 của elseTalk nhằm để giáo viên hoàn toàn tập trung vào 1 học viên là bạn.
            Giờ Học linh hoạt từ 8 giờ sáng đến 23 giờ mỗi ngày.
            Hệ thống đặt lịch và quản lý thông minh...
          </p>
          <a [routerLink]="['/phuong-phap-hoc-tieng-anh/']" class="btn-main bg-btn lnk mt30">Tìm Hiểu Thêm <i class="fas fa-chevron-right fa-icon"></i><span
              class="circle"></span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start statistics-->
<div class="statistics-section bg-gradient pad-tb tilt3d">
  <div class="container">
    <div class="row justify-content-center t-ctr">
      <div class="col-lg-4 col-sm-6">
        <div class="statistics">
          <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
            <img src="assets/images/icons/startup.svg" alt="years" class="img-fluid" />
          </div>
          <div class="statnumb">
            <span class="counter">10</span><span>+</span>
            <p>Teacher</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="statistics">
          <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
            <img src="assets/images/icons/team.svg" alt="team" class="img-fluid" />
          </div>
          <div class="statnumb">
            <span class="counter">7</span><span>+</span>
            <p>Course</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row small t-ctr">
      <div class="col-lg-3 col-sm-6">
        <div class="statistics">
          <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
            <img src="assets/images/icons/deal.svg" alt="happy" class="img-fluid" />
          </div>
          <div class="statnumb">
            <span class="counter">1100</span><span>+</span>
            <p>Happy Student</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="statistics">
          <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
            <img src="assets/images/icons/computers.svg" alt="project" class="img-fluid" />
          </div>
          <div class="statnumb counter-number">
            <span class="counter">600</span><span>+</span>
            <p>Monthly Class</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="statistics">
          <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
            <img src="assets/images/icons/worker.svg" alt="work" class="img-fluid" />
          </div>
          <div class="statnumb">
            <span class="counter">20</span><span>+</span>
            <p>Dailly Class</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="statistics mb0">
          <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
            <img src="assets/images/icons/customer-service.svg" alt="support" class="img-fluid" />
          </div>
          <div class="statnumb">
            <span>24/7</span>
            <p>Support Available</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--End statistics-->


<!-- TODO -->

<!--Start Clients-->
<!-- <section class="clients-section pad-tb">
  <div class="container">
  <div class="row justify-content-center">
  <div class="col-lg-8">
  <div class="common-heading">
  <span>Our happy customers</span>
  <h2>Some of our Clients</h2>
  </div>
  </div>
  </div>
  <div class="row">
  <div class="col-lg-12">
  <div class="opl">
  <ul>
  <li class=" wow fadeIn" data-wow-delay=".2s">
  <div class="clients-logo"><img src="assets/images/client/clients-1.png" alt="text" class="img-fluid"/></div>
  </li>
  <li class=" wow fadeIn" data-wow-delay=".4s">
  <div class="clients-logo"><img src="assets/images/client/clients-2.png" alt="text" class="img-fluid"/></div>
  </li>
  <li class=" wow fadeIn" data-wow-delay=".6s">
  <div class="clients-logo"><img src="assets/images/client/clients-3.png" alt="text" class="img-fluid"/></div>
  </li>
  <li class=" wow fadeIn" data-wow-delay=".8s">
  <div class="clients-logo"><img src="assets/images/client/clients-4.png" alt="text" class="img-fluid"/></div>
  </li>
  <li class=" wow fadeIn" data-wow-delay="1s">
  <div class="clients-logo"><img src="assets/images/client/clients-5.png" alt="text" class="img-fluid"/></div>
  </li>
  </ul>
  </div>
  </div>
  </div>
  <div class="-cta-btn mt70">
  <div class="free-cta-title v-center wow zoomInDown" data-wow-delay="1.2s">
  <p>We <span>Promise.</span> We <span>Deliver.</span></p>
  <a href="#" class="btn-main bg-btn2 lnk">Let's Work Together<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
  </div>
  </div>
  </div>
  </section> -->
<!--End Clients-->
<!--Start work-category-->
<section class="work-category pad-tb tilt3d">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 v-center">
        <div class="common-heading text-l">
          <span>Tại sao elseTalk</span>
          <h2>Học Tiếng Anh với elseTalk</h2>
          <p>Đừng để tiếng Anh bỏ bạn lại phía sau</p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="work-card-set">
          <div class="icon-set wow fadeIn" data-wow-delay=".2s">
            <div class="work-card cd1">
              <div class="icon-bg"><i class="fas fa-map-signs"></i></div>
              <p>100% Tiếng Anh</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay=".2s">
            <div class="work-card cd2">
              <div class="icon-bg"><i class="fas fa-globe-americas"></i></div>
              <p>100% GV nước ngoài</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay=".2s">
            <div class="work-card cd3">
              <div class="icon-bg"><i class="fas fa-cloud"></i></div>
              <p>100% Lớp Online</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay=".2s">
            <div class="work-card cd4">
              <div class="icon-bg"><i class="fas fa-exchange-alt"></i></div>
              <p>100% Tương Tác</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay=".4s">
            <div class="work-card cd5">
              <div class="icon-bg"><i class="fas fa-user-friends"></i></div>
              <p>Lớp 1 kèm 1</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay=".4s">
            <div class="work-card cd6">
              <div class="icon-bg"><i class="fas fa-calendar-alt"></i></div>
              <p>Hệ Thống Đặt Lịch</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay=".4s">
            <div class="work-card cd7">
              <div class="icon-bg"><i class="fas fa-clock"></i></div>
              <p>25/50 phút mỗi lớp</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay=".4s">
            <div class="work-card cd8">
              <div class="icon-bg"><i class="fas fa-list-alt"></i></div>
              <p>Giờ học Linh Hoạt</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay="0.6s">
            <div class="work-card cd9">
              <div class="icon-bg"><i class="fas fa-hand-holding-usd"></i></div>
              <p>Giá Hợp Lý</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay="0.6s">
            <div class="work-card cd10">
              <div class="icon-bg"><i class="fas fa-bell-slash"></i></div>
              <p>Học Không Giới Hạn</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay="0.6s">
            <div class="work-card cd11">
              <div class="icon-bg"><i class="fab fa-creative-commons-nc"></i></div>
              <p>Học Thử Miễn Phí</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay="0.6s">
            <div class="work-card cd12">
              <div class="icon-bg"><i class="fas fa-comment-dots"></i></div>
              <p>Hỗ trợ 24/7</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay="0.8s">
            <div class="work-card cd1">
              <div class="icon-bg"><i class="fas fa-piggy-bank"></i></div>
              <p>Tiết Kiệm Chi phí</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay="0.8s">
            <div class="work-card cd2">
              <div class="icon-bg"><i class="fas fa-hourglass-start"></i></div>
              <p>Tiết Kiệm Thời Gian</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay="0.8s">
            <div class="work-card cd3">
              <div class="icon-bg"><i class="fas fa-map-marked-alt"></i></div>
              <p>Giáo Trình Chuẩn</p>
            </div>
          </div>
          <div class="icon-set wow fadeIn" data-wow-delay="0.8s">
            <div class="work-card cd4">
              <div class="icon-bg"><i class="fab fa-medapps"></i></div>
              <p>GV Nhiều Kinh Nghiệm</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End  work-category-->

<!--Start Testinomial-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 v-center">
        <div class="common-heading text-l">
          <span>Cảm nhận</span>
          <h2 class="mb0">Học Viên Nghĩ Về elseTalk</h2>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="owl-carousel testimonial-card-a pl25">
          <div class="testimonial-card">
            <div class="t-text">
              <p>Ban đầu mình rất lo lắng khi chỉ học lớp có 1 mình với giáo viên, nhưng sau đó mình nhận ra đó phương pháp tuyệt vời vì từ đầu mình bặp bẹ không diễn đạt được ý nhưng nhờ cô mà mình tự tin giao tiếp hơn chỉ sau vài tuần, cảm ơn cô Tess</p>
            </div>
            <div class="client-thumbs mt30">
              <div class="media v-center">
                <div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl.jpg" alt="girl"
                    class="img-fluid" /></div>
                <div class="media-body user-info v-center">
                  <h5>Thanh Đinh</h5>
                  <p>Quản Lý, <small>Chất Lượng Thực Phẩm</small></p>
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-card">
            <div class="t-text">
              <p>Cô giáo tận tình, mỗi ngày không cần tốn nhiều thời gian rất phù hợp cho người bận đi làm bận rộn.
                Tôi đã học thử thấy rất hiệu quả đặc biệt là học hàng ngày và lâu dài (khoảng 6 tháng).
                Tôi có thể thể hiện điều tôi muốn bằng tiếng Anh tốt hơn trước đây rất nhiều</p>
            </div>
            <div class="client-thumbs mt30">
              <div class="media v-center">
                <div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl2.jpeg" alt="girl"
                    class="img-fluid" /></div>
                <div class="media-body user-info">
                  <h5>Danh Trần</h5>
                  <p>Trưởng Phòng, <small>Công Nghệ TT</small></p>
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-card">
            <div class="t-text">
              <p>Tôi đi làm nhiều năm vẫn không nói được tiếng anh, chỉ có thể chat với khách hàng thực sự đó là rào cản lớn trong công việc.
                 Tôi cũng tìm đến học ở vài trung tâm nhưng không hiệu quả vì lớp đông giờ học thì cố định trong khi công việc tôi khá thất thường.
                 Sau vài tháng học ở elseTalk trình độ tăng lên hẳn, tự tin hơn, hiện tại tôi đang chuyển theo học IELTS cũng cảm thấy rất hay.</p>
            </div>
            <div class="client-thumbs mt30">
              <div class="media v-center">
                <div class="user-image bdr-radius"><img src="assets/images/user-thumb/client-3.png" alt="client-3"
                    class="img-fluid" /></div>
                <div class="media-body user-info">
                  <h5>Phúc Trần</h5>
                  <p>Chuyên Viên, <small>Tư vấn Công Nghệ</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Testinomial-->
<!--Start Enquire Form-->
<section class="enquire-form pad-tb">
  <div class="container">
    <div class="row light-bgs">
      <div class="col-lg-6">
        <div class="common-heading text-l">
          <span>Liên Hệ</span>
          <h2 class="mt0">Bạn Cần Hỗ Trợ? Nhắn Tin Ngay</h2>
        </div>
        <div class="form-block">
          <form #contactForm="ngForm" (ngSubmit)="onSubmitContact(contactForm)" name="feedback-form">
            <div class="row">
              <div class="form-group col-sm-6">
                <input type="text" name="fullName" id="fullName" [ngModel]="contact.fullName" #name="ngModel"
                  placeholder="Tên của bạn" required>
                <div *ngIf="isContactSubmitted || (fullName.invalid && (fullName.dirty || fullName.touched))">
                  <div *ngIf="fullName.errors?.required" class="help-block with-errors">Vui lòng nhập tên của bạn</div>
                </div>
              </div>
              <div class="form-group col-sm-6">
                <input type="email" id="email" name="email" [ngModel]="contact.email" #email="ngModel"
                  placeholder="Email của bạn" required email>
                <div *ngIf="isContactSubmitted || (email.invalid && (email.dirty || email.touched))">
                  <div *ngIf="email.errors?.required" class="help-block with-errors">Vui lòng nhập Email của bạn</div>
                  <div *ngIf="email.errors?.email" class="help-block with-errors">Vui lòng kiểm tra lại Email của bạn
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6">
                <input type="text" id="mobile" name="phone" id="phone" [ngModel]="contact.phone" #phone="ngModel"
                  placeholder="Số điện thoại của bạn" required data-error="Please fill Out">
                <div *ngIf="isContactSubmitted || (phone.invalid && (phone.dirty || phone.touched))">
                  <div *ngIf="phone.errors?.required" class="help-block with-errors">Vui lòng nhập số điện thoại của bạn
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-6">
                <input id="Dtype" name="title" id="title" [ngModel]="contact.title" #title="ngModel"
                  placeholder="Tiêu đề" required>
                <div *ngIf="isContactSubmitted || (title.invalid && (title.dirty || title.touched))">
                  <div *ngIf="title.errors?.required" class="help-block with-errors">Vui lòng nhập Tiêu đề</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <textarea id="content" rows="5" name="content" id="content" [ngModel]="contact.content" #content="ngModel"
                placeholder="Nội dung" required></textarea>
              <div *ngIf="isContactSubmitted || (content.invalid && (content.dirty || content.touched))">
                <div *ngIf="content.errors?.required" class="help-block with-errors">Vui lòng nhập Nội Dung</div>
              </div>
            </div>
            <div class="fieldsets mt20">
              <div *ngIf="isContactSubmittedOK" class="help-block with-success">Cảm ơn bạn liên hệ với elseTalk, elseTalk sẽ liên hệ lại với bạn nhất có thể!</div>
              <button type="submit" name="submit" class="lnk btn-main bg-btn">
                Gửi Ngay <i class="fas fa-chevron-right fa-icon"></i>
                <span class="circle"></span>
              </button>
            </div>
            <p class="trm"><i class="fas fa-lock"></i>Chúng tôi ghét spam, và chúng tôi tôn trọng sự riêng tư của bạn.
            </p>
          </form>
        </div>
      </div>
      <div class="col-lg-6 v-center">
        <div class="enquire-image">
          <img src="assets/images/about/helopic.png" alt="enquire" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Enquire Form-->
