<div class="row">

  <div class="col-lg-5">
    <nb-card>
      <nb-card-header>
        <div class="row">
          <div class="col-lg-5">
            <img class="teacher-avatar" src="{{teacher.avatarUrl}}" alt="Sensei" style="display: initial;">
          </div>
          <div class="col-lg-7">
            <h4>{{teacher.fullName}}</h4>
            <div class="teacher-title">{{teacher.title}}</div>
            <!-- start rating -->
            <ng-template #t let-fill="fill">
              <span class="star" [class.full]="fill === 100">
                <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
              </span>
            </ng-template>

            <ngb-rating [(rate)]="teacher.rate" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
            <span class="rate">{{teacher.rate}}</span>

            <div>Success Class: {{teacher.successClass}}</div>
            <div>Opened Class: {{teacher.openedClass}}</div>
            <br>
            <a *ngIf="isTeacherOwner || isAdmin" [routerLink]="['/pages/teacher', teacherId, 'profile', 'edit']">Edit Profile</a>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <iframe [src]='safeURL' width="98%" height="315" frameborder="0" allowfullscreen></iframe>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-lg-7">
    <nb-card>
      <!-- <nb-card-header>Default Inputs</nb-card-header> -->
      <nb-card-body>
        <input type="text" nbInput fullWidth [(ngModel)]="teacher.fullName" [ngModelOptions]="{standalone: true}" class="info" placeholder="First Name" disabled>
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="teacher.gender" [ngModelOptions]="{standalone: true}" placeholder="email" disabled>
        <textarea rows="14" nbInput fullWidth shape="rectangle" [(ngModel)]="teacher.introduce" placeholder="About your self" disabled></textarea>
        <!-- <button nbButton fullWidth status="success">Save</button> -->
      </nb-card-body>
    </nb-card>
  </div>

</div>
