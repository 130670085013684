<div class="row">
  <div class="col-lg-12">
        <h3>Thank for your register</h3>
        <br>
        <p>elseTalk will review and contract to you soon to approve your register</p>
        <a href="" style="float:right">Go to Home</a>
  </div>
</div>


<!-- <nb-card>
  <nb-card-header>
    Thank for your register
  </nb-card-header>

  <nb-card-body>
    <p>elseTalk will review and contract to you soon to approve your register</p>
    <a href="">Go to Home</a>
  </nb-card-body>
</nb-card> -->
