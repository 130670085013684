import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NbDialogService } from '@nebular/theme';

import { StudentService, ApiService } from '../../../services';
import { DateTime } from 'luxon';
import { config } from '../../../config';
import { DialogUploadComponent } from '../dialog-upload/dialog-upload.component';



@Component({
  selector: 'ngx-student-profile',
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile.component.html',
})
export class StudentProfileComponent implements OnDestroy {

  private alive = true;

  user: any = {};
  userId: string;
  currentPassword: string;
  newPassword: string;
  expriationDateString: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  avatarUrl: string;
  isPasswordField: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private studentService: StudentService,
    private dialogService: NbDialogService,
  ) {

    this._initData();
  }

  ngOnDestroy() {
    this.alive = false;
  }


  private async _initData() {
    this.activatedRoute.params.subscribe(async params => {
      this.userId = params['id'];
      try {
        const user = await this.studentService.getStudent(this.userId);
        this.avatarUrl = user.avatarUrl || config.DEFAULT_IMAGE;
        this.expriationDateString = DateTime.fromMillis(user.expirationAt).toFormat(config.DATE_TIME_SHORT_FORMAT);
        this.user = user;
      } catch (ex) {

      }

    });
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onUpdate() {
    const user = {
      id: this.userId,
      fullName: this.user.fullName,
      email: this.user.email,
      dob: this.user.dob,
      gender: this.user.gender,
      skypeAccount: this.user.skypeAccount,
      zaloAccount: this.user.zaloAccount,
      introduce: this.user.introduce,
    };
    this.studentService.updateStudent(user).then(() => {
      this.apiService.showToast('Success', 'Update profile', 'success');
    });
    // change pwd
    if (this.currentPassword && this.newPassword) {
      const userPassword = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };
      this.studentService.updateStudentPassword(this.userId, userPassword).then(() => {
        this.apiService.showToast('Success', 'Update password', 'success');
      });
    }
  }

  openUploadDialog() {
    this.dialogService.open(DialogUploadComponent).onClose.subscribe(
      async (data) => {
        if (!data || !data.fileUrl) {
          return;
        }
        // add timeout because S3 generate URL before upload, so if we get early image uploaded will get 404
        setTimeout(() => {
          this.avatarUrl = data.fileUrl;
        }, 3000);
        const tmpUser = {
          id: this.userId,
          avatarUrl: data.fileUrl,
          shortAvatarUrl: data.shortFileUrl,
        };
        this.studentService.updateStudent(tmpUser);
      });
  }

  togglePassword() {
    if (this.isPasswordField) {
      this.isPasswordField = false;
    } else {
      this.isPasswordField = true;
    }
  }

}
