<nb-card>
  <nb-card-header>
    Class Table
  </nb-card-header>

  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="classes"
    (deleteConfirm)="onDeleteConfirm($event)"
    (userRowSelect)="onUserRowSelect($event)"
    >
    </ng2-smart-table>
  </nb-card-body>
</nb-card>
