<!--Breadcrumb Area-->
<!-- <section class="breadcrumb-area banner-11" data-background="assets/images/assets/images/banner/11.jpg"> -->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-giao-tiep', '']">Khoá Học</a></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>Tiếng Anh Giao Tiếp</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start About-->
<section class="service pad-tb bg-gradient5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="image-block wow fadeIn">
          <img src="assets/images/service/service-img-1.png" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-8 block-1">
        <div class="common-heading text-l pl25">
          <span>Gới Thiệu</span>
          <h2>Tiếng Anh Giao Tiếp</h2>
          <p>Bạn học tiếng Anh đã nhiều năm, bạn vẫn chưa thể giao tiếp được, bạn luôn cảm thấy bối rối và xấu hổ khi
            nói tiếng Anh. Bạn đã thử rất nhiều cách khác nhau, nhưng tiếng Anh luôn làm bạn đau đầu, luôn cản trở bạn
            trong cuộc sống và thăng tiến trong công việc. Đừng bỏ cuộc elseTalk sẽ giúp bạn tìm ra phương pháp học
            tiếng Anh giao tiếp đúng đắn. Với lớp học online 1 kèm 1 giáo viên chất lượng, giờ học linh hoạt elseTalk tự
            tin sẽ giúp bạn làm chủ tiếng Anh chỉ sau 3 tháng</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start Key points-->
<section class="service light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="servie-key-points">
          <h3>Khoá học tiếng Anh giao tiếp dành cho ai?</h3>
          <p class="mt10">Nhân viên cán bộ văn phòng, người đang làm ở môi trường làm việc quốc tế sử dụng tiếng Anh
            nhiều trong công việc, cần học tiếng anh để thăng tiến trong công việc.</p>
          <p class="mt10">Người đi làm cần cải thiện tiếng Anh giao tiếp để xin việc mới, nâng cao thu nhập và cơ hội
            trong tương lai.</p>
          <p class="mt10">Sinh Viên quan tâm đến tiếng Anh để xin việc hay săn học bổng, cần học để có thể giao tiếp
            tốt, phỏng vấn xin việc dễ dàng.</p>
          <p class="mt10">elseTalk dành cho những bạn học tiếng Anh nhiều năm từ tiểu học đến đi làm vẫn chưa thể nói
            được, hoặc đã thử nhiều cách học, học ở nhiều trung tâm cố định khác mà vẫn thất bại, không đủ kiên trì để
            tự học, cần giáo viên chỉnh phát âm, luyện phản xạ giao tiếp tiếng Anh.</p>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="servie-key-points mt20">
          <h3>Ưu điểm khoá học Tiếng Anh Giao Tiếp</h3>
          <ul class="key-points mt15">
            <li>100% Giáo Viên nước ngoài (Philippines) được tuyển chọn đều có nhiều năm kinh nghiệm giảng dạy online
              cũng như ở các trường chuyên dạy ngoại ngữ, sẽ giúp bạn 100% dùng tiếng Anh trong lớp không bị lối mòn dạy
              tiếng Anh bằng tiếng Việt, từ đó hình thành tuy duy, suy nghĩ, diễn đạt bằng tiếng Anh một cách tự nhiên.
            </li>
            <li>Lớp học 1 kèm 1 của elseTalk nhằm để giáo viên hoàn toàn tập trung vào 1 học viên là bạn, nên sẽ rất
              hiệu quả và tiết kiệm thời gian hơn lớp học truyền thống nhiều lần, sẽ giúp học viên sửa sai ngay lập tức
              và tìm ra điểm yếu của bạn để cải thiện triệt để.</li>
            <li>Theo dõi theo từng buổi học, sau mỗi buổi học giáo viên sẽ viết lại nhận xét, những tiến bộ của bạn cũng
              như là những điểm cần chú ý để cải thiện, giúp bạn tiến bộ theo từng ngày.</li>
            <li>Giờ Học linh hoạt từ sáng đến 23h mỗi ngày, kể cả thứ bảy, chủ nhật, thích hợp cho người bận rộn. Vì là
              lớp 1-1 nên bạn có thể đặt lịch học bất cứ lúc nào, bất cứ ngày nào và rất tiện lợi hơn lớp học cố định
              thời gian.</li>
            <li>Học tiếng Anh giao tiếp ở bất cứ nơi đâu, học tại nhà, tại quán cafe, tại công ty của bạn… Chỉ cần điện
              thoại hoặc laptop kết nối internet, giúp bạn tiết kiệm thời gian đi tới trung tâm cố định, thời gian học
              trong lớp nhiều học viên vì thật sự bạn chỉ có vài phút để nói chưa kể tới việc trong lớp sẽ có nhiều bạn
              trình độ khác nhau, và khi bạn thực hành với bạn học có trình độ tương đương hoặc thấp hơn bạn sẽ không
              giúp bạn tiến bộ hơn.</li>
            <li>elseTalk có học phí hợp lý, nhiều gói khác nhau để bạn dễ dàng lựa chọn cho phù hợp và tiết kiệm hơn 5
              lần so với học tại trung tâm cố định.</li>
            <li>Elsetalk có lớp học thử miễn phí 100%, sau buổi học giáo viên sẽ viết nhận xét đánh giá trình độ tiếng
              Anh của bạn và để xuất giáo trình phù hợp.</li>
            <li>Chương trình học và giáo trình chuẩn quốc tế theo các trung tâm chuyên dạy du học sinh tiếng Anh tại
              Philippines.</li>
            <li>elseTalk có Hệ thống đặt lịch và quản lý thông minh:</li>
            <div>
              <ul class="list-style-" style="margin-left: 30px;">
                <li>Giúp bạn chủ động hơn khi sắp xếp lịch học của mình, huỷ lớp một cách dễ dàng.</li>
                <li>Quản lý lớp học và thời gian học một cách dễ dàng.</li>
                <li>Giáo Viên sẽ đánh giá theo từng buổi học để bạn dễ dàng theo dõi và thấy mình tiến bộ theo từng
                  ngày.
                </li>
              </ul>
            </div>
          </ul>
        </div>
        <div class="servie-key-points mt20">
          <h3>Phương Pháp Học Tiếng Anh Giao Tiếp</h3>
          <ul class="key-points mt15">
            <li>Phương pháp học mới nhiều ưu điểm bạn có thể tham khảo <a
                [routerLink]="['/phuong-phap-hoc-tieng-anh', '']">tại đây </a></li>
          </ul>
        </div>
        <div class="servie-key-points mt20">
          <h3>Lộ trình Học Tiếng Anh Giao Tiếp</h3>
          <ul class="key-points mt15">
            <li>Cá nhân hoá theo từng học viên</li>
            <li>Kiểm tra đầu vào trực tiếp với giáo viên</li>
            <li>Chỉ Định giáo trình phù hợp với trình độ và mục tiêu của từng học viên</li>
            <li>Giúp bạn từ mất gốc tới nói tiếng Anh trôi chảy</li>
          </ul>
        </div>
        <div class="servie-key-points mt20">
          <h3>Kết Quả</h3>
          <ul class="key-points mt15">
            <li>Học viên có lượng từ vựng khổng lồ về nhiều chủ đề khác nhau trong cuộc sống như du lịch, ẩm thực, khoa
              học, chính trị...</li>
            <li>kỹ năng Nghe, Nói trôi trải hơn, không còn bị dịch từ tiếng Việt sang tiếng Anh rồi tìm từ để thay vào
              nữa.</li>
            <li>Có khả năng diễn thuyết cũng như diễn đạt thoải mái về các chủ đề trong cuộc sống bằng tiếng Anh</li>
            <li>Viết email, chat tiếng Anh với khách hàng sẽ không còn là vấn đề đâu đầu mất thời gian nữa.</li>
          </ul>
        </div>
        <div class="servie-key-points mt20 mb40">
          <h3>Học phí Tiếng Anh Giao Tiếp</h3>
          <ul class="key-points mt15">
            <li>Bạn có thể tham khảo các gói <a [routerLink]="['/hoc-phi', 'tieng-anh-giao-tiep', '']">học phí</a></li>
            <li>Đăng ký <a [routerLink]="['/lien-he', '']" [queryParams]="{title: 'trial-register'}">học thử</a> miễn
              phí</li>
            <li><a [routerLink]="['/lien-he', '']" [queryParams]="{title: 'register'}">Đăng ký</a>
               học ngay hoặc nhờ
              <a [routerLink]="['/lien-he', '']" [queryParams]="{title: 'business-course-help'}">tư vấn</a>
              để nhận nhiều
              ưu đãi bất ngờ! hoặc có thể chat với nhân viên tư vấn qua FB Messenger ở bên dưới
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Key points-->
