import { Injectable, Input } from '@angular/core';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {config} from '../config';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private REST_API_SERVER = config.END_POINT;

  @Input()
  httpRequestHeaders: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
    // private toastrService: NbToastrService,
  ) { }


  handleError(ErrorResponse: HttpErrorResponse) {
    let errorMessage = ErrorResponse?.error?.error?.message;
    if (!errorMessage) {
      errorMessage = ErrorResponse?.error?.message;
    }
    this.showToast('Issue: ', errorMessage);
    return throwError(ErrorResponse);
  }

  public get(endpoint) {
    return this.httpClient.get(this.REST_API_SERVER + endpoint, this.header()).pipe(catchError(this.handleError.bind(this))).toPromise();
  }

  public post(endpoint, data) {
    return this.httpClient.post<any>(this.REST_API_SERVER + endpoint, data, this.header()).pipe(catchError(this.handleError.bind(this))).toPromise();
  }

  public patch(endpoint, data) {
    return this.httpClient.patch<any>(this.REST_API_SERVER + endpoint, JSON.stringify(data), this.header()).pipe(catchError(this.handleError.bind(this))).toPromise();
  }

  public delete(endpoint) {
    return this.httpClient.delete(this.REST_API_SERVER + endpoint, this.header()).pipe(catchError(this.handleError.bind(this))).toPromise();
  }


  public postFile(endpoint, data) {
    const formData = new FormData();
    formData.append('files', data, 'png');
    return this.httpClient.post<any>(this.REST_API_SERVER + endpoint, formData, this.headerMultipart()).pipe(catchError(this.handleError.bind(this))).toPromise();
  }

  private header() {
    const token = JSON.parse(localStorage.getItem('auth_app_token')) || {};

    return {
      headers: new HttpHeaders({
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token.value,
      }),
    };
  }

  private headerMultipart() {
    const token = JSON.parse(localStorage.getItem('auth_app_token')) || {};

    return {
      headers: new HttpHeaders({
        'accept': '*/*',
        // 'Content-Type': 'multipart/form-data', // by pass ERROR: content-type missing boundary
        'Authorization': 'Bearer ' + token.value,
      }),
    };
  }

  public showToast(title: string, body: string, type?: any) {
    // const status: NbComponentStatus = type || 'danger';
    // const toastConfig = {
    //   status,
    //   destroyByClick: true,
    //   duration: 4000,
    //   position: NbGlobalPhysicalPosition.TOP_RIGHT,
    //   // preventDuplicates: true,
    // };

    // this.toastrService.show(
    //   body,
    //   `${title}`,
    //   toastConfig);
  }
}
