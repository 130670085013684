import { Component, OnInit, Renderer2 } from '@angular/core';
import { CanActivate, ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

// import { MENU_ITEMS } from './pages-menu';
import { LoadScriptService, ApiService, SubscribeService, BlogService } from '../services';
// import * as $ from 'jquery';

@Component({
  selector: 'app-blog-page',
  styleUrls: ['blog.component.scss'],
  templateUrl: './blog.component.html',
})
export class BlogComponent implements OnInit {

  menu;
  contactForm: any = {};
  trialForm: any = {};
  subscribe: any = {};
  isShowSlideMenu = false;
  blogs: any = [];

  constructor(
    private router: Router,
    private loadScriptService: LoadScriptService,
    private renderer: Renderer2,
    private blogService: BlogService,
    private subscribeService: SubscribeService,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle("Blog | elseTalk");
    this.meta.updateTag({name: "description", content: "bí kiếp học tiếng Anh, study English"});
   }

  ngOnInit() {
    this._initData();
    // // window.location.reload();
    // setTimeout(async () => {
    //   await this.loadScriptService.loadAll();
    //   // this._spinner.load();
    // }, 2000);

    // // this.blogService.getBlogs().then(data .then(data => this.blogs = data);
  }

  async _initData() {
    this.blogs = await this.blogService.getBlogs();
    console.log(this.blogs);
  }


  onBlogItemClick(slug) {
    this.router.navigateByUrl(`/blog/${slug}`);
  }

}
