<nb-card>
  <nb-tabset>
    <nb-tab tabTitle="Student Info">
      <nb-card class="card">
        <nb-card-header>Booked Class <strong>{{header}}</strong></nb-card-header>
        <nb-card-body>
          <div class="form-group">
            <label for="studentName" class="label">Student Name</label>
            <input nbInput fullWidth [(ngModel)]="slot.user.fullName" id="studentName" placeholder="-" disabled>
          </div>
          <div class="form-group">
            <label for="studentSkypeAccount" class="label">Skype Account</label>
          <input nbInput fullWidth [(ngModel)]="slot.user.skypeAccount" id="studentSkypeAccount" placeholder="-" disabled>

          </div>
          <div class="form-group">
            <label for="title" class="label">Class Name</label>
          <input nbInput fullWidth [(ngModel)]="slot.title" id="title" placeholder="-" disabled>
          </div>
          <div class="form-group">
            <label for="note" class="label">Note</label>
            <input nbInput fullWidth [(ngModel)]="slot.note" id="note" placeholder="-" disabled>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <button nbButton status="danger" (click)="cancel()" class="close">Close</button>
        </nb-card-footer>
      </nb-card>
    </nb-tab>

    <nb-tab tabTitle="Latest Remark Info">
      <nb-card class="card">
        <nb-card-header>
          Remark Class  <strong>{{header}}</strong><br>
          By Teacher: <strong>{{remark.teacherName || '-'}}</strong>
        </nb-card-header>
        <nb-card-body>
          <h6></h6>
          <input type="text" nbInput fullWidth  [(ngModel)]="remark.materialName" placeholder="Material Name" disabled/>

          <input type="text" nbInput fullWidth  [(ngModel)]="remark.whereStart" placeholder="Where Start, lesson, page, phase, sentence..." disabled/>

          <input type="text" nbInput fullWidth  [(ngModel)]="remark.whereEnd" placeholder="Where End, lesson, page, phase, sentence..." disabled/>

          <textarea rows="3" nbInput fullWidth [(ngModel)]="remark.performance" placeholder="Performance" disabled></textarea>
          <br>
        </nb-card-body>
        <nb-card-footer>
          <button nbButton status="danger" (click)="cancel()" class="close">Close</button>
        </nb-card-footer>
      </nb-card>
    </nb-tab>

  </nb-tabset>
</nb-card>
