import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { config } from '../../../config';
import { FeedbackService } from '../../../services';

@Component({
  selector: 'ngx-student-feedback',
  styleUrls: ['./feedback-list.component.scss'],
  templateUrl: './feedback-list.component.html',
})
export class StudentFeedbackComponent implements OnDestroy {
  private alive = true;
  data: any = [];

  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: false,
    },
    columns: {
      userName: {
        title: 'Student',
        type: 'string',
      },
      content: {
        title: 'Content',
        type: 'string',
      },
      slotTitle: {
        title: 'Class Title',
        type: 'string',
      },
      period: {
        title: 'Class Time (dd-mm-yy)',
        type: 'string',
      },
      // lastUpdate: {
      //   title: 'Last Update',
      //   type: 'string',
      // },
    },
  };

  constructor(
    private feedbackService: FeedbackService,
    private activatedRoute: ActivatedRoute,

  ) {
    this._initData();
  }

  // onUserRowSelect(event): void {
  //   this.router.navigateByUrl(`/pages/teacher/${event.data.id}/profile`);
  // }

  ngOnDestroy() {
    this.alive = false;
  }

  private async _initData() {

    this.activatedRoute.params.subscribe(async params => {
      const teacherId = params['id'];
      try {
        const result = await this.feedbackService.getStudentFeedbacksByTeacherId(teacherId);
        const resutl2 = [];
        for (const data of result) {
          if (!data.content) {
            continue;
          }
          const start = new Date(data.slotStart);
          data.period = DateTime.fromMillis(data.slotStart).toFormat('HH:mm') + '-' + DateTime.fromMillis(data.slotEnd).toFormat('HH:mm') + ' - ' + DateTime.fromMillis(data.slotStart).toFormat('dd-MM-yyyy');
          data.lastUpdate = DateTime.fromMillis(data.lastUpdate).toFormat(config.DATE_TIME_SHORT_FORMAT);
          // skip empty content like just emotion or report teacher absent
          resutl2.push(data);
        }
        resutl2.sort(this.compare);

        this.data = resutl2;
      } catch (ex) {
        console.error('getUserFeedbacksByTeacherId: ', ex);
      }
    });

  }

  private compare(a, b) {
    if (a.slotStart > b.slotStart) {
      return -1;
    }
    if (a.slotStart < b.slotStart) {
      return 1;
    }
    return 0;
  }

}
