<!--Breadcrumb Area-->
<!-- <section class="breadcrumb-area banner-11" data-background="assets//assets/images/banner/11.jpg"> -->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/khoa-hoc', 'tieng-theo-yeu-cau', '']">Khoá Học</a></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>Tiếng Anh Theo Yêu cầu</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start About-->
<section class="service pad-tb bg-gradient5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="image-block wow fadeIn">
          <img src="assets/images/service/service-img-4.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-8 block-1">
        <div class="common-heading text-l pl25">
          <span>Gới Thiệu</span>
          <h2>Tiếng Anh Theo Yêu cầu</h2>
          <p>Bạn muốn học tiếng Anh theo mục tiêu và sở thích của mình, bạn cần cải thiện một vài kỷ năng</p>
          <p>Bạn cần tìm giáo viên chỉnh sửa phát âm, mở rộng ý diễn đạt</p>
          <p>Bạn cần tìm giáo viên luyện nói tiếng anh để không bị mất đi phản xạ do lâu ngày không dùng</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start Key points-->
<section class="service light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="servie-key-points">
          <h3>Khoá học Theo Yêu Cầu dành cho ai?</h3>
          <p class="mt10">Tất cá mọi người có yêu cầu đặc biệt chỉ tập chung vào vấn đề còn thiếu xót để cải hiện</p>
          <p class="mt10">Tìm giáo viên luyện phản xạ nói</p>
          <p class="mt10">Muốn khoá học tiếng Anh theo sở thích mục tiêu riêng</p>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="servie-key-points mt20">
          <h3>Ưu điểm khoá học Theo Yêu Cầu</h3>
          <ul class="key-points mt15">
            <li>100% Giáo Viên nước ngoài (Philippines) được tuyển chọn đều có nhiều năm kinh nghiệm giảng dạy online
              cũng như ở các trường chuyên dạy ngoại ngữ, sẽ giúp bạn 100% dùng tiếng Anh trong lớp không bị lối mòn dạy
              tiếng Anh bằng tiếng Việt, từ đó hình thành tuy duy, suy nghĩ, diễn đạt bằng tiếng Anh một cách tự nhiên.
            </li>
            <li>Lớp học 1 kèm 1 của elseTalk nhằm để giáo viên hoàn toàn tập trung vào 1 học viên là bạn, nên sẽ rất
              hiệu quả và tiết kiệm thời gian hơn lớp học truyền thống nhiều lần, sẽ giúp học viên sửa sai ngay lập tức
              và tìm ra điểm yếu của bạn để cải thiện triệt để.</li>
            <li>Theo dõi theo từng buổi học, sau mỗi buổi học giáo viên sẽ viết lại nhận xét, những tiến bộ của bạn cũng
              như là những điểm cần chú ý để cải thiện, giúp bạn tiến bộ theo từng ngày.</li>
            <li>Giờ Học linh hoạt từ sáng đến 23h mỗi ngày, kể cả thứ bảy, chủ nhật, thích hợp cho người bận rộn. Vì là
              lớp 1-1 nên bạn có thể đặt lịch học bất cứ lúc nào, bất cứ ngày nào và rất tiện lợi hơn lớp học cố định
              thời gian.</li>
            <li>Học tiếng Anh giao tiếp ở bất cứ nơi đâu, học tại nhà, tại quán cafe, tại công ty của bạn… Chỉ cần điện
              thoại hoặc laptop kết nối internet, giúp bạn tiết kiệm thời gian đi tới trung tâm cố định, thời gian học
              trong lớp nhiều học viên vì thật sự bạn chỉ có vài phút để nói chưa kể tới việc trong lớp sẽ có nhiều bạn
              trình độ khác nhau, và khi bạn thực hành với bạn học có trình độ tương đương hoặc thấp hơn bạn sẽ không
              giúp bạn tiến bộ hơn.</li>
            <li>elseTalk có học phí hợp lý</li>
            <li>Elsetalk có lớp học thử miễn phí 100%, sau buổi học giáo viên sẽ viết nhận xét đánh giá trình độ tiếng
              Anh của bạn và để xuất giáo trình phù hợp.</li>
            <li>Chương trình học sẽ xây dựng theo yêu cầu của bạn</li>
            <li>elseTalk có Hệ thống đặt lịch và quản lý thông minh:</li>
            <div>
              <ul class="list-style-" style="margin-left: 30px;">
                <li>Giúp bạn chủ động hơn khi sắp xếp lịch học của mình, huỷ lớp một cách dễ dàng.</li>
                <li>Quản lý lớp học và thời gian học một cách dễ dàng.</li>
                <li>Giáo Viên sẽ đánh giá theo từng buổi học để bạn dễ dàng theo dõi và thấy mình tiến bộ theo từng
                  ngày.
                </li>
              </ul>
            </div>
          </ul>
        </div>
        <div class="servie-key-points mt20">
          <h3>Phương Pháp Học</h3>
          <ul class="key-points mt15">
            <li>Phương pháp học mới nhiều ưu điểm bạn có thể tham khảo <a
                [routerLink]="['/phuong-phap-hoc-tieng-anh', '']">tại đây </a></li>
          </ul>
        </div>
        <div class="servie-key-points mt20">
          <h3>Lộ trình Học</h3>
          <ul class="key-points mt15">
            <li>Cá nhân hoá theo từng học viên</li>
            <li>Kiểm tra đầu vào trực tiếp với giáo viên</li>
            <li>Tư vấn giáo trình học theo yêu cầu</li>
          </ul>
        </div>
        <div class="servie-key-points mt20">
          <h3>Kết Quả</h3>
          <ul class="key-points mt15">
            <li>Đạt đến mục tiêu theo yêu cầu của bạn</li>
          </ul>
        </div>
        <div class="servie-key-points mt20 mb40">
          <h3>Học phí</h3>
          <ul class="key-points mt15">
            <li>Giá hợp lý</li>
            <li>Bạn có thể tham khảo gói <a [routerLink]="['/hoc-phi', 'tieng-anh-theo-yeu-cau', '']">học phí</a></li>
            <li>Liên hệ <a [routerLink]="['/lien-he', '']" [queryParams]="{title: 'customize-course-help'}">tư vấn</a>
              để nhận nhiều ưu đãi bất ngờ! hoặc có thể chat với nhân viên tư vấn qua FB Messenger ở bên dưới</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Key points-->
