import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { ApiService } from '../../../services';



@Component({
  selector: 'ngx-dialog-upload-2',
  templateUrl: 'dialog-upload.component.html',
  styleUrls: ['dialog-upload.component.scss'],
})
export class DialogUploadComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isShowUploader: boolean = true;
  @ViewChild('uploader') uploader: ElementRef<HTMLElement>;


  constructor(
    protected ref: NbDialogRef<DialogUploadComponent>,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    // need init value because change event not fired if not check/uncheck lead to submit out of date value
  }

  cancel() {
    this.ref.close({ isCancle: true });
  }

  submit() {
    // call upload here
    const file = this.convertBase64toFile(this.croppedImage, 'avata');
    // upload data
    this.apiService.postFile('upload-file?type=students', file).then(data => {
      console.log('upload info: ', data);
      this.ref.close(data);
    });
  }

  triggerUploader() {
    // trigger click uploader input
    const el: HTMLElement = this.uploader.nativeElement;
    el.click();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.isShowUploader = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


  private convertBase64toFile(dataurl, filename) {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
