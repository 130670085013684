import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class ClassService {

  constructor(private apiService: ApiService) { }

  public getClass = () => this.apiService.get('bookings');
  public createClass = (booking) => this.apiService.post('bookings', booking);

  // get slot by teacherId
  public getClassByStudentId = (id) => this.apiService.get(`bookings?filter[where][userId]=${id}`);



}
