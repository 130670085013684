import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StudentComponent } from './student.component';
import { StudentClassComponent } from './class-list/class.component';
import { StudentRemarkListComponent } from './remark/remark.component';
import { StudentProfileComponent } from './profile/profile.component';
import { TeacherFeedbackComponent } from './feedback-list/feedback-list.component';
import { SubscriptionComponent } from './subscription/subscription.component';


const routes: Routes = [{
  path: '',
  component: StudentComponent,
  children: [
    {
      path: ':id/profile',
      component: StudentProfileComponent,
    }, {
      path: ':id/class',
      component: StudentClassComponent,
    },
    {
      path: ':id/teacher-remark',
      component: StudentRemarkListComponent,
    },
    {
      path: ':id/teacher-feedback',
      component: TeacherFeedbackComponent,
    },
    {
      path: ':id/subscription',
      component: SubscriptionComponent,
    },
  ],

}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StudentRoutingModule { }
