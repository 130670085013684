<!--Breadcrumb Area-->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/blog', '']">Blog</a></li>
                <li><a [routerLink]="['/blog', blog.slug, '']">Nội Dung Bài Viết</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h2>Bí Kiếp học tiếng Anh</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Blog Details-->
<section class="blog-page pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="blog-header">
          <h1>{{blog.title}}</h1>
          <div class="row mt20 mb20">
            <div class="col-md-8 col-9">
              <div class="media">
                <div class="user-image bdr-radius">
                  <!-- <img [src]="blog.authorImage" [alt]="blog.slug" [title]="blog.slug" class="img-fluid" /> -->
                </div>
                <div class="media-body user-info">
                  <h5>{{blog.createBy}}</h5>
                  <p>{{blog.createAt}}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-3">
              <div class="postwatch"><i class="far fa-eye"></i> {{blog.readCount}}</div>
            </div>
          </div>
        </div>
        <div class="image-set"><img [src]="blog.image" [alt]="blog.slug" [title]="blog.slug" class="img-fluid" /></div>

        <div class="blog-main-content" [innerHTML]="blog.html"></div>
      </div>
      <!--End Blog Details-->
      <!--Start Sidebar-->
      <div class="col-lg-4">
        <div class="sidebar">
          <!--Start block for offer/ads-->
          <div class="offer-image">
            <!-- <img src="assets/images/blog/strategy-guide.jpg" alt="offer" class="img-fluid" /> -->
          </div>
          <!--End block for offer/ads-->
          <!--Start Recent post-->
          <div class="recent-post widgets mt100">
            <h3 class="mb30">Recent post</h3>
            <div *ngFor="let blog of blogs" class="media">
              <div class="post-image bdr-radius">
                <a [routerLink]="['/', 'blog', blog.slug]"><img [src]="blog.image" [alt]="blog.slug" [title]="blog.slug"
                    class="img-fluid" /></a>
              </div>
              <div class="media-body post-info widget-post" data-toggle="tooltip" data-placement="top"
                [title]="blog.title">
                <h5><a [routerLink]="['/', 'blog', blog.slug]">
                    <div class="blog-title">{{blog.title}}</div>
                  </a>
                </h5>
                <p>{{blog.createAt}}</p>
              </div>
            </div>
            <!-- <div class="media">
                  <div class="post-image bdr-radius">
                    <a [routerLink]="[]"><img src="assets/images/blog/blog-small2.jpg" alt="girl" class="img-fluid" /></a>
                  </div>
                  <div class="media-body post-info">
                    <h5><a [routerLink]="[]">Stock Market App Development - Time, Cost, Features</a></h5>
                    <p>September 24, 2019</p>
                  </div>
                </div>
                <div class="media">
                  <div class="post-image bdr-radius">
                    <a [routerLink]="[]"><img src="assets/images/blog/blog-small.jpg" alt="girl" class="img-fluid" /></a>
                  </div>
                  <div class="media-body post-info">
                    <h5><a [routerLink]="[]">Stock Market App Development - Time, Cost, Features</a></h5>
                    <p>September 24, 2019</p>
                  </div>
                </div> -->
          </div>
          <!--Start Recent post-->
          <!--Start Blog Category-->
          <div class="recent-post widgets mt60">
            <h3 class="mb30">Chủ đề</h3>
            <div class="blog-categories">
              <ul>
                <li>
                  <a [routerLink]="[]">Tiếng Anh Giao Tiếp <span class="categories-number">(2)</span></a>
                </li>
                <li>
                  <a [routerLink]="[]">Tự Học Tiếng Anh <span class="categories-number">(3)</span></a>
                </li>
                <li>
                  <a [routerLink]="[]">IELTS Speaking <span class="categories-number">(4)</span></a>
                </li>
                <li>
                  <a [routerLink]="[]">IELTS Writing <span class="categories-number">(3)</span></a>
                </li>
                <li>
                  <a [routerLink]="[]">Chia sẽ Tài Liệu Học Tiếng Anh <span class="categories-number">(5)</span></a>
                </li>
              </ul>
            </div>
          </div>
          <!--End Blog Category-->
          <!--Start Tags-->
          <div class="recent-post widgets mt60">
            <h3 class="mb30">Tag phổ biến</h3>
            <div class="tabs">
              <a [routerLink]="[]">HọcTiếngAnhGiaoTiếp</a>
              <a [routerLink]="[]">HocTiengAnhGiaoTiep</a>
              <a [routerLink]="[]">TuHocTiengAnh</a>
              <a [routerLink]="[]">HocTiengAnhOnline</a>
              <a [routerLink]="[]">HocTiengAnh1Kem1</a>
              <a [routerLink]="[]">TuHocIELTS</a>
              <a [routerLink]="[]">HocIETLSSpkeaing</a>
              <a [routerLink]="[]">HocIELTSWriting</a>
              <a [routerLink]="[]">HocIELTSListening</a>
              <a [routerLink]="[]">HocIELTSReading</a>
              <a [routerLink]="[]">HocTiengAnhMienPhi</a>
              <a [routerLink]="[]">TaiLieuHocTiengAnhMienPhi</a>
              <a [routerLink]="[]">TiengAnh</a>
              <a [routerLink]="[]">English</a>
            </div>
          </div>
          <!--End Tags-->
          <!--Start block for offer/ads-->
          <div class="offer-image mt60">
            <!-- <img src="assets/images/blog/sidebar-image.jpg" alt="offer" class="img-fluid" /> -->
          </div>
          <!--End block for offer/ads-->
        </div>
      </div>
      <!--End Sidebar-->
    </div>
  </div>
</section>
