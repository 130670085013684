import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private apiService: ApiService) { }

  public getBlogs = () => this.apiService.get('blogs');
  // TODO shoule be sort by create time
  public getLatestBlogs = (limit=3) => this.apiService.get('blogs?filter[offset]=0&filter[limit]=' + limit);
  public getBlogById = (id) => this.apiService.get('blogs/' + id); // id and slug is samlilar
  public createBlog = (booking) => this.apiService.post('blogs', booking);
}
