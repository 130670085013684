<!--Breadcrumb Area-->
<!-- <section class="breadcrumb-area banner-11" data-background="assets/images/assets/images/banner/11.jpg"> -->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/hoc-phi', '']">Học Phí</a></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>Các gói Học Phí</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Pricing-->
<section class="block-a1 pad-tb">
  <div class="container">
  <div class="row justify-content-center">
  <div class="col-lg-8">
  <div class="common-heading ptag">
    <span>Bảng Giá</span>
    <h2>Áp Dụng Cho Các Lớp Tiếng Anh Giao Tiếp</h2>
    <p class="mb0">Mỗi khoá bên dưới chia làm 3 gói nhỏ có giá khác nhau tuỳ vào số buổi học của bạn mua</p>
  </div>
  </div>
  </div>
  <div class="row justify-content-center">
  <div class="col-lg-4 col-md-6">
  <div class="pricing-table mt60">
    <div class="inner-table">
      <img src="/assets/images/icons/plan-1.svg" alt="Personal"/>
      <span class="title">3 tháng</span>
      <p class="title-sub">Gồm 3 gói nhỏ</p>
      <h2>Liên Hệ</h2>
      <p class="duration">25 phút mỗi buổi</p>
      <div class="details">
        <ul>
          <li>24 buổi học </li>
          <li>36 buổi học</li>
          <li>60 buổi học</li>
        </ul>
      </div>
    </div>
    <a [routerLink]="['/lien-he', '']" (click)="onGetPrice(3)" class="btn-main bg-btn2 lnk">Báo Giá Ngay<i class="fas fa-chevron-right fa-icon"></i> <span class="circle"></span></a>
  </div>
  </div>
  <div class="col-lg-4 col-md-6">
  <div class="pricing-table best-plan mt60 bg-gradient4">
    <div class="inner-table">
      <img src="/assets/images/icons/plan-2.svg" alt="Advance"/>
      <span class="title">6 Tháng</span>
      <p class="title-sub">Gồm 3 gói nhỏ</p>
      <h2>Liên Hệ</h2>
      <p class="duration">25 phút mỗi buổi</p>
      <div class="details">
        <ul>
          <li>48 buổi học </li>
          <li>72 buổi học</li>
          <li>120 buổi học</li>
        </ul>
      </div>
    </div>
    <a [routerLink]="['/lien-he', '']" (click)="onGetPrice(6)" class="btn-main bg-btn3 lnk">Báo Giá Ngay<i class="fas fa-chevron-right fa-icon"></i> <span class="circle"></span></a>
  </div>
  </div>
  <div class="col-lg-4 col-md-6">
  <div class="pricing-table mt60">
    <div class="inner-table">
      <img src="/assets/images/icons/plan-3.svg" alt="Ultimate"/>
      <span class="title">12 Tháng</span>
      <p class="title-sub">Gồm 3 gói nhỏ</p>
      <h2>Liên Hệ</h2>
      <p class="duration">25 phút mỗi buổi</p>
      <div class="details">
        <ul>
          <li>96 buổi học </li>
          <li>144 buổi học</li>
          <li>240 buổi học</li>
        </ul>
      </div>
    </div>
    <a [routerLink]="['/lien-he', '']" (click)="onGetPrice(12)" class="btn-main bg-btn2 lnk">Báo Giá Ngay<i class="fas fa-chevron-right fa-icon"></i> <span class="circle"></span></a>
  </div>
  </div>
  </div>
  </div>
  </section>
  <!--End Pricing-->
