import { NgModule } from '@angular/core';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbFormFieldModule,
  NbTooltipModule,
} from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ImageCropperModule } from 'ngx-image-cropper';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);
import { RouterModule } from '@angular/router';
import { DialogPickSlotComponent } from './dialog-pick-slot/dialog-pick-slot.component';
import { DialogRemoveSlotComponent } from './dialog-remove-slot/dialog-remove-slot.component';
import { DialogFeedBackSlotComponent } from './dialog-feedback-slot/dialog-feedback-slot.component';
import { DialogUploadComponent } from './dialog-upload/dialog-upload.component';
import { TeacherProfileComponent } from './profile/profile.component';
import { TeacherProfileEditComponent } from './profile/profile-edit.component';
import { StudentFeedbackComponent } from './feedback-list/feedback-list.component';
import { TeacherFeedbackComponent } from './feedback-list-self/feedback-list-self.component';
import { TeacherRemarkListComponent } from './remark-self/remark-self.component';



import { ThemeModule } from '../../@theme/theme.module';
import { TeacherComponent } from './teacher.component';
import { TeacherCalendarSelfComponent } from './teacher-calendar-self/teacher-calendar-self.component';
import { TeacherRoutingModule } from './teacher-routing.module';

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbIconModule,
    NbActionsModule,
    NbSelectModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbInputModule,
    NbRadioModule,
    NbFormFieldModule,
    NbTooltipModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    FullCalendarModule, // register FullCalendar with you app
    RouterModule,
    ImageCropperModule,
    TeacherRoutingModule,
    Ng2SmartTableModule,
    NbRouteTabsetModule,
    NbTabsetModule,
    NgbModule,
  ],
  declarations: [
    TeacherComponent,
    TeacherProfileComponent,
    TeacherCalendarSelfComponent,
    TeacherProfileComponent,
    TeacherProfileEditComponent,
    StudentFeedbackComponent,
    TeacherFeedbackComponent,
    TeacherRemarkListComponent,
    DialogPickSlotComponent,
    DialogRemoveSlotComponent,
    DialogFeedBackSlotComponent,
    DialogUploadComponent,
  ],
  providers: [
  ],
})
export class TeacherModule {
}
