<!--Breadcrumb Area-->
<!-- <section class="breadcrumb-area banner-11" data-background="assets/assets/images/assets/assets/images/banner/11.jpg"> -->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/teacher', '']">Giáo Viên</a></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>Giáo Viên elseTalk</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Pricing-->
<section class="block-a1 pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span>Giáo Viên</span>
          <h2>Giáo Viên Giàu Kinh Nghiệm</h2>
          <p class="mb0">elseTalk tuyển chọn đội ngủ giáo viên giỏi về chuyên môn và có nhiều năm kinh nghiệm giảng dạy
            tiếng Anh online với những chứng chỉ giảng dạy tiếng Anh quốc tế quốc tế như TESOL, CELTA... elseTalk sẽ
            giúp bạn vượt qua lo sợ giao tiếp và giúp bạn bước tiếp nấc thang cao hơn của giao tiếp tiếng Anh.</p>
        </div>
      </div>
    </div>

    <div class="row upset ovr-bg2 bd-hor">
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
        <div class="s-block up-hor bd-hor-base hfull">
          <div class="nn-card-set">
            <div class="teacher"><img src="assets/images/teacher/person_1.jpg" alt="service" class="teacher-avatar" /></div>
            <h6>Trixie</h6>
            <h4>Professional Teacher</h4>
            <p>My name is Teacher Trixie. I love teaching because I can help others improve their skills and at the same time I also learn. It gives me joy and excitement meeting learners around the world. In my class, I work with wonderful enthusiasm and positivity. I’m interested in many different topics, and I’m happy to focus on my learners’ goals.</p>
            <!-- <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a> -->
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
        <div class="s-block up-hor bd-hor-base hfull">
          <div class="nn-card-set">
            <div class="teacher"><img src="assets/images/teacher/person_2.jpg" alt="service" class="teacher-avatar" /></div>
            <h6>Joyce</h6>
            <h4>Professional Teacher</h4>
            <p>I'm teacher Joyce. I'm fond of watching movies and reading books. I'm kind, passionate and friendly. I love English so much that's why I am very much willing to help you learn the language. I can teach you a lot, especially pronunciations (American Accent).</p>
            <!-- <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a> -->
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
        <div class="s-block up-hor bd-hor-base hfull">
          <div class="nn-card-set">
            <div class="teacher"><img src="assets/images/teacher/person_3.jpg" alt="service" class="teacher-avatar" /></div>
            <h6>Joan</h6>
            <h4>Professional Teacher</h4>
            <p>I'm teacher Joan. Professional ESL teacher with passion in student learning and 5 years experience in different language schools in the Philippines . Have taught ESL English as well as IELTS, TOEIC AND BUSINESS English to different nationalities.</p>
            <!-- <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!--End Pricing-->
