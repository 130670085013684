
<!-- datatime picker for generate slot -->
<input type="hidden"
ngxDaterangepickerMd
[timePicker]="true"
[showCancel] = "true"
[timePickerIncrement]="30"
[timePicker24Hour]="true"
[linkedCalendars]="true"
[locale]="{format: 'DD/MM/YYYY HH:mm', firstDay: 1}"
[(ngModel)]="selected"
(change)="change($event)"
class="datepicker-calendar-icon"/>
<!-- checkbox for override endtime -->
<nb-checkbox status="danger" (checkedChange)="onChangOpenClassEndOfAllSelectedDay($event)" [checked]="isOpenClassEndOfAllSelectedDay">
  Open class until the end of your selected days <strong>(this setting will override the end time you selected)</strong>
</nb-checkbox>
<!-- calendar -->
<full-calendar deepChangeDetection="true" [options]="calendarOptions" #calendar ></full-calendar>
