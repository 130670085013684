import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SmartTableData } from '../../../@core/data/smart-table';
import { SlotService } from '../../../services';
import { DateTime } from 'luxon';

@Component({
  selector: 'ngx-student-class',
  styleUrls: ['./class.component.scss'],
  templateUrl: './class.component.html',
})
export class StudentClassComponent implements OnDestroy {
  private alive = true;

  classes: any = [];
  userId: string;

  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    columns: {
      period: {
        title: 'Time (dd-mm-yy)',
        type: 'string',
      },
      teacherName: {
        title: 'Teacher',
        type: 'string',
        editable: false,
      },
      status: {
        title: 'Status',
        type: 'string',
        editable: false,
      },
    },
  };



  // source: LocalDataSource = new LocalDataSource();

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: SmartTableData,
    private slotService: SlotService,
    private router: Router,
  ) {
    const data = this.service.getData();
    this._initData();
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }

  onUserRowSelect(event): void {
    // TODO tralve exactly calendar time of class
    this.router.navigateByUrl(`/pages/booking/${event.data.teacherId}/class?day=${event.data.start}`);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private async _initData() {
    this.activatedRoute.params.subscribe(async params => {
      this.userId = params['id'];
      try {
        const classes: any = await this.slotService.getSlotsByUserId(this.userId);
        for (const data of classes) {
          data.status = this.convertSate(data);
          const start = new Date(data.start);
          // const end = new Date(data.end);

          data.period = DateTime.fromMillis(data.start).toFormat('HH:mm') + ' - ' + DateTime.fromMillis(data.end).toFormat('HH:mm') + '    ' + DateTime.fromMillis(data.start).toFormat('dd-MM-yyyy');
        }
        // sort DES as start time
        classes.sort(this.compare);

        this.classes = classes;
      } catch (ex) {
      }
    });
  }

  // TODO: implement
  private convertSate(clazz: any) {
    let {state} = clazz;
    // AVAILABLE = 'Available',
    // OVERTIME = 'Overtime',
    // BOOKED = 'Booked',
    // COMPLETED = 'Completed',
    if (state == 'Available') {
      if (this._isOverTime(clazz)) {
        state = 'Complete';
      } else {
        state = 'Upcomming';
      }
    }

    return state[0].toUpperCase() + state.slice(1);
  }

  _isOverTime(slot) {
    const now = new Date();
    return now.getTime() <= (slot.start);
  }

  private compare(a, b) {
    if (a.start > b.start) {
      return -1;
    }
    if (a.start < b.start) {
      return 1;
    }
    return 0;
  }

}
