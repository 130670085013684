import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class TeacherService {

  constructor(private apiService: ApiService) { }

  public getTeachers = () => this.apiService.get('teachers');
  public getTeacher = (id) => this.apiService.get('teachers/' + id);
  public createTeachers = (teacher) => this.apiService.post('teachers', teacher);
  public updateTeachers = (id, teacher) => this.apiService.patch('teachers/' + id, teacher);
  public updateTeacherPassword = (id, teacherPassword) => this.apiService.patch(`teachers/${id}/password`, teacherPassword);


  // statistic
  public teacherStatistics = (time) => this.apiService.get(`teacher-statistics/date/${time}`);
  public getTecherStatisticsById = (id) => this.apiService.get(`teacher-statistics/${id}`);
  public updateTeacherStatistics = (id, teacherStatistic) => this.apiService.patch('teacher-statistics/' + id, teacherStatistic);

}
