import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TeacherComponent } from './teacher.component';
import { TeacherCalendarSelfComponent } from './teacher-calendar-self/teacher-calendar-self.component';
import { StudentFeedbackComponent } from './feedback-list/feedback-list.component';
import { TeacherFeedbackComponent } from './feedback-list-self/feedback-list-self.component';
import { TeacherRemarkListComponent } from './remark-self/remark-self.component';
import {  TeacherProfileComponent } from './profile/profile.component';
import {  TeacherProfileEditComponent } from './profile/profile-edit.component';


const routes: Routes = [{
  path: '',
  component: TeacherComponent,
  children: [
    {
      path: ':id/profile',
      component: TeacherProfileComponent,
    },
    {
      path: ':id/profile/edit',
      component: TeacherProfileEditComponent,
    },
    {
      path: ':id/class',
      component: TeacherCalendarSelfComponent,
      pathMatch: 'full',
    },
    {
      path: ':id/student-feedback',
      component: StudentFeedbackComponent,
      pathMatch: 'full',
    },
    {
      path: ':id/my-feedback',
      component: TeacherFeedbackComponent,
      pathMatch: 'full',
    },
    {
      path: ':id/my-remark',
      component: TeacherRemarkListComponent,
      pathMatch: 'full',
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeacherRoutingModule { }
