import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {

  constructor(private apiService: ApiService) { }

  public createSubscription = (data) => this.apiService.post('subscriptions', data);
  public deleteSubscription = (id) => this.apiService.delete('subscriptions/' + id);
  public updateSubscription = (id, data) => this.apiService.patch('subscriptions/' + id, data);
  public getSubscriptions = () => this.apiService.get('subscriptions');
  public getSubscriptionsByStudentId = (userId) => this.apiService.get('subscriptions/user/' + userId);
  public sendInvoiceEmail = (id) => this.apiService.post(`subscriptions/${id}/send-email`, {});
}
