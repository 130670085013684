import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { TeacherComponent } from './teacher/teacher.component';
import { StudentComponent } from './student/student.component';

// import { TeacherListComponent } from './teacher/teacher-list.component';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'call',
      loadChildren: () => import('./video-call/video-call.module')
        .then(m => m.VideoCallModule),
    },
    {
      path: 'booking',
      loadChildren: () => import('./booking/booking.module')
        .then(m => m.BookingModule),
    },
    {
      path: 'student',
      loadChildren: () => import('./student/student.module')
        .then(m => m.StudentModule),
    },
    {
      path: 'teacher',
      loadChildren: () => import('./teacher/teacher.module')
        .then(m => m.TeacherModule),
    },
    {
      path: 'admin',
      loadChildren: () => import('./admin/admin.module')
        .then(m => m.AdminModule),
    },
    {
      path: '',
      redirectTo: 'booking/teachers',
      pathMatch: 'full',
    },
    // {
    //   path: '**',
    //   component: NotFoundComponent,
    // },
  ],
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
