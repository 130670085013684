import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherService } from '../../../services';
import { DomSanitizer } from '@angular/platform-browser';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';




@Component({
  selector: 'ngx-teacher-profile',
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile.component.html',
})
export class TeacherProfileComponent implements OnDestroy, OnInit {

  private alive = true;

  teacher: any = {};
  teacherId: string;
  safeURL: any;
  isTeacherOwner: boolean;
  isAdmin: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private teacherService: TeacherService,
    private _sanitizer: DomSanitizer,
    private authService: NbAuthService,
  ) {
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    this._initData();
  }


  private async _initData() {
    this.activatedRoute.params.subscribe(async params => {
      const teacherId = localStorage.getItem('user_id');
      this.teacherId = params['id'];
      this.isTeacherOwner = this.teacherId == teacherId;
      try {
        this.teacher = await this.teacherService.getTeacher(this.teacherId);
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.teacher.introduceVideoUrl);
      } catch (ex) {

      }
    });
    this.authService.getToken().subscribe((token: NbAuthJWTToken) => {
      // remove after logout
      if (token.getPayload()) {
        const { roles } = token.getPayload();
        if (roles.includes('super_admin') || roles.includes('admin')) {
          this.isAdmin = true;
        }
      }
    });
  }


}
