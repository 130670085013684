<nb-card>
  <nb-tabset>
    <nb-tab tabTitle="Remark">
      <nb-card>
        <nb-card-header>
          Remark Class  <strong>{{header}}</strong><br>
          For Student: <strong>{{slot.userName}}</strong>
        </nb-card-header>
        <nb-card-body>
          <input type="text" nbInput fullWidth  [(ngModel)]="remark.materialName" placeholder="Material Name"/>

          <input type="text" nbInput fullWidth  [(ngModel)]="remark.whereStart" placeholder="Where Start, lesson, page, phase, sentence..."/>

          <input type="text" nbInput fullWidth  [(ngModel)]="remark.whereEnd" placeholder="Where End, lesson, page, phase, sentence..."/>

          <textarea rows="3" nbInput fullWidth [(ngModel)]="remark.performance" placeholder="Performance"></textarea>
          <br>
        </nb-card-body>
        <nb-card-footer>
          <button nbButton status="danger" (click)="cancelRemark()"  class="close">Close</button>
          <button nbButton status="success" (click)="submitRemark(content)" class="btn-submit">Submit</button>
        </nb-card-footer>
      </nb-card>
    </nb-tab>
    <nb-tab tabTitle="FeedBack">
      <nb-card>
        <nb-card-header>FeedBack Class <strong>{{header}}</strong></nb-card-header>
        <nb-card-body>
          <!-- <input #feeback nbInput fullWidth placeholder="content"> -->
          <textarea rows="3" nbInput fullWidth [(ngModel)]="content" placeholder="Type your feedback"></textarea>
          <br>
          <!-- <nb-radio-group [(value)]="whoAbsent">
            <nb-radio
              [value]="'0'">
              Teacher Absent
            </nb-radio>
            <nb-radio
              [value]="'1'">
              Student Absent
            </nb-radio>
          </nb-radio-group> -->
          <nb-checkbox status="danger" (checkedChange)="teacherAbsent($event)" [checked]="checkedTeacher">Teacher Absent</nb-checkbox>
          <nb-checkbox status="danger" (checkedChange)="studentAbsent($event)" [checked]="checkedStudent">Student Absent</nb-checkbox>
          <nb-icon nbTooltip="Only Teacher or Student was absent, please do not select both!" nbTooltipPlacement="top" nbTooltipStatus="primary" icon="info" pack="eva"></nb-icon>
        </nb-card-body>
        <nb-card-footer>
          <button nbButton status="danger" (click)="cancel()"  class="close">Close</button>
          <button nbButton status="success" (click)="submit(content)" class="btn-submit">Submit</button>
        </nb-card-footer>
      </nb-card>
    </nb-tab>

  </nb-tabset>
</nb-card>
