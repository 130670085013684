import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-feedback-slot',
  templateUrl: 'dialog-feedback-slot.component.html',
  styleUrls: ['dialog-feedback-slot.component.scss'],
})
export class DialogFeedBackSlotComponent implements OnInit {

  header: string;
  slot: any;
  content: string;
  checkedTeacher = null;
  checkedStudent = null;
  remark: any;

  constructor(protected ref: NbDialogRef<DialogFeedBackSlotComponent>) { }

  ngOnInit(): void {
    // need init value because change event not fired if not check/uncheck lead to submit out of date value
    this.checkedTeacher = this.slot.isTeacherAbsent;
    this.checkedStudent = this.slot.isStudentAbsent;
  }


  teacherAbsent(checked: boolean) {
    this.checkedTeacher = checked;
    this.checkedStudent = false;
  }

  studentAbsent(checked: boolean) {
    this.checkedStudent = checked;
    this.checkedTeacher = false;
  }

  cancel() {
    this.ref.close({ isCancle: true });
  }

  submit(content) {
    this.ref.close({ content, checkedTeacher: this.checkedTeacher, checkedStudent: this.checkedStudent });
  }

  cancelRemark() {
    this.ref.close({ isCancle: true });
  }

  submitRemark(content) {
    this.remark.isRemark = true;
    this.ref.close(this.remark);
  }
}
