import { NgModule } from '@angular/core';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbFormFieldModule,
} from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ThemeModule } from '../../@theme/theme.module';
import { ImageCropperModule } from 'ngx-image-cropper';

import { StudentRoutingModule } from './student-routing.module';
import { StudentComponent } from './student.component';
import { StudentProfileComponent } from './profile/profile.component';
import { StudentClassComponent } from './class-list/class.component';
import { TeacherFeedbackComponent } from './feedback-list/feedback-list.component';
import { StudentRemarkListComponent } from './remark/remark.component';
import { SubscriptionComponent } from './subscription/subscription.component';


import { DialogUploadComponent } from './dialog-upload/dialog-upload.component';



@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbIconModule,
    NbActionsModule,
    NbSelectModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbInputModule,
    NbRadioModule,
    NbFormFieldModule,
    FormsModule,

    ImageCropperModule,
    StudentRoutingModule,
    Ng2SmartTableModule,
  ],
  declarations: [
    StudentComponent,
    StudentProfileComponent,
    StudentClassComponent,
    TeacherFeedbackComponent,
    DialogUploadComponent,
    StudentRemarkListComponent,
    SubscriptionComponent,
  ],
  providers: [
  ],
})
export class StudentModule {
}
