import { Component, OnInit, Renderer2 } from '@angular/core';


// import { MENU_ITEMS } from './pages-menu';
import { LoadScriptService } from '../../services';
// import * as $ from 'jquery';

@Component({
  selector: 'component-course',
  styleUrls: ['./course.component.scss'],
  templateUrl: './course.component.html',
})
export class CourseOwlComponent implements OnInit {

  constructor(
    private loadScriptService: LoadScriptService,
  ) {
   }

  ngOnInit() {
    this.loadScriptService.loadScript(this.loadScriptService.OWL);
  }
}
