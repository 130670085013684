<div class="row">
  <div class="col-lg-4">
    <nb-card>
      <!-- <nb-card-header></nb-card-header> -->
      <nb-card-body>
        <img class="avatar" [src]="avatarUrl" attr.loading="lazy">
        <button nbButton fullWidth status="basic" (click)="openUploadDialog()">Upload an Image </button>
        <!-- <span>Used Class: <strong>{{user.usedClass}} </strong>  |  Total Class: <strong>{{user.totalClass}}</strong></span> -->
        <span style="float: left;">Used Class: <strong>{{user.usedClass}} </strong> </span> <span style="float: right;">Total Class: <strong>{{user.totalClass}}</strong></span>
        <!-- <br> -->
        <span style="float: left;">Expire At: <strong>{{expriationDateString}}</strong></span>
        <textarea rows="3" nbInput fullWidth shape="round" [(ngModel)]="user.introduce" placeholder="About your self"></textarea>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-lg-8">
    <nb-card>
      <!-- <nb-card-header>Default Inputs</nb-card-header> -->
      <nb-card-body>
        <!-- <div class="full-name-inputs">
          <input type="text" nbInput fullWidth placeholder="Last Name">
        </div> -->
        <input type="text" nbInput fullWidth [(ngModel)]="user.fullName" [ngModelOptions]="{standalone: true}" placeholder="First Name">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}" placeholder="email">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="user.dob" placeholder="20/10/1992">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="user.gender" placeholder="Male | Female | ...">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="user.skypeAccount" placeholder="Skype Account">
        <input type="text" nbInput fullWidth shape="rectangle" [(ngModel)]="user.zaloAccount" placeholder="Zalo Account">
        <a nbButton status="info" ghost (click)="togglePassword()">Click to change password</a>
        <input type="text" nbInput fullWidth shape="rectangle" *ngIf="isPasswordField" [(ngModel)]="currentPassword" placeholder="Current Password">
        <input type="text" nbInput fullWidth shape="rectangle" *ngIf="isPasswordField" [(ngModel)]="newPassword" placeholder="New Password">
        <button nbButton fullWidth status="success" (click)="onUpdate()">Save</button>
      </nb-card-body>
    </nb-card>
  </div>
</div>
