
	<!--Breadcrumb Area-->
	<!-- <section class="breadcrumb-area banner-12" data-background="assets/images/banner/12.jpg"> -->
	<section class="breadcrumb-area">
		<div class="text-block">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 v-center">
						<div class="bread-inner">
							<div class="bread-menu">
								<ul>
									<li><a [routerLink]="['/']">Home</a></li>
									<li><a [routerLink]="['/blog', '']">Blog</a></li>
								</ul>
							</div>
							<div class="bread-title">
								<h2>Blog</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--End Breadcrumb Area-->
	<!--Start Blog Grid-->
	<section class="blog-page pad-tb pt40">
		<div class="container">
			<div class="row">
        <!-- blog -->
				<!-- <div *ngFor="let blog of blogs" class="col-md-4" (click)="onBlogItemClick(blog.slug)">
					<div class="blog">
						<div class="blog-img">
							<img class="img-responsive" [src]="blog.image" [alt]="blog.slug" [title]="blog.slug">
						</div>
						<div class="blog-content">
							<ul class="blog-meta">
								<li><i class="fa fa-user"></i>{{blog.createBy}}</li>
								<li><i class="fa fa-clock-o"></i>{{blog.createAt}}</li>
								<li><i class="fa fa-eye"></i>{{blog.readCount}}</li>
							</ul>
							<h3>{{blog.title}}</h3>
							<p>{{blog.excerpt}}</p>
							<a [routerLink]="['/', 'blog', blog.slug]">Read more</a>
						</div>
					</div>
				</div> -->
			<!-- /Row -->


				<div *ngFor="let blog of blogs" class="col-md-4" (click)="onBlogItemClick(blog.slug)" class="col-lg-4 col-sm-6 single-card-item">
					<div class="isotope_item hover-scale">
						<div class="item-image">
							<a [routerLink]="['/', 'blog', blog.slug]"><img [src]="blog.image" [alt]="blog.slug" [title]="blog.slug" class="img-fluid"/> </a>
							<span class="category-blog">
                <a *ngFor="let tag of blog.highlightTags" [routerLink]="['/blog', '']">{{tag}}</a>
              </span>
						</div>
						<div class="item-info blog-info">
							<div class="entry-blog">
								<span class="bypost"><a [routerLink]="['/blog', '']"><i class="fas fa-user"></i>{{blog.createBy}}</a></span>
								<span class="posted-on">
									<a [routerLink]="['/', 'blog', blog.slug]"><i class="fas fa-clock"></i> {{blog.createAt}}</a>
								</span>
								<span><a [routerLink]="['/', 'blog', blog.slug]"><i class="fas fa-comment-dots"></i> {{blog.readCount}}</a></span>
							</div>
							<h4><a [routerLink]="['/', 'blog', blog.slug]">{{blog.title}}</a></h4>
							<p>{{blog.excerpt}}</p>
						</div>
					</div>
				</div>

			</div>
		</div>
	</section>
	<!--End Blog Grid-->


