<!--Breadcrumb Area-->
<!-- <section class="breadcrumb-area banner-11" data-background="assets/images/assets/assets/images/banner/11.jpg"> -->
<section class="breadcrumb-area">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/hoc-phi', '']">Học Phí</a></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h2>Các gói Học Phí</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->

<!--Start Pricing-->
<section class="block-a1 pad-tb">
  <div class="container">
  <div class="row justify-content-center">
  <div class="col-lg-8">
  <div class="common-heading ptag">
    <span>Bảng Giá</span>
    <h2>Khoá học theo yêu cầu</h2>
    <p class="mb0">elseTalk sẽ thiết kế riêng khoá học theo yêu mọi cầu và mục tiêu của bạn</p>
  </div>
  </div>
  </div>
  <div class="row justify-content-center">
  <div class="col-lg-4 col-md-6">
  <div class="pricing-table best-plan mt60 bg-gradient4">
    <div class="inner-table mb40">
      <img src="assets/images/icons/plan-2.svg" alt="Advance"/>
      <span class="title">Theo yêu cầu</span>
      <p class="title-sub">Cá nhân hoá</p>
      <h2>Liên Hệ</h2>
      <p class="duration">Thiết kế riêng</p>
    </div>
    <a [routerLink]="['/lien-he', '']" (click)="onGetPrice()" class="btn-main bg-btn3 lnk">Báo Giá Ngay<i class="fas fa-chevron-right fa-icon"></i> <span class="circle"></span></a>
  </div>
  </div>
  </div>
  </div>
  </section>
  <!--End Pricing-->
