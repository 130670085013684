import { Injectable } from '@angular/core';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root',
})
export class SubscribeService {

  constructor(private apiService: ApiService) { }

  public createSubscribe = (subscribe) => this.apiService.post('subscribes', subscribe);


}
