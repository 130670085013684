<nb-card>
  <nb-card-header>Remove Class <strong>{{header}}</strong></nb-card-header>
  <nb-card-body>
    <span>Do you want to remove this class?</span>
  </nb-card-body>
  <nb-card-footer>
    <button  nbButton status="danger" (click)="cancel()" class="close">No</button>
    <button nbButton status="success" (click)="submit()" class="btn-submit">Yes</button>
  </nb-card-footer>
</nb-card>
