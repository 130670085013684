import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { stringify } from 'qs';


@Injectable({
  providedIn: 'root',
})
export class ContactService {

  constructor(private apiService: ApiService) { }

  public createContact = (contact) => this.apiService.post('contacts', contact);


}
