<!--Start Preloader -->
<div class="onloadpage" id="page_loader">
  <div class="pre-content">
    <div class="logo-pre"><img src="assets/images/logo.png" alt="Logo" class="img-fluid" /></div>
    <div class="pre-text-"><span>Tiếng Anh giao tiếp, IELTS online | elseTalk</span>1 kèm 1</div>
  </div>
</div>
<!--End Preloader -->
<!--Start Header -->
<header *ngIf="isLanding" class="nav-bg-w main-header navfix fixed-top menu-white">
  <div class="container-fluid m-pad">
    <div class="menu-header">
      <div class="dsk-logo"><a class="nav-brand" [routerLink]="['/']">
          <img src="assets/images/white-logo.png" alt="Logo" class="mega-white-logo" />
          <img src="assets/images/logo.png" alt="Logo" class="mega-darks-logo" />
        </a></div>
      <div class="custom-nav" role="navigation">
        <ul class="nav-list">
          <li><a [routerLink]="['/']" class="menu-links">Home</a></li>
          <!-- <li><a [routerLink]="['/portfolio']" class="menu-links">Portfolio</a></li> -->
          <li class="sbmenu rpdropdown"><a [routerLink]="[]" class="menu-links">Khoá Học</a>
            <div class="nx-dropdown menu-dorpdown">
              <div class="sub-menu-section">
                <div class="sub-menu-center-block">
                  <div class="sub-menu-column smfull">
                    <ul>
                      <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-giao-tiep', '']">Tiếng Anh Giao Tiếp</a> </li>
                      <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-thuong-mai', '']">Tiếng Anh Thương Mại</a> </li>
                      <li><a [routerLink]="['/khoa-hoc', 'ielts', '']">Khoá IETLS</a> </li>
                      <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-theo-yeu-cau', '']">Khoá Học Theo Yêu Cầu</a> </li>
                      <!-- <li><a [routerLink]="['/khoa-hoc', 'ielts', '']">Khoá IETLS Writing</a> </li>
                              <li><a [routerLink]="['/khoa-hoc', 'ielts', '']">Khoá IETLS Reading</a> </li>
                              <li><a [routerLink]="['/khoa-hoc', 'ielts', '']">Khoá IETLS Listening</a> </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li><a [routerLink]="['/phuong-phap-hoc-tieng-anh']" class="menu-links">Phương Pháp</a></li>
          <li><a [routerLink]="['/giao-vien']" class="menu-links">Giáo Viên</a></li>
          <!-- <li><a [routerLink]="['/hoc-phi']" class="menu-links">Học Phí</a></li> -->
          <li class="sbmenu rpdropdown"><a [routerLink]="[]" class="menu-links">Học Phí</a>
            <div class="nx-dropdown menu-dorpdown">
              <div class="sub-menu-section">
                <div class="sub-menu-center-block">
                  <div class="sub-menu-column smfull">
                    <ul>
                      <li><a [routerLink]="['/hoc-phi', 'tieng-anh-giao-tiep']">Học Phí Lớp Giao tiếp</a></li>
                      <li><a [routerLink]="['/hoc-phi', 'ielts']">Học Phí Lớp IELTS</a> </li>
                      <li><a [routerLink]="['/hoc-phi', 'khoa-hoc-theo-yeu-cau']">Học Phí Lớp Theo Yêu Cầu</a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li><a [routerLink]="['/lien-he/']" class="menu-links">Liên Hệ</a></li>
          <li><a [routerLink]="['/blog/']" class="menu-links">Blog</a></li>
          <li><a [routerLink]="[]" class="menu-links right-bddr">&nbsp;</a></li>
          <!--menu right border-->
          <li class="contact-show"><a [routerLink]="[]" class="btn-round- trngl btn-br bg-btn2"><i
                class="fas fa-phone-alt"></i></a>
            <div class="contact-inquiry">
              <div class="contact-info-">
                <div class="inquiry-card-nn">
                  <ul>
                    <li>
                      <p><i class="fas fa-phone-alt"></i> ĐT: <span> <a href="tel:+84901481609">(+84) 901 481
                            609</a></span></p>
                    </li>
                    <li>
                      <p><i class="fas fa-comment"></i> Zalo: <span> <a href="http://zalo.me/0901481609"
                            target="_blank">0901 481 609</a></span></p>
                    </li>
                    <li>
                      <p><i class="fab fa-skype"></i> Skype: <span> <a
                            href="skype:live:.cid.a466fddbd1f65778?chat">elseTalk</a></span></p>
                    </li>
                    <li>
                      <p><i class="fas fa-envelope"></i>Email: <span> <a
                            href="mailto:elsetalk.business@gmail.com">elsetalk.business@gmail.com</a></span></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li><a [routerLink]="['/auth/login']" class="btn-br bg-btn3 btshad-b2 lnk">Đăng Nhập<span
                class="circle"></span></a> </li>
        </ul>
      </div>
      <div class="mobile-menu2">
        <ul class="mob-nav2">
          <li><a href="#" class="btn-round- trngl btn-br bg-btn btshad-b1" data-toggle="modal"
              data-target="#menu-popup"><i class="fas fa-envelope-open-text"></i></a></li>
          <li class="navm-"> <a class="toggle" href="#"><span></span></a></li>
        </ul>
      </div>
    </div>
    <!--Mobile Menu-->
    <nav id="main-nav">
      <ul class="first-nav">
        <li><a [routerLink]="['/']">Home</a></li>
        <li><a href="#">Khoá Học</a>
          <ul>
            <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-giao-tiep', '']">Tiếng Anh Giao Tiếp</a> </li>
            <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-thuong-mai', '']">Tiếng Anh Thương Mại</a> </li>
            <li><a [routerLink]="['/khoa-hoc', 'ielts', '']">Khoá IETLS</a></li>
            <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-theo-yeu-cau', '']">Khoá Học Theo Yêu Cầu</a> </li>
          </ul>
        </li>
        <li><a [routerLink]="['/phuong-phap-hoc-tieng-anh']" class="menu-links">Phương Pháp</a></li>
        <li><a [routerLink]="['/giao-vien']" class="menu-links">Giáo Viên</a></li>
        <li><a href="#">Học Phí</a>
          <ul>
            <li><a [routerLink]="['/hoc-phi', 'tieng-anh-giao-tiep']">Học Phí Lớp Giao tiếp</a></li>
            <li><a [routerLink]="['/hoc-phi', 'ielts']">Học Phí Lớp IELTS</a> </li>
            <li><a [routerLink]="['/hoc-phi', 'khoa-hoc-theo-yeu-cau']">Học Phí Lớp Theo Yêu Cầu</a> </li>
          </ul>
        </li>
        <li><a [routerLink]="['/lien-he/']" class="menu-links">Liên Hệ</a></li>
        <li><a [routerLink]="['/blog/']" class="menu-links">Blog</a></li>
      </ul>
      <ul class="bottom-nav">
        <li class="prb">
          <a href="tel:+84901481609">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384">
              <path
                d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
                        c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
                        c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
                        C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z" />
            </svg>
          </a>
        </li>
        <li class="prb">
          <a href="mailto:elsetalk.business@gmail.com">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</header>
<!--Mobile contact-->
<div *ngIf="isLanding" class="popup-modal1">
  <div class="modal" id="menu-popup">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="common-heading">
            <h4 class="mt0 mb0">Bạn cần hỗ trợ, báo giá...</h4>
          </div>
          <button type="button" class="closes" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-block fdgn2 mt10 mb10">
            <form #contactForm="ngForm" name="feedback-form">
              <div class="fieldsets row">
                <div class="col-md-12">
                  <input type="text" name="name" id="name" [ngModel]="contact.fullName" #name="ngModel"
                    placeholder="Tên Của Bạn" required>
                  <div *ngIf="isContactSubmitted || (name.invalid && (name.dirty || name.touched))">
                    <div *ngIf="name.errors?.required" class="help-block with-errors">Vui lòng nhập tên của bạn</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <input type="email" id="email" name="email" [ngModel]="contact.email" #email="ngModel"
                    placeholder="Email của bạn" required email>
                  <div *ngIf="isContactSubmitted || (email.invalid && (email.dirty || email.touched))">
                    <div *ngIf="email.errors?.required" class="help-block with-errors">Email is required</div>
                    <div *ngIf="email.errors?.email" class="help-block with-errors">Vui lòng nhập Email của bạn</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <input type="number" id="mobile" name="phone" id="phone" [ngModel]="contact.phone" #phone="ngModel"
                    placeholder="Số điện thoại của Bạn" required data-error="Please fill Out">
                  <div *ngIf="isContactSubmitted || (phone.invalid && (phone.dirty || phone.touched))">
                    <div *ngIf="phone.errors?.required" class="help-block with-errors">Vui lòng nhập số điện thoại của
                      bạn</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <textarea id="message" rows="5" name="message" id="message" [ngModel]="contact.content"
                    #message="ngModel" placeholder="Bạn Cầu hỗ trợ gì?" required></textarea>
                  <div *ngIf="isContactSubmitted || (message.invalid && (message.dirty || message.touched))">
                    <div *ngIf="message.errors?.required" class="help-block with-errors">Vui lòng nhập Nội Dung</div>
                  </div>
                </div>
              </div>
              <div class="fieldsets mt20 pb20">
                <button type="button" name="submit" class="lnk btn-main bg-btn" (click)="onSubmitContact(contactForm)"
                  data-dismiss="modal">Gửi Yêu Cầu<i class="fas fa-chevron-right fa-icon"></i><span
                    class="circle"></span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Mobile contact-->
<!--End Header -->
<router-outlet (activate)="onActivate($event)"></router-outlet>

<!--Start Footer-->
<footer *ngIf="isLanding">
  <div class="footer-row1 bg-gradient9">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="email-subs">
            <h3>Đăng ký nhận bí kiếp học tiếng Anh hàng tuần thôi nào!</h3>
            <p>Đừng bỏ lỡ tài liệu, phương pháp học tiếng Anh giao tiếp, IELTS cực kỳ hấp dẫn nhé!</p>
          </div>
        </div>
        <div class="col-lg-6 v-center">
          <div class="email-subs-form">
            <form #subscribeFrom="ngForm" (ngSubmit)="onSubmitSubscribe(subscribeFrom)">
              <input type="email" name="email" [ngModel]="subscribe.email" #email="ngModel"
                placeholder="Nhập email của bạn" required email>
              <button type="submit" name="submit" class="lnk btn-main bg-btn">
                Subscribe
                <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span>
              </button>
            </form>
            <div *ngIf="isSubscribeSubmitted || (email.invalid && (email.dirty || email.touched))" class="mt20">
              <div *ngIf="email.errors?.required" class="help-block with-errors">Vui lòng nhập email của bạn</div>
              <div *ngIf="email.errors?.email" class="help-block with-errors">Email của bạn không hợp lệ, kiểm tra lại
                nhé!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Start CTA-->
  <app-cta-component></app-cta-component>
  <!--End CTA-->
  <div class="footer-row2">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-3 col-sm-6  ftr-brand-pp">
          <a class="navbar-brand mt30 mb25" [routerLink]="['/']"> <img src="assets/images/logo.png" alt="Logo"
              width="100" /></a>
          <p>Lớp học tiếng Anh giao tiếp, IELTS online 1 kèm 1 đặt lịch học linh hoạt với 100% giáo viên nước ngoài,
            elseTalk sẽ giúp bạn giao tiếp tự tin từ 3 đến 6 tháng</p>
          <a [routerLink]="['/lien-he', '']" (click)="setContactTitle('register')"
            class="btn-main bg-btn3 lnk mt20">Đặng Ký Học Ngay <i class="fas fa-chevron-right fa-icon"></i><span
              class="circle"></span></a>
        </div>
        <div class="col-lg-2 col-sm-6 mr5">
          <h5>elseTalk Ngay</h5>
          <ul class="footer-address-list ftr-details">
            <li>
              <span><i class="fas fa-phone-alt"></i></span>
              <p>Phone <span> <a href="tel:+84901481609">(+84) 901 481 609</a></span></p>
            </li>
            <li>
              <span><i class="fas fa-comment"></i></span>
              <p>Zalo <span> <a href="http://zalo.me/0901481609" target="_blank">0901 481 609</a></span></p>
            </li>
            <li>
              <span><i class="fab fa-skype"></i></span>
              <p>Skype <span> <a href="skype:live:.cid.a466fddbd1f65778?chat">elseTalk</a></span></p>
            </li>
            <li>
              <span><i class="fas fa-envelope"></i></span>
              <p>Email <span> <a href="mailto:elsetalk.business@gmail.com">elsetalk.business@gmail.com</a></span></p>
            </li>
            <!-- <li>
    <span><i class="fas fa-map-marker-alt"></i></span>
    <p>Address <span> 123 Business Centre London SW1A 1AA</span></p>
    </li> -->
          </ul>
        </div>
        <div class="col-lg-3 col-sm-6">
          <h5>Menu</h5>
          <ul class="footer-address-list link-hover">
            <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-giao-tiep', '']">Khoá Tiếng Anh Giao Tiếp</a> </li>
            <li><a [routerLink]="['/khoa-hoc', 'tieng-anh-giao-tiep-business', '']">Tiếng Anh Giao Tiếp Business</a>
            </li>
            <li><a [routerLink]="['/khoa-hoc', 'ielts', '']">Khoá IETLS</a> </li>
            <li><a [routerLink]="['/hoc-phi']" class="menu-links">Học Phí</a></li>
            <li><a [routerLink]="['/lien-he']" class="menu-links">Liên Hệ</a></li>
            <li><a [routerLink]="['/blog']" class="menu-links">Blog</a></li>
          </ul>
        </div>
        <div class="col-lg-4 col-sm-6 footer-blog-">
          <h5>Blogs Tiếng Anh</h5>
          <div *ngFor="let blog of blogs" class="single-blog-">
            <div class="post-thumb">
              <a [routerLink]="['/', 'blog', blog.slug]"><img [src]="blog.image" [alt]="blog.slug"
                  [title]="blog.slug"></a>
            </div>
            <div class="content">
              <p class="post-meta"><span class="post-date"><i class="far fa-clock"></i>{{blog.createAt}}</span></p>
              <h4 class="title"><a [routerLink]="['/', 'blog', blog.slug]">{{blog.title}}</a></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="footer-brands">
    <div class="container">
    <div class="row">
    <div class="col-lg-4 v-center">
    <h5 class="mb10">Top App Development Companies</h5>
    <p>News letter dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. Enter your email</p>
    </div>
    <div class="col-lg-8 v-center">
    <ul class="footer-badges-">
    <li><a href="#"><img src="assets/images/about/badges-a.png" alt="badges"></a></li>
    <li><a href="#"><img src="assets/images/about/badges-b.png" alt="badges"></a></li>
    <li><a href="#"><img src="assets/images/about/badges-c.png" alt="badges"></a></li>
    <li><a href="#"><img src="assets/images/about/badges-d.png" alt="badges"></a></li>
    </ul>
    </div>
    </div>
    </div>
    </div> -->
  <div class="footer-row3">
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="footer-social-media-icons">
              <a href="https://www.facebook.com/elsetalk" target="blank"><i class="fab fa-facebook"></i></a>
              <!-- <a href="javascript:void(0)" target="blank"><i class="fab fa-twitter"></i></a> -->
              <a href="https://www.instagram.com/elsetalk/" target="blank"><i class="fab fa-instagram"></i></a>
              <!-- <a href="javascript:void(0)" target="blank"><i class="fab fa-linkedin"></i></a> -->
              <!-- <a href="javascript:void(0)" target="blank"><i class="fab fa-youtube"></i></a> -->
              <!-- <a href="javascript:void(0)" target="blank"><i class="fab fa-pinterest-p"></i></a> -->
              <!-- <a href="javascript:void(0)" target="blank"><i class="fab fa-vimeo-v"></i></a> -->
              <!-- <a href="javascript:void(0)" target="blank"><i class="fab fa-dribbble"></i></a> -->
              <!-- <a href="javascript:void(0)" target="blank"><i class="fab fa-behance"></i></a> -->
            </div>
            <div class="footer-">
              <p>Copyright &copy; 2022 elseTalk. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--End Footer-->

<!-- <div *ngIf="!isLanding">
  <router-outlet></router-outlet>
</div> -->
